<template>
  <div style="overflow:initial;">
    <v-navigation-drawer
      v-model="drawer"
      floating
      app
      height="100vh"
      class="elevation-3"
      v-if="imageHeight >= 500"
    >
      <div class="text-center mt-2 pointer" @click="reloadtomydrive()">
        <img
          v-if="triggle_feature_logo() === true"
          class="pt-3 pb-1"
          :src="logo_default"
          width="40%"
          height="40%"
        />
        <!-- logo default ของ onebox -->
        <img
          v-else
          class="pt-3 pb-1"
          src="@/assets/logo_onebox-vertical-png.png"
          width="20%"
          height="20%"
        />
      </div>
      <p
        class="text-center pointer"
        style="font-size: 14px; color: red;"
        v-if="system_type_run === 'UAT'"
      >
        <b>UAT SYSTEM</b>
      </p>
      <v-list dense nav class="pr-1">
        <!-- <v-divider></v-divider> -->
        <v-menu transition="slide-y-transition" bottom :close-on-content-click="false" offset-y min-width="216px" nudge-right="8">
          <template v-slot:activator="{ on }">
            <div class="text-center pa-2">
              <v-btn
                :color="color.theme"
                :dark="!permission_denied_account"
                v-on="on"
                block
                class="elevation-2"
                :disabled="permission_denied_account"
              >
                <v-icon class="mr-2" color="white">add_box</v-icon>
                {{ " " + $t("new") }}
              </v-btn>
            </div>
          </template>
          <v-list>
            <v-list-item @click.stop="opencreatefolder()">
              
                <v-icon class="ml-2" size="25" style="color:#ffc107;">mdi-folder-upload</v-icon>
                <!-- <v-icon size="30" :color="darkModeIcon">mdi-folder-upload</v-icon> -->
              
              <v-list-item-title class="ml-5" :style="darkModeText">{{
                $t("mainRClick.uploadfolder")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="openfile(), $emit('closedragselect')">
              
                <v-icon class="ml-2" size="25" style="color:#579C51;">mdi-file-upload</v-icon>
                <!-- <v-icon size="30" :color="darkModeIcon">mdi-file-upload</v-icon> -->
              
              <v-list-item-title class="ml-5" :style="darkModeText">{{
                $t("uploadfile.name")
              }}</v-list-item-title>
            </v-list-item>
            <v-divider class="mx-5"></v-divider>
            <v-list-item @click.stop="openfolder(), $emit('closedragselect')">
              
                <!-- <v-icon size="30" :color="darkModeIcon" >mdi-folder-plus</v-icon> -->
                <v-icon class="ml-2" size="25" style="color:#ffc107;" >mdi-folder-plus</v-icon> 
              
              <v-list-item-title class="ml-5" :style="darkModeText">{{
                $t("uploadfolder.name")
              }}</v-list-item-title>
            </v-list-item>
            <v-divider class="mx-5"></v-divider>
            <!-- <v-list-item>
              <v-list-item-avatar>
                <v-icon size="30" style="color:#579C51;" >mdi-file-plus</v-icon> 
              </v-list-item-avatar>
              <v-list-item-title :style="darkModeText">{{
                $t("mainRClick.createfile")
              }}</v-list-item-title>
            </v-list-item> -->
            <v-menu offset-x bottom open-on-hover v-if="$route.name !== 'directorysharefile' && feature_onlyoffice !== false">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                      <v-icon class="ml-2" size="25" style="color:#579C51;">mdi-file-plus</v-icon>
                    <v-list-item-content>
                      <v-list-item-title class="ml-5" :style="darkModeText">{{ $t("mainRClick.createfile") }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <v-icon medium>mdi-chevron-right</v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in itemsCreateFile"
                    :key="i"
                    @click="create_files(item.text, item.document_type, item.file_extension)"
                  >
                      <v-icon size="25" medium v-text="item.icon" :style="item.document_type === 'word' ? 'color:#0263d1;' : item.document_type === 'excel' ? 'color:#00733b;' : item.document_type === 'powerpoint' ? 'color:#C43E1C;' : 'color:blue-grey;'"></v-icon>
                    <v-list-item-content>
                      <v-list-item-title class="ml-5" :style="darkModeText">{{ $t(item.text) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
        </v-menu>
            <!-- <v-list-item @click.stop="fn_shortcut(), $emit('closedragselect')">
              <v-list-item-avatar>
                <v-icon size="30" :color="darkModeIcon">mdi-file-link</v-icon>
              </v-list-item-avatar>
              <v-list-item-title :style="darkModeText">{{
                $t("shortcut_header")
              }}</v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <!-- <v-divider></v-divider> -->
        <div class="mt-2 mb-2">
          <v-autocomplete
            @change="changestorage_sidebar(choosetypeuser)"
            :items="typeuser"
            :item-text="$t('default') === 'en' ? 'nameen' : 'nameth'"
            item-value="business_id"
            :color="color.theme"
            filled
            v-model="choosetypeuser"
            return-object
            dense
            hide-details
            rounded
            class="rounded-lg"
            style="cursor: pointer;"
            :no-data-text="$t('default') === 'en' ? 'No data available': 'ไม่พบอเจอข้อมูล'"
            v-if="check_service_oneplatfrom !== 'true'"
          >
            <template color="#F6F7FA" v-slot:item="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 14px; cursor: pointer;">{{
                item.nameen
              }}</span>
              <span v-else style="font-size: 14px; cursor: pointer;">{{ item.nameth }}</span>
            </template>
            <template color="#F6F7FA" v-slot:selection="{ item }">
              <span v-if="$t('default') === 'en'" style="font-size: 13px; cursor: pointer;">{{
                item.nameen
              }}</span>
              <span v-else style="font-size: 13px; cursor: pointer;">{{ item.nameth }}</span>
            </template>
          </v-autocomplete>
          <v-text-field 
            outlined
            :color="color.theme"
            :value="$t('default') === 'en' ? choosetypeuser.nameen : choosetypeuser.nameth"
            dense
            class="rounded-lg"
            readonly
            v-else
          ></v-text-field>
        </div>
        <!-- <v-divider></v-divider> -->
        <!-- <v-list-item-group v-model="activemenu" color="white"> -->
        <template v-for="item in [...listinputmore(),...listmenu()]">
          <v-row
            :color="color.theme"
            v-if="item.heading"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            :color="color.theme"
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
          >
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              class="pl-10"
              v-for="(child, i) in item.children"
              :key="i"
              link
              @click="tolink(child.link, child.text)"
            >
              <v-list-item-action>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t(child.text) }}</v-list-item-title>
              </v-list-item-content>
              <!-- <v-list-item-action v-if="child.icon">
                  <v-icon></v-icon>
              </v-list-item-action>-->
            </v-list-item>
          </v-list-group>
          <!-- <div v-else :key="item.text"> -->
          <v-list-item
            v-else-if="item.text === 'toolbar.specialupload'"
            :disabled="item.disable"
            :key="item.text"
            @click="tolink_inputmore()"
            style="color: white !important; background: linear-gradient(to right, rgba(24,73,102,0.9), rgba(113,181,216,0.9));"        
          >
            <v-list-item-action>
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :color="color.theme"
            v-else
            :to="item.disable === true ? '' : item.link"
            :disabled="item.disable"
            :key="item.text"
            @click="
              dataAccountActive['type'] === 'Citizen'
                ? ''
                : item.link ===
                  '/directory/' +
                    dataAccountActive['business_info']['my_folder_id']
                ? $emit('loadfile')
                : ''
            "
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
            </v-list-item-content>
            <v-expand-transition>
              <v-overlay
                v-if="item.disable === true"
                absolute
                color="grey lighten-1"
                :value="disableByPackage"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
                  </template>
                  <span>{{ $t("balloon.lock_function") }}</span>
                </v-tooltip>
              </v-overlay>
            </v-expand-transition>
          </v-list-item>
          <!-- </div> -->
        </template>
        <!-- </v-list-item-group> -->
      </v-list>
      <div
        v-if="
          dataBusinessProfile.length > 0 &&
            dataAccountActive.type === 'Business'
        "
      >
        <!-- <v-divider></v-divider> -->
        <!-- {{permission_inbox_outbox}} -->
        <!-- <v-list dense nav>
          <v-list-group append-icon="keyboard_arrow_down">
            <template v-slot:activator>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title>{{ $t("forbusiness") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <div
              v-if="
                status_permission_inbox === 'AD' ||
                  status_permission_inbox === 'AL' ||
                  status_permission_inbox === 'DL' ||
                  status_permission_inbox === ''
              "
            >
              <v-list-item
                link
                :to="item.link"
                :disabled="item.disable"
                :key="i"
                v-for="(item, i) in forbusiness_inbox"
              >
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div
              v-if="
                status_permission_outbox === 'AD' ||
                  status_permission_outbox === 'AL' ||
                  status_permission_outbox === 'DL' ||
                  status_permission_outbox === ''
              "
            >
              <v-list-item
                link
                :to="item.link"
                :disabled="item.disable"
                :key="i"
                v-for="(item, i) in forbusiness_outbox"
              >
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text),}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </v-list> -->
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            :to="item.link"
            :disabled="item.disable"
            :key="i"
            v-for="(item, i) in library"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 13px;"
                >{{ $t(item.text),}}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <!-- cctv -->
      <div
        v-if="
          dataAccountActive.type === 'Citizen' &&
            dataAccountCCTV.status === true
        "
      >
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item
            link
            :to="item.link"
            :disabled="item.disable"
            :key="i"
            v-for="(item, i) in cctv"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </div>
      <!-- เมนูล่างซ้าย -->
      <template v-slot:append>
        <div v-if="dataAccountActive.type === 'Citizen'">
          <v-list nav dense>
            <v-list-item>
              <v-list-item-action class="text-center">
                <v-icon>mdi-inbox-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content class="ml-n3">
                <v-list-item-title style="line-height: 22px;">
                  <v-layout row justify-start >
                  <div class="ml-4">{{ $t("toolbar.storage") }}</div>
                  <!-- <div style="position: absolute; right: 2px; top:.5px">
                  <v-btn icon @click="fn_checkAccountActivetypebiz()">
                  <v-icon>mdi-cart</v-icon>
                  </v-btn></div> -->
                  </v-layout>
                </v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  <v-progress-linear
                    class="mt-2"
                    :color="percentagestoage_setcolor"
                    rounded
                    :value="percentageStorage"
                  ></v-progress-linear>
                  <br />
                  {{ storage_usage }} {{ $t("adminrecheckimap.in") }} {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="dataAccountActive.type === 'Business'">
          <!-- <v-list nav dense>
            <v-list-item>
              <v-list-item-action class="text-center">
                <v-icon>storage</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("toolbar.storage") }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  <v-progress-linear
                    class="mt-2"
                    :color="percentagestoagecitizeninbiz_setcolor"
                    rounded
                    :value="percentageStoragecitizeninbiz"
                  ></v-progress-linear>
                  <br />
                  {{ storage_usage }} {{ $t("adminrecheckimap.in") }} {{ storage_max_personal_biz }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list> -->
          <v-list nav dense>
            <v-list-item>
              <v-list-item-action class="text-center">
                <v-icon>mdi-inbox-multiple</v-icon>
              </v-list-item-action>
              <v-list-item-content class="ml-n3">
                <v-list-item-title style="line-height: 22px;">
                  <v-layout row justify-start >
                  <div class="ml-4">{{ $t("toolbar.storage") }}</div>
                  <!-- <div style="position: absolute; right: 2px; top:.5px">
                  <v-btn icon :disabled="role_level !== 'True'" @click="fn_checkAccountActivetypebiz()">
                  <v-icon>mdi-cart</v-icon>
                  </v-btn></div> -->
                  </v-layout>
                </v-list-item-title>
                <v-list-item-subtitle class="text-center">
                  <v-progress-linear
                    class="mt-2"
                    :color="percentagestoagebiz_setcolor_right"
                    rounded
                    :value="percentageStoragebusiness"
                  ></v-progress-linear>
                  <br />
                  {{ storage_usage_biz }} {{ $t("adminrecheckimap.in") }} {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <div v-if="dataAccountActive.type === 'Citizen'">
          <v-flex class="text-center ma-2">
            <v-btn
              small
              block
              class="white--text"
              style="font-size: 14px;"
              color="error"
              @click="fn_checkreportSystem()"
              >{{ $t("toolbar.report_problem") }}</v-btn
            >
          </v-flex>
        </div>
        <div v-else>
          <v-flex class="text-center ma-2">
            <v-btn
              small
              block
              class="white--text"
              style="font-size: 14px;"
              color="error"
              @click="fn_checkreportSystem()"
              >{{ $t("toolbar.report_problem") }}</v-btn
              >
            
          </v-flex>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- หน้าจอมือถือด้านซ้าย -->
    <v-navigation-drawer
      v-model="drawer"
      floating
      app
      fixed
      fab
      class="elevation-3"
      width="300"
      v-else
    >
      <div class="text-right" @click="drawer = false">
        <v-btn icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="text-center mt-2 pointer" @click="reloadtomydrive()">
        <img
          v-if="triggle_feature_logo() === true"
          class="pt-3 pb-1"
          :src="logo_default"
          width="40%"
          height="40%"
        />
        <!-- logo default ของ onebox -->
        <img
          v-else
          class="pt-3 pb-1"
          src="@/assets/img/logo-onebox-mobile.png"
          width="60%"
          height="60%"
        />
      </div>
      <v-list dense class="mx-4">
        <!-- <v-divider></v-divider> -->
        <v-menu
          transition="slide-y-transition"
          bottom
          :close-on-content-click="false"
          offset-y
          content-class="roundUploadAndCreate"
        >
          <template v-slot:activator="{ on }">
            <div class="text-center mb-3">
              <v-btn
                :color="color.theme"
                :dark="!permission_denied_account"
                v-on="on"
                block
                class="elevation-0"
                dense
                :style="textThemeFontSmall"
                height="36px"
                width="120px"
                :disabled="permission_denied_account"
              >
                <v-icon size="12" class="pt-1" color="white"
                  >mdi-cloud-upload</v-icon
                ><span
                  class="pl-1"
                  style="font-Weight:500; font-size:14; line-height: 22px;"
                  >{{ $t("new") }}</span
                >
              </v-btn>
            </div>
          </template>
          <v-list dense>
            <v-list-item
              @click.stop="opencreatefolder()"
              :style="heightListItem"
            >
              <v-list-item-title style="font-size: 14px; line-height: 24px;">
                <v-icon class="pb-1" size="20" style="color:#ffc107;"
                  >mdi-folder-upload</v-icon
                >
                <span class="pl-2">{{
                  $t("mainRClick.uploadfolder")
                }}</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click.stop="openfile()" :style="heightListItem">
              <v-list-item-title style="font-size: 14px; line-height: 24px;">
                <v-icon class="pb-1" size="20" style="color:#579C51;"
                  >mdi-file-upload</v-icon
                >
                <span class="pl-2">{{
                  $t("uploadfile.name")
                }}</span></v-list-item-title
              >
            </v-list-item>
            <v-list-item @click.stop="openfolder(), $emit('closedragselect')" :style="heightListItem">
              <v-list-item-title style="font-size: 14px; line-height: 24px;">
                <v-icon class="pb-1" size="20" style="color:#ffc107;"
                  >mdi-folder-plus</v-icon
                >
                <span class="pl-2">{{
                  $t("uploadfolder.name")
                }}</span></v-list-item-title
              >
            </v-list-item>
            <v-menu offset-x bottom v-if="$route.name !== 'directorysharefile' && feature_onlyoffice !== false">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">  
                    <v-list-item-title style="font-size: 14px; line-height: 24px;">
                      <v-icon class="pb-1" size="20" style="color:#579C51;">mdi-file-plus</v-icon>
                      <span class="pl-2">{{ $t("mainRClick.createfile") }}</span>
                      </v-list-item-title>
                      <v-icon medium>mdi-chevron-right</v-icon>
                  </v-list-item>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, i) in itemsCreateFile"
                    :key="i"
                    @click="create_files(item.text, item.document_type, item.file_extension)"
                  >
                      <v-icon size="20" medium v-text="item.icon" :style="item.document_type === 'word' ? 'color:#0263d1;' : item.document_type === 'excel' ? 'color:#00733b;' : item.document_type === 'powerpoint' ? 'color:#C43E1C;' : 'color:blue-grey;'"></v-icon>
                      <v-list-item-title><span class="pl-2">{{ $t(item.text) }}</span></v-list-item-title>
                  </v-list-item>
                </v-list>
        </v-menu>
            <!-- <v-list-item @click.stop="fn_shortcut(), $emit('closedragselect')" :style="heightListItem">
              <v-list-item-title :style="textThemeFontSmall">
                <v-icon class="pb-1" size="20" :color="iconTheme"
                  >mdi-file-link</v-icon
                >
                <span class="pl-2">{{ $t("shortcut_header") }}</span>
              </v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <!-- <v-divider></v-divider> -->
        <div class="mt-2 mb-5">
          <v-autocomplete
            @change="changestorage_sidebar(choosetypeuser)"
            :items="typeuser"
            :item-text="$t('default') === 'en' ? 'nameen' : 'nameth'"
            item-value="business_id"
            v-model="choosetypeuser"
            return-object
            dense
            hide-details
            outlined
            :color="color.theme"
            :border-color="color.theme"
            append-icon="keyboard_arrow_down"
            class="autocompleteClass"
            v-if="check_service_oneplatfrom !== 'true'"
          >
            <template v-slot:item="{ item }">
               <!-- choosetypeuser.nameen === item.nameen -->
               <span
                v-if="$t('default') === 'en'"
                :style="
                  choosetypeuser.taxid === item.taxid
                    ? listOnBoldFontSmall
                    : listNoBoldFontSmall
                "
                >{{ item.nameen }}</span
              >
              <!-- choosetypeuser.nameth === item.nameth -->
              <span
                v-else
                :color="color.theme"
                :style="
                  choosetypeuser.taxid === item.taxid
                    ? listOnBoldFontSmall
                    : listNoBoldFontSmall
                "
                >{{ item.nameth }}</span
              >
            </template>
          </v-autocomplete>
          <v-text-field 
            outlined
            :color="color.theme"
            :value="$t('default') === 'en' ? choosetypeuser.nameen : choosetypeuser.nameth"
            dense
            class="rounded-lg"
            readonly
            v-else
          ></v-text-field>
        </div>
        <!-- <v-divider></v-divider> -->
        <!-- <v-list-item-group v-model="activemenu" color="white"> -->
        <template v-for="item in [...listinputmore(),...listmenu()]">
          <v-row
            :color="color.theme"
            v-if="item.heading"
            :key="item.heading"
            align="center"
          >
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            :color="color.theme"
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            no-action
          >
            <template v-slot:activator>
              <v-list-item
                class="ml-n5"
                :style="heightListMenuLeft"
                :color="color.theme"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :style="
                      $route.path === item.link
                        ? listOnBoldFontNormal
                        : listNoBoldFontNormal
                    "
                    class="pt-2"
                    ><v-icon class="mb-1" size="22">{{
                      item.model ? item.icon : item["icon-alt"]
                    }}</v-icon
                    ><span class="pl-1">
                      {{ $t(item.text),}}
                    </span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
              class="pl-10"
              v-for="(child, i) in item.children"
              :key="i"
              link
              @click="tolink(child.link, child.text)"
              :style="heightListMenuLeft"
            >
              <v-list-item-content>
                <v-list-item-title
                  :style="
                    $route.path === item.link
                      ? listOnBoldFontNormal
                      : listNoBoldFontNormal
                  "
                >
                  <v-icon class="mb-1" size="22">{{ child.icon }}</v-icon>
                  <span class="pl-1">{{
                    $t(child.text)
                  }}</span></v-list-item-title
                >
              </v-list-item-content>
              <!-- <v-list-item-action v-if="child.icon">
                  <v-icon></v-icon>
              </v-list-item-action>-->
            </v-list-item>
          </v-list-group>
          <!-- <div v-else :key="item.text"> -->
          <v-list-item
            v-else-if="item.text === 'toolbar.specialupload'"
            :disabled="item.disable"
            :key="item.text"
            @click="tolink_inputmore()"
            style="height: 54px; background: linear-gradient(to right, rgba(24,73,102,0.9), rgba(113,181,216,0.9));"        
          >
            <v-list-item-content class="ml-n1">
              <v-list-item-title
                class="pt-1"
                :style="
                  $route.path === item.link
                    ? listOnBoldFontNormal
                    : listNoBoldFontNormal
                "
              >
              <v-icon class="mb-1" size="22" color="white">{{ item.icon }}</v-icon>
              <span class="pl-1" style="color: white !important">{{ $t(item.text),}}</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :style="heightListMenuLeft"
            :color="color.theme"
            v-else
            :to="item.disable === true ? '' : item.link"
            :disabled="item.disable"
            :key="item.text"
            @click="
              dataAccountActive['type'] === 'Citizen'
                ? ''
                : item.link ===
                  '/directory/' +
                    dataAccountActive['business_info']['my_folder_id']
                ? $emit('loadfile')
                : ''
            "
          >
            <v-list-item-content class="ml-n1">
              <v-list-item-title
                class="pt-1"
                :style="
                  $route.path === item.link
                    ? listOnBoldFontNormal
                    : listNoBoldFontNormal
                "
                ><v-icon class="mb-1" size="22">{{ item.icon }}</v-icon
                ><span class="pl-1">
                  {{ $t(item.text),}}
                </span></v-list-item-title
              >
            </v-list-item-content>
            <v-expand-transition>
              <v-overlay
                v-if="item.disable === true"
                absolute
                color="grey lighten-1"
                :value="disableByPackage"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-lock</v-icon>
                  </template>
                  <span>{{ $t("balloon.lock_function") }}</span>
                </v-tooltip>
              </v-overlay>
            </v-expand-transition>
          </v-list-item>
          <!-- </div> -->
        </template>
        <!-- </v-list-item-group> -->
      </v-list>
      <div
        v-if="
          dataBusinessProfile.length > 0 &&
            dataAccountActive.type === 'Business'
        "
        class="mx-4"
      >
        <!-- <v-divider class="mx-2"></v-divider> -->
        <!-- {{permission_inbox_outbox}} -->
        <!-- <v-list dense>
          <v-list-group append-icon="keyboard_arrow_down" :color="color.theme">
            <template v-slot:activator>
              <v-list-item class="ml-n5" :style="heightListMenuLeft">
                <v-list-item-content>
                  <v-list-item-title class="pt-2"
                    ><v-icon class="mb-1" size="22">mdi-clipboard</v-icon
                    ><span class="pl-1" :style="listNoBoldFontNormal">
                      {{ $t("forbusiness") }}</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
            <div
              v-if="
                status_permission_inbox === 'AD' ||
                  status_permission_inbox === 'AL' ||
                  status_permission_inbox === 'DL' ||
                  status_permission_inbox === ''
              "
            >
              <v-list-item
                link
                :to="item.link"
                :disabled="item.disable"
                :key="i"
                v-for="(item, i) in forbusiness_inbox"
                :style="heightListMenuLeft"
                :v-model="item.text"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="ml-6"
                    :style="
                      $route.path === item.link
                        ? listOnBoldFontNormal
                        : listNoBoldFontNormal
                    "
                  >
                    <v-icon class="mb-1" size="22">{{ item.icon }}</v-icon
                    ><span
                      class="pl-1"
                      >{{ $t(item.text),}}</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
            <div
              v-if="
                status_permission_outbox === 'AD' ||
                  status_permission_outbox === 'AL' ||
                  status_permission_outbox === 'DL' ||
                  status_permission_outbox === ''
              "
            >
              <v-list-item
                link
                :to="item.link"
                :disabled="item.disable"
                :key="i"
                :style="heightListMenuLeft"
                v-for="(item, i) in forbusiness_outbox"
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="ml-6"
                    :style="
                      $route.path === item.link
                        ? listOnBoldFontNormal
                        : listNoBoldFontNormal
                    "
                  >
                    <v-icon class="mb-1" size="22">{{ item.icon }}</v-icon>
                    <span
                      class="pl-1"
                      >{{ $t(item.text),}}</span
                    ></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </v-list> -->
        <v-divider class="mx-2"></v-divider>
        <v-list>
          <v-list-item
            :to="item.link"
            :disabled="item.disable"
            :key="i"
            v-for="(item, i) in library"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                style="font-size: 13px;"
                >{{ $t(item.text),}}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-else class="mx-4">
        <v-divider class="mx-2"></v-divider>
      </div>
      <!-- cctv -->
      <!-- <div
        v-if="
          dataAccountActive.type === 'Citizen' &&
            dataAccountCCTV.status === true
        "
      >
        <v-divider></v-divider>
        <v-list dense nav>
          <v-list-item
            link
            :to="item.link"
            :disabled="item.disable"
            :key="i"
            v-for="(item, i) in cctv"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </div> -->
      <!-- เมนูล่างซ้าย -->
      <div v-if="dataAccountActive.type === 'Citizen'">
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="line-height: 24px; font-size:16px; font-weight: 400; color: #333333;"
              >
                <!-- <v-icon size="22" class="mb-1">mdi-inbox-multiple</v-icon>
                <span class="pl-2">{{
                  $t("toolbar.storage")
                }}</span> -->
                <v-layout row justify-start >
                <div class="ml-4">{{ $t("toolbar.storage") }}</div>
                <!-- <div style="position: absolute; right: 2px; top:.5px">
                  <v-btn icon @click="fn_checkAccountActivetypebiz()">
                  <v-icon>mdi-cart</v-icon>
                  </v-btn></div> -->
                  </v-layout>
                </v-list-item-title
              >
              <v-list-item-subtitle class="text-center">
                <v-progress-linear
                  class="mt-4"
                  :color="percentagestoage_setcolor"
                  rounded
                  :value="percentageStorage"
                ></v-progress-linear>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="pl-1 text-left mt-2">
                <span
                  >{{ $t("toolbar.use") }} {{ storage_usage }}
                  {{ $t("toolbar.from") }} {{ showDataStorage }}</span
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-if="dataAccountActive.type === 'Business'">
        <v-list dense class="mt-n4">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                style="line-height: 26px; font-size:16px; font-weight: 400; color: #333333;"
              >
                <v-icon size="22" class="mb-1">mdi-inbox-multiple</v-icon
                ><span class="pl-2">{{
                  $t("toolbar.storage")
                }}</span>
                <!-- <v-layout row justify-start >
                  <div style="position: absolute; right: 2px; top:.5px">
                  <v-btn icon :disabled="role_level !== 'True'" @click="fn_checkAccountActivetypebiz()">
                  <v-icon>mdi-cart</v-icon>
                  </v-btn></div>
                </v-layout> -->
                </v-list-item-title
              >
              <v-list-item-subtitle class="text-center">
                <v-progress-linear
                  class="mt-4"
                  :color="percentagestoagebiz_setcolor_right"
                  rounded
                  :value="percentageStoragebusiness"
                ></v-progress-linear>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="pl-1 text-left mt-2">
                <span
                  >{{ $t("toolbar.use") }} {{ storage_usage_biz }}
                  {{ $t("toolbar.from") }} {{ dataStorageMax }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-if="dataAccountActive.type === 'Citizen'">
        <v-flex class="text-left ml-4">
          <v-btn
            height="40px"
            width="155px"
            outlined
            style="border-color: #C4C4C4; font-size: 14px; font-weight: 400; letter-spacing: 0px; line-height: 24px;"
            :color="iconTheme"
            @click="fn_checkreportSystem()"
            >{{ $t("toolbar.report_problem") }}</v-btn
          >
          <br /><br /><br /><br /><br /><br /><br /><br />
        </v-flex>
      </div>
      <div v-else>
        <v-flex class="text-left ml-4">
          <v-btn
            style="border-color: #C4C4C4; font-size: 14px; font-weight: 400; letter-spacing: 0px; line-height: 24px;"
            :color="iconTheme"
            outlined
            height="40px"
            width="155px"
            @click="fn_checkreportSystem()"
            >{{ $t("toolbar.report_problem") }}</v-btn
          >
          <br /><br /><br /><br /><br /><br /><br /><br />
        </v-flex>
      </div>
    </v-navigation-drawer>

    <v-app-bar
      :scroll-threshold="1"
      app
      id="toolbarcss"
      color="background"
      v-if="imageHeight >= 500"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        :color="color.theme"
      ></v-app-bar-nav-icon>
      <!-- search -->
      <v-row class="ma-5" v-if="quicksearch === true">
        <v-col
          cols="12"
          sm="12"
          lg="10"
          v-if="
            $router.app['_route']['name'] === 'myfiles' ||
              $router.app['_route']['name'] === 'directory' ||
              $router.app['_route']['name'] === 'directorysharefile' || 
              $router.app['_route']['name'] === 'sharedwithme'
          "
        >
         <!-- || 
              $router.app['_route']['name'] === 'sharedwithme' -->

          <div>
            <v-layout>
              <v-flex>
                <v-menu
                  v-model="advancesearch"
                  offset-y
                  transition="slide-y-transition"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  max-height="600"
                >
                  <template v-slot:activator="{ on }">
                    <v-layout>
                      <v-flex sm8 md12 lg12 v-if="imageHeight >= 500">
                        <v-text-field
                          prepend-inner-icon="search"
                          class="mt-1 white--text"
                          v-model="search"
                          :label="$t('quicksearch')"
                          dense
                          single-line
                          solo-inverted
                          flat
                          clearable
                          hide-details
                          :color="color.theme"
                          @click:clear="$emit('loadfile')"
                          @keyup.enter="calladvancesearchdata"
                          @focus="(isFocusLabel = true)"
                          @blur="(isFocusLabel = false)"
                        >
                        <template v-slot:append>
                          <v-flex>
                          <v-btn
                            v-on="on"
                            icon
                            :color="color.theme"
                            class="ma-0 mr-n2"
                            :loading="processdelete"
                            @mouseover="show_search = true"
                            @mouseleave="show_search = false"
                          >
                            <v-icon :color="$vuetify.theme.dark ? isFocusLabel ? color.theme : 'white' : color.theme ">mdi-filter-variant</v-icon>
                          </v-btn>
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-85px;"
                            :value="show_search"
                            content="ค้นหาโดยระบุรายละเอียด"
                            transition="slide-y-transition"
                            color="grey"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-95px;"
                            :value="show_search"
                            content="Search by specifying details"
                            transition="slide-y-transition"
                            color="grey"
                          ></v-badge>
                          </v-flex>
                        </template>
                      </v-text-field>
                      </v-flex>
                      <v-btn
                        icon
                        class="ma-0"
                        @mousedown="fn_openhashtag"
                        v-if="isOpenInfor"  >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            color="#B7B7B7"
                            dark
                            v-on="on"
                          >
                            mdi-help-circle
                          </v-icon>
                        </template>
                        <div  style="max-width: 240px" class="hashtag-more">
                          <span>{{ $t('hashtag_tooltip') }}</span>
                          <span class="font-weight-bold" >{{ $t('more_infor_hashtag') }}</span>
                        </div>
                      </v-tooltip>
                      
                    </v-btn>
                    </v-layout>
                  </template>
      
                  <v-card outlined>
                    <v-card-title>
                      <v-subheader class="ma-0 pa-0 text-h6 font-weight-black " style="font-size:25px">
                        <v-radio-group v-model="selectSearch">
                          <v-radio value="advance_search"></v-radio>
                        </v-radio-group>
                        {{ $t("toolbar.searching.name") }}
                      </v-subheader>
                      <v-spacer />
                      <v-btn
                        icon
                        :color="color.theme"
                        @click="
                          (advancesearch = false),
                            //  ( $emit('loadfile')),
                          fn_loadfile(),
                            (selectSearch = 'advance_search')
                        "
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-card-title>
                    <!-- <v-card-text>
                      <v-radio-group v-model="typeSearch" class="ma-0 pa-0">
                        <v-radio :label="$t('toolbar.searching.keywordsearch')" value="keywordsearch"></v-radio>
                        <v-radio
                          :label="$t('toolbar.searching.fulltextsearch')"
                          value="fulltextsearch"
                          :disabled="!ismydrive"
                        ></v-radio>
                      </v-radio-group>
                      <div v-if="typeSearch == 'fulltextsearch'">
                        <v-divider></v-divider>
                        <v-subheader class="ma-0 pa-0">{{$t('toolbar.searching.optional_fulltextearch')}}</v-subheader>
                        <v-radio-group v-model="type_Fulltextsearch" class="ma-0 pa-0">
                          <v-radio
                            :label="$t('toolbar.searching.optional_name_fulltextearch')"
                            value="file_name"
                          ></v-radio>
                          <v-radio
                            :label="$t('toolbar.searching.optional_content_fulltextearch')"
                            value="content_text"
                          ></v-radio>
                        </v-radio-group>
                      </div>
                    </v-card-text>-->
                    <v-card-text>
                      <v-layout row wrap justify-center fill-height class="pb-4">
                        <v-flex lg10 md10 sm10 xs10>
                          <!-- Checkbox Option Search -->
                          <span class="pa-0 text-h6 font-weight-black" style="font-size:20px">{{
                            $t("toolbar.searching.searchby")
                          }}</span>
                          <v-row>
                            <v-col cols="6">
                              <v-checkbox
                                hide-details
                                v-model="specific_folder"
                                :label="$t('toolbar.searching.fileinfolder')"
                                :color="color.theme"
                                :disabled="selectSearch == 'aspect_search'"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <!-- <v-divider></v-divider> -->
                          <v-row class="mb-5" dense no-gutters>
                            <v-col cols="4">
                              <!-- Column 1 -->
                              <v-checkbox
                                hide-details
                                value="foldername"
                                v-model="optionSearch"
                                :label="$t('toolbar.searching.optional_folder_name')"
                                :color="color.theme"
                                @click="fn_selectFilterSearch(1)"
                              ></v-checkbox>
                            </v-col>
                            <v-col>
                              <!-- Column 2 -->
                              <v-checkbox
                                hide-details
                                value="filename"
                                v-model="optionSearch"
                                :label="
                                  $t('toolbar.searching.optional_name_fulltextearch')
                                "
                                :color="color.theme"
                                @click="fn_selectFilterSearch(1)"
                              ></v-checkbox>
                            </v-col>
                            <v-col>
                              <!-- Column 3 -->
                              <v-layout>
                                <v-checkbox
                                  hide-details
                                  value="hashtag"
                                  v-model="optionSearch"
                                  :label="
                                    $t(
                                      'toolbar.searching.optional_hashtag_fulltextearch'
                                    )
                                  "
                                  :color="color.theme"
                                  @click="fn_selectFilterSearch(1)"
                                ></v-checkbox>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      color="#B7B7B7"
                                      dark
                                      v-on="on"
                                      class="mt-4 ml-3"
                                      @click="fn_openhashtag"
                                    >
                                      mdi-help-circle
                                    </v-icon>
                                  </template>
                                  <div  style="max-width: 240px" class="hashtag-more">
                                    <span>{{ $t('hashtag_tooltip') }}</span> <br />
                                    <span class="font-weight-bold" >{{ $t('more_infor_hashtag') }}</span>
                                  </div>
                                </v-tooltip>
                              </v-layout>
                            </v-col>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row dense no-gutters>
                            <!-- เนื้อหาไฟล์ -->
                            <v-checkbox
                              hide-details
                              value="file_content"
                              v-model="optionSearch"
                              :label="
                                $t(
                                  'toolbar.searching.optional_content_fulltextearch'
                                )
                              "
                              :color="color.theme"
                              @click="fn_selectFilterSearch(2)"
                            ></v-checkbox>
                          </v-row>
                          <!-- <v-radio-group
                            v-model="type_Fulltextsearch"
                            class="ma-0 pa-0"
                            row
                            :disabled="selectSearch == 'aspect_search'"
                          >
                            <v-radio
                              :color="color.theme"
                              :label="$t('toolbar.searching.optional_folder_name')"
                              value="folder"
                            ></v-radio>
                            <v-radio
                              :color="color.theme"
                              :label="$t('toolbar.searching.optional_name_fulltextearch')"
                              value="file_name"
                            ></v-radio>
                            <v-radio
                              :color="color.theme"
                              :label="$t('toolbar.searching.optional_content_fulltextearch')"
                              value="content_text"
                            ></v-radio>
                            <v-radio
                              :color="color.theme"
                              :label="$t('toolbar.searching.optional_hashtag_fulltextearch')"
                              value="hashtag"
                            ></v-radio>
                          </v-radio-group> -->
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center fill-height>
                        <v-flex lg10 md10 sm10 xs10>
                          <v-text-field
                            v-model="search"
                            outlined
                            :label="$t('toolbar.searching.detail')"
                            :color="color.theme"
                            dense
                            :error-messages="errorsearch"
                            prepend-icon="image_search"
                            :disabled="selectSearch == 'aspect_search'"
                            @keyup.enter="calladvancesearchdata"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-center fill-height>
                        <v-flex lg5 md5 sm5 xs5>
                          <v-dialog
                            persistent
                            v-model="menufromdate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="fromdate"
                                :color="color.theme"
                                :label="$t('toolbar.searching.searchfromdate')"
                                :disabled="
                                  selectSearch == 'aspect_search' ||
                                    type_Fulltextsearch == 'hashtag'
                                "
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                                @keyup.enter="calladvancesearchdata"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="fromdate"
                              @input="menufromdate = false"
                              :color="color.theme"
                              @keyup.enter="calladvancesearchdata"
                              :max="todate === '' || todate === null ? date_now : todate"
                            >
                              <v-spacer></v-spacer>
                              <v-btn dark color="error" @click="menufromdate = false">
                                ปิด
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                        <v-flex lg5 md5 sm5 xs5 class="pl-2">
                          <v-dialog
                            persistent
                            v-model="menutodate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="todate"
                                :color="color.theme"
                                :disabled="
                                  selectSearch == 'aspect_search' ||
                                    type_Fulltextsearch == 'hashtag'
                                "
                                :label="$t('toolbar.searching.searchtodate')"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                                @keyup.enter="calladvancesearchdata"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="todate"
                              @input="menutodate = false ,changeFromdatedocument()"
                              :color="color.theme"
                              @keyup.enter="calladvancesearchdata"
                              :max="date_now"
                            >
                              <v-spacer></v-spacer>
                              <v-btn dark color="error" @click="menutodate = false">
                                ปิด
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-divider></v-divider>
                    <!-- Aspect Search -->
                    <!-- <v-card-title v-if="aspect_data[0].aspect == 'True'">
                      <v-subheader class="ma-0 pa-0">
                        <v-radio-group v-model="selectSearch">
                          <v-radio value="aspect_search"></v-radio>
                        </v-radio-group>
                        {{ $t("toolbar.searching.aspect_name") }}
                      </v-subheader>
                      <v-spacer />
                    </v-card-title> -->
                    <!-- <v-card-text v-if="aspect_data[0].aspect == 'True'" >
                      <div v-for="(item, index) in aspect_data[0].aspect_key" :key="index" >
                        <v-layout row wrap justify-center fill-height v-if="item.key_type == 'varchar'">
                          <v-flex lg10 class="pa-1">
                            <v-text-field
                              outlined
                              :label="item.key_name"
                              :color="color.theme"
                              dense
                              @input="(aspect_search) => updateAspectData(index, aspect_search)"
                              prepend-icon="image_search"
                              :disabled="selectSearch == 'advance_search'"
                              @keyup.enter="aspectbutton"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap justify-center fill-height v-if="item.key_type == 'dropdown'"  >
                          <v-flex lg10 pa-1>
                            <v-select
                              :items="item.data"
                              :label="item.key_name"
                              :value="item.data"
                              @input="(aspect_value) => updateAspectData(index, aspect_value)"
                              outlined
                              dense
                              prepend-icon="image_search"
                              :disabled="selectSearch == 'advance_search'"
                              @keyup.enter="aspectbutton"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                        ถ้า 2 ตัวบน key_type ไม่เปลี่ยน dateให้ใช้ v-else ไปเลย
                        <v-layout row wrap justify-center fill-height v-if="item.key_type == 'date'"  >
                          <v-flex lg5 class="pa-1">
                            <v-dialog
                              v-model="aspect_datefrom[item.dialog_date_from]"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="aspect_valuedatefrom[item.dialog_date_from]"
                                  :value="item.date_from"
                                  :color="color.theme"
                                  :label="item.key_name"
                                  :disabled="selectSearch == 'advance_search'"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  dense
                                  outlined
                                  clearable
                                  @keyup.enter="aspectbutton"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                @input="aspect_datefrom[item.dialog_date_from] = false"
                                v-model="aspect_valuedatefrom[item.dialog_date_from]"
                                :color="color.theme"
                                @keyup.enter="aspectbutton"
                              >
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                          <v-flex lg5 class="pa-1">
                            <v-dialog
                              v-model="aspect_dateto[item.dialog_date_to]"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                  
                                <v-text-field
                                  v-model="aspect_valuedateto[item.dialog_date_to]"
                                  :color="color.theme"
                                  :disabled="selectSearch == 'advance_search'"
                                  :label="$t('toolbar.searching.searchtodate')"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  dense
                                  outlined
                                  clearable
                                  @keyup.enter="aspectbutton"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="aspect_valuedateto[item.dialog_date_to]"
                                @input="aspect_dateto[item.dialog_date_to] = false"
                                :color="color.theme"
                                @keyup.enter="aspectbutton"
                              ></v-date-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                        ===== condition ยังไม่ได้ใช้ (รอ api) =====
                        <div v-if="item.key_type == 'condition'">
                          <v-layout row wrap justify-center fill-height v-for="(c_item, c_index) in conditions" :key="c_index">
                            <v-flex lg3 sm4 pa-1 ml-lg-5 ml-sm-6 ml-8>
                              <v-text-field
                                v-model="c_item.title"
                                outlined
                                label="title"
                                :color="color.theme"
                                dense                                              
                                @input="(aspect_search) => updateAspectData(index, aspect_search)"
                                :disabled="selectSearch == 'advance_search'"
                                @keyup.enter="aspectbutton"
                              ></v-text-field>
                            </v-flex>
                            <v-flex lg4 sm5 xs7 pa-1 ml-sm-0 ml-3>
                              <v-text-field
                                v-model="c_item.detail"
                                outlined
                                label="detail"
                                :color="color.theme"
                                dense                                              
                                @input="(aspect_search) => updateAspectData(index, aspect_search)"
                                :disabled="selectSearch == 'advance_search'"
                                @keyup.enter="aspectbutton"
                              ></v-text-field>
                            </v-flex>
                            <v-flex lg2 sm2 xs3 pa-1>
                              <v-btn-toggle 
                                v-model="c_item.operation"
                                rounded 
                                mandatory
                                :color="c_item.operation ? 'success' : 'grey'"
                                v-if="!(c_item === conditions[conditions.length - 1])"
                              >
                                <v-btn fab small value="OR" :disabled="selectSearch == 'advance_search'">OR</v-btn>
                                <v-btn fab small value="AND" :disabled="selectSearch == 'advance_search'">AND</v-btn>
                              </v-btn-toggle>
                              <v-btn
                                outlined
                                fab
                                small
                                :color="color.theme"
                                :disabled="selectSearch == 'advance_search'"
                                v-on:click="addSearch()"
                                v-if="c_item === conditions[conditions.length - 1]"
                              >
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </div>                      
                      </div>
                    </v-card-text> -->
      
                    <!-- ปุ่ม -->
                    <v-card-actions class="mr-5">
                      <v-spacer></v-spacer>
                      <!-- Advance Search -->
                      <v-btn
                        v-if="selectSearch == 'advance_search'"
                        @click="calladvancesearchdata"
                        :color="color.theme"
                        :dark="color.darkTheme"
                        :loading="processdelete"
                        >{{ $t("toolbar.searching.button") }}</v-btn
                      >
                      <!-- Aspect serach button -->
                      <v-btn
                        v-if="selectSearch == 'aspect_search'"
                        @click="aspectbutton"
                        :color="color.theme"
                        :dark="color.darkTheme"
                        >{{ $t("toolbar.searching.button") }}</v-btn
                      >
                    </v-card-actions>
                    <v-progress-linear
                      :active="processdelete"
                      indeterminate
                      color="green"
                    ></v-progress-linear>
                  </v-card>
                </v-menu>
              </v-flex>
              <!-- ปุ่ม smart upload -->
              <v-flex v-if="$router.app['_route']['name'] === 'directory' && dataAccountActive.type === 'Business' && feature_inputmore === true">
              <v-menu
                class="ma-5"
                v-model="smartuploadsearch"
                offset-y
                transition="slide-y-transition"
                :close-on-click="true"
                :close-on-content-click="false"
                max-height="800"
                max-width="500"
              >
              <template v-slot:activator="{ on }">
                <v-layout>
                  <v-flex>
                  <v-btn
                    v-on="on"
                    icon
                    :color="color.theme"
                    class="ma-0 mt-1 ml-2"
                    :loading="processdelete"
                    @mouseover="show_smartuploadsearch = true"
                    @mouseleave="show_smartuploadsearch = false"
                  >
                  <!-- @click="fn_gettamplate_smartupload()" -->
                    <v-icon>mdi-layers-search</v-icon>
                  </v-btn>
                  <v-badge
                      v-if="$t('default') === 'th'"
                      style="z-index:10000;bottom:-45px;left:-75px;"
                      :value="show_smartuploadsearch"
                      content="ค้นหา smart upload"
                      transition="slide-y-transition"
                      color="grey"
                    ></v-badge>
                    <v-badge
                      v-else
                      style="z-index:10000;bottom:-45px;left:-95px;"
                      :value="show_smartuploadsearch"
                      content="Search by smart upload"
                      transition="slide-y-transition"
                      color="grey"
                    ></v-badge>
                </v-flex>
                </v-layout>
              </template>
              <v-card width="550" outlined>
                  <!-- smart upload search จอ lg -->
                  <v-card-title >
                    <v-layout row wrap justify-center fill-height class="pl-8 pb-2">
                      <v-flex lg10 md10 sm10 xs10>
                        <span class="pa-0 text-h6 font-weight-black" style="font-size:25px">
                        {{ $t("toolbar.searching.smartuploadsearch") }}
                        </span>
                      </v-flex>
                      <v-spacer />
                    <v-btn
                      icon
                      :color="color.theme"
                      @click="
                        (smartuploadsearch = false), $emit('cloasedialogsmartsearch')
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-layout>
                    <!-- <v-subheader class="ma-0 pa-0 text-h6 font-weight-black " style="font-size:25px">
                      <v-radio-group v-model="selectSearch_smartupload">
                        <v-radio value="smartupload_search"></v-radio>
                      </v-radio-group>
                      {{ $t("toolbar.searching.smartuploadsearch") }}
                    </v-subheader> -->
                  </v-card-title>
                  <v-card-text style="overflow-y: hidden">
                    <v-layout row wrap justify-center fill-height class="pb-4">
                      <v-flex lg10 md10 sm10 xs10>
                        <!-- Checkbox Option Search -->
                        <span class="pa-0 text-h6 font-weight-black" style="font-size:20px">{{
                          $t("toolbar.searching.searchby")
                        }}</span>
                      </v-flex>
                    </v-layout>
                    <v-layout>
                      <v-flex lg8 md8 sm8 xs8>
                        <v-select
                          outlined
                          dense
                          class="pa-1 pt-0 pb-0"
                          v-model="item_template"
                          :items="itemstemplate"
                          item-value="template_id"
                          item-text="template_name"
                          label="tamplate"
                          prepend-icon="mdi-layers"
                          :color="color.theme"
                          :item-color="color.theme"
                          @change="fn_get_detail_template(item_template)"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-card-text style="max-height: 380px; overflow-y: auto">
                      <v-list 
                        dense
                        v-for="(item, i) in datatemplate"
                        :key="i"
                        class="mb-3"
                      >
                        <v-list-item class="mt-n1 mb-n6">
                          <v-list-item-content v-if="item.aspect_type === 'DROPDOWN'">
                            <v-select
                              v-model="item.value"
                              :items="item.aspect_fix_value"
                              outlined
                              dense
                              item-color="color.theme"
                              :color="color.theme"
                              :label="item.aspect_label"
                              prepend-icon="mdi-format-list-bulleted-type"
                              :item-value="item.aspect_fix_value[0]"
                            ></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-else-if="item.aspect_type === 'DATE'">
                            <v-dialog
                              persistent
                              v-model="item.menufromdate"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              width="290px"
                              clearable
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="item.fromdate"
                                  :color="color.theme"
                                  :label="item.aspect_label"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  dense
                                  outlined
                                  clearable
                                  @keyup.enter="calladvancesearchdata"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="item.fromdate"
                                @input="item.menufromdate = false"
                                :color="color.theme"
                                @keyup.enter="calladvancesearchdata"
                                :max="todate === '' || todate === null ? date_now : todate"
                              >
                                <v-spacer></v-spacer>
                                <v-btn dark color="error" @click="item.menufromdate = false">
                                  ปิด
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-list-item-content>
                          <v-list-item-content v-else-if="item.aspect_type === 'TEXT'">
                            <v-text-field
                              v-model="item.value_search"
                              outlined
                              dense
                              :label="item.aspect_label"
                              prepend-icon="mdi-file-find-outline"
                              clearable
                              :color="color.theme"
                            ></v-text-field>
              
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <!-- <div class="form-group" v-for="(list_detail_tem_text, i) in list_detail_tem_text" :key="i">
                        <v-layout lg12>
                          <v-flex lg10>
                            <v-text-field
                              outlined
                              dense
                              :label="list_detail_tem_text.aspect_label"
                              prepend-icon="mdi-file-find-outline"
                              clearable
                              :color="color.theme"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                    </div>
                    <div class="form-group" v-for="(list_detail_tem_dropdown, i) in list_detail_tem_dropdown" :key="i">
                        <v-layout lg12>
                          <v-flex lg10>
                            <v-select
                              :items="list_detail_tem_dropdown.aspect_fix_value"
                              :label="list_detail_tem_dropdown.aspect_label"
                              :color="color.theme"
                              outlined
                              dense
                              clearable
                              prepend-icon="mdi-format-list-bulleted-type"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                    </div>
                    <div class="form-group" v-for="(list_detail_tem_date, i) in list_detail_tem_date" :key="i">
                        <v-layout lg12>
                          <v-flex lg10>
                        <v-dialog
                          persistent
                          v-model="menufromdate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          width="290px"
                          clearable
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fromdate"
                              :color="color.theme"
                              :label="list_detail_tem_date.aspect_label"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              outlined
                              clearable
                              @keyup.enter="calladvancesearchdata"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="fromdate"
                            @input="menufromdate = false"
                            :color="color.theme"
                            @keyup.enter="calladvancesearchdata"
                            :max="todate === '' || todate === null ? date_now : todate"
                          >
                            <v-spacer></v-spacer>
                            <v-btn dark color="error" @click="menufromdate = false">
                              ปิด
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-flex>
                        </v-layout>
                    </div> -->
                  </v-card-text>
                  <!-- ปุ่ม -->
                  <v-card-actions class="mr-5">
                    <v-spacer></v-spacer>
                      <!-- smart upload search button -->
                      <v-btn
                        @click="callsmartuploadsearch"
                        :color="color.theme"
                        :dark="color.darkTheme"
                        >{{ $t("toolbar.searching.button") }}</v-btn
                      >
                  </v-card-actions>
                  <v-progress-linear
                    :active="processdelete"
                    indeterminate
                    color="green"
                  ></v-progress-linear>
                </v-card>
              </v-menu>
              </v-flex>
            </v-layout>
          </div>

        </v-col>
        <v-col cols="12" sm="8" lg="8" v-else-if="$router.app['_route']['name'] === 'system-personal-log'">
        </v-col>
        <v-col cols="12" sm="8" lg="8" v-else>
          <v-text-field
            v-if="imageHeight >= 500"
            v-model="search"
            :label="$t('quicksearch')"
            append-icon="search"
            dense
            single-line
            solo-inverted
            flat
            clearable
            hide-details
            @input="$emit('inputdata', search)"
            :color="color.theme"
            @click:clear="$emit('loadfile')"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="search"
            :label="$t('quicksearch')"
            dense
            single-line
            solo-inverted
            flat
            clearable
            hide-details
            @input="$emit('inputdata', search)"
            :color="color.theme"
            @click:clear="$emit('loadfile')"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-spacer v-if="quicksearch !== true"></v-spacer>
      <!-- <v-menu transition="slide-x-transition" left>
        <template v-slot:activator="{ on }">
           <v-btn fab text v-on="on" small  >
             <v-icon>mdi-palette</v-icon>
          </v-btn>
      </template>-->
      <!-- <v-list dense>
        <v-list-item v-for="(item, i) in colors" :key="i" @click="changecolor(item.data)">
            <v-list-item-content>
              <v-btn  elevation="1" fab x-small :color="item.name"></v-btn>
            </v-list-item-content>
           
          </v-list-item>
      </v-list>-->
      <!-- <v-layout><v-flex>
              <v-btn v-for="(item, i) in colors" :key="i" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
      </v-flex></v-layout>-->
      <!-- </v-menu> -->
      <!-- แจ้งเตือนเมื่อมีรายการไม่ชำระ -->
      <!-- <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn fab text v-on="on" small>
            <v-badge color="red" content="6"> 
              <v-icon large :color="color.theme" @click="$router.replace({ path: '/packagelistpay' })">mdi-bell</v-icon> 
            </v-badge>
          </v-btn>
        </template>
      </v-menu> -->
      <!-- ใบรับรองแพทย์หมอพร้อม [new] ใช้-->
      <!-- <v-menu transition="slide-y-transition" bottom close-on-click offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            @mouseover="show_mohpromt = true"
            @mouseleave="show_mohpromt = false"
            class="mr-n1"
            fab
            text
            v-on="on"
          >
            <v-icon large :color="color.theme">mdi-file-multiple</v-icon>
            <v-badge
              v-if="$t('default') === 'th'"
              style="z-index:10000;bottom:-45px;left:-35px;"
              :value="show_mohpromt"
              :content="$t('balloon.Medandetax')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="z-index:10000;bottom:-45px;left:-45px;"
              :value="show_mohpromt"
              :content="$t('balloon.Medandetax')"
              transition="slide-y-transition"
            ></v-badge>
          </v-btn>
        </template>
        <v-list> -->
      <!-- <v-list-item @click.stop="opendialogmed = true" v-if="dataAccountActive.type !== 'Business'">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-file-upload</v-icon>
            </v-list-item-avatar>
            <v-list-item-title :style="darkModeText">{{ $t('med.medicalcertificate') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="gotoinboxoutbox" v-if="dataAccountActive.type === 'Business'">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-folder-upload</v-icon>
            </v-list-item-avatar>
            <v-list-item-title :style="darkModeText">{{ $t('med.etax') }}</v-list-item-title>
          </v-list-item> -->
      <!-- <v-list-item @click.stop="opendialogshopdee = true">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-file-upload</v-icon>
            </v-list-item-avatar>
            <v-list-item-title :style="darkModeText"
              >ช้อปดีมีคืน</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- ใบรับรองแพทย์หมอพร้อม ใช้-->
      <!-- <v-btn
          text 
          fab
          @click="opendialogmed = true"
          @mouseover="show_mohpromt = true"
          @mouseleave="show_mohpromt = false"
        >
          <v-icon size="32" :color="color.theme">mdi-file-multiple</v-icon>
          <v-badge
            v-if="$t('default') === 'th'"
            style="z-index:10000;bottom:-45px;left:-50px;"
            :value="show_mohpromt"
            :content="$t('balloon.Medicalcertificate')"
            transition="slide-y-transition"
          ></v-badge>
          <v-badge
            v-else
            style="z-index:10000;bottom:-45px;left:-65px;"
            :value="show_mohpromt"
            :content="$t('balloon.Medicalcertificate')"
            transition="slide-y-transition"
          ></v-badge>
        </v-btn> -->
      <!-- dark mode ใช้-->
      <!-- <v-btn
        text
        fab
        @click="darkMode"
        @mouseover="show_darkmode = true"
        @mouseleave="show_darkmode = false"
      >
        <v-icon large :color="color.theme">{{
          $vuetify.theme.dark
            ? "mdi-white-balance-sunny"
            : "mdi-moon-waxing-crescent"
        }}</v-icon>
        <v-badge
          v-if="$t('default') === 'th'"
          style="z-index:10000;bottom:-45px;left:-35px;"
          :value="show_darkmode"
          :content="
            $vuetify.theme.dark
              ? $t('balloon.lightmode')
              : $t('balloon.darkmode')
          "
          transition="slide-y-transition"
        ></v-badge>
        <v-badge
          v-else
          style="z-index:10000;bottom:-45px;left:-45px;"
          :value="show_darkmode"
          :content="
            $vuetify.theme.dark
              ? $t('balloon.lightmode')
              : $t('balloon.darkmode')
          "
          transition="slide-y-transition"
        ></v-badge>
      </v-btn> -->
      <!-- คู่มือ ใช้-->
      <!-- <v-menu bottom close-on-click offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            @mouseover="show_manual = true"
            @mouseleave="show_manual = false"
            v-on="on"
            fab
            text
          >
            <v-badge
              v-if="$t('default') === 'th'"
              style="z-index:10000;bottom:-45px;left:-50px;"
              :value="show_manual"
              :content="$t('balloon.manual')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="z-index:10000;bottom:-45px;left:-50px;"
              :value="show_manual"
              :content="$t('balloon.manual')"
              transition="slide-y-transition"
            ></v-badge>
            <v-icon large :color="color.theme"
              >mdi-book-open-page-variant</v-icon
            >
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="clickManual(1)">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-book</v-icon>
            </v-list-item-avatar>
            <v-list-item-title style="line-height: 24px">
              {{ $t("manual.businessbox") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="clickManual(2)">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-book</v-icon>
            </v-list-item-avatar>
            <v-list-item-title style="line-height: 24px">
              {{ $t("manual.oneboxmanual") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="clickManual(3)">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-book</v-icon>
            </v-list-item-avatar>
            <v-list-item-title style="line-height: 24px">
              {{ $t("manual.etax") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="clickManual(4)">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon"
                >mdi-arrow-right-drop-circle</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-title>
              {{ $t("manual.useonebox") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="clickManual(5)">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon"
                >mdi-arrow-right-drop-circle</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-title style="line-height: 24px">
              {{ $t("manual.storage") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- upload ใช้-->
      <!-- <v-menu
        transition="slide-y-transition"
        bottom
        close-on-click
        offset-y
        v-if="checkroutername !== 'document-management'"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @mouseover="show_upload = true"
            @mouseleave="show_upload = false"
            class="mr-2"
            fab
            text
            v-on="on"
            :disabled="permission_denied_account"
          >
            <v-icon large :color="color.theme">mdi-cloud-upload-outline</v-icon>
            <v-badge
              v-if="$t('default') === 'th'"
              style="z-index:10000;bottom:-45px;left:-50px;"
              :value="show_upload"
              :content="$t('balloon.upload_create')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="z-index:10000;bottom:-45px;left:-60px;"
              :value="show_upload"
              :content="$t('balloon.upload_create')"
              transition="slide-y-transition"
            ></v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click.stop="openfile_">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-file-upload</v-icon>
            </v-list-item-avatar>
            <v-list-item-title :style="darkModeText">{{
              $t("uploadfile.name")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="opencreatefolder()">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-folder-upload</v-icon>
            </v-list-item-avatar>
            <v-list-item-title :style="darkModeText">{{
              $t("mainRClick.uploadfolder")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="openfolder_">
            <v-list-item-avatar>
              <v-icon size="30" :color="darkModeIcon">mdi-folder-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-title :style="darkModeText">{{
              $t("uploadfolder.name")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <!-- ซื้อ storage -->
      <!-- <v-btn
        :disabled="
          role_level !== 'True' && dataAccountActive.type !== 'Citizen'
        "
        @mouseover="show_purchase = true"
        @mouseleave="show_purchase = false"
        class="mr-2"
        fab
        text
        @click="fn_checkAccountActivetypebiz(), (show_purchase = false)"
      >
        <v-icon large :color="color.theme">mdi-shopping</v-icon>
        <v-badge
          v-if="$t('default') === 'th'"
          style="z-index:10000;bottom:-45px;left:-60px;"
          :value="show_purchase"
          :content="$t('balloon.purchase')"
          transition="slide-y-transition"
        ></v-badge>
        <v-badge
          v-else
          style="z-index:10000;bottom:-45px;left:-65px;"
          :value="show_purchase"
          :content="$t('balloon.purchase')"
          transition="slide-y-transition"
        ></v-badge>
      </v-btn> -->
      <!-- link etax -->
      <!-- <div class="pointer text-right mr-2" @click="fn_gotoetax()">
        <img
          class="pt-3 pb-1"
          src="@/assets/img/logo-etaxoneth.webp"
          width="20%"
          height="20%"
        />
      </div> -->
      <v-flex xs1 lg1 v-if="dataAccountActive.type !== 'Citizen' && imageHeight >= 400" >
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn class="ml-2 pa-2" text x-large v-on="on" @click="fn_gotoetax()">
            <img
            class="pt-3 pb-1"
            src="@/assets/img/logo-etaxoneth.webp"
            width="65px"
            height="65px"
            />
              <!-- <img
                class="pt-3 pb-1"
                src="@/assets/img/logo-etaxoneth.webp"
                 width="23%"
                height="23%"
              /> -->
            </v-btn>
          </template>
        </v-menu>
      </v-flex>
      <!-- จัดการบริษัท ใช้-->
      <v-btn
        v-if="role_level === 'True' && dataAccountActive.type !== 'Citizen'"
        @mouseover="show_business_management = true"
        @mouseleave="show_business_management = false"
        class="mr-2"
        rounded
        :color="color.theme"
        dark
        small
        @click="fn_admin()"
        >{{ $t("balloon.business_management") }}
        <!-- <v-icon>mdi-card-account-details-outline</v-icon> -->
        <!-- <img src="@/assets/icon_admin.png" alt="John" width="35px" /> -->
        <v-badge
          v-if="$t('default') === 'th'"
          style="z-index:10000;bottom:-45px;left:-68px;"
          :value="show_business_management"
          :content="$t('balloon.business_management')"
          transition="slide-y-transition"
        ></v-badge>
        <v-badge
          v-else
          style="z-index:10000;bottom:-45px;left:-145px;"
          :value="show_business_management"
          :content="$t('balloon.business_management')"
          transition="slide-y-transition"
        ></v-badge>
      </v-btn>

      <!-- เก่า ไม่ได้ใช้ -->
      <!-- จัด layout ให้ดันเมนูไปทางขวา -->
      <!-- <v-layout v-if="quicksearch !== true"> </v-layout> -->
      <!-- Mutimenu -->
      <!-- <div v-if="show_menu === false" class="ma-3 mr-2">
        <v-btn fab small text class="ma-0" disabled><span></span></v-btn>
        <v-btn fab small text class="ma-0" disabled><span></span></v-btn>
        <v-btn fab small text class="ma-0" disabled><span></span></v-btn>
        <v-btn fab small text class="ma-0" disabled><span></span></v-btn>
        <v-btn
          :color="color.theme"
          rounded
          class="pa-3 ml-3 white--text"
          @click="(show_menu = true), (show_closemenu = false)"
        >
          <v-icon>mdi-widgets-outline</v-icon>
        </v-btn>
      </div> -->
      <!-- <div v-else class="ml-n10 mt-2 mb-2 mr-2"> -->
      <!-- ใบรับรองแพทย์หมอพร้อม -->
      <!-- <v-btn
          fab
          small
          :style="darkModeMutiIcon"
          outlined
          class="ma-1 pa-0"
          @click="opendialogmed = true"
          @mouseover="show_mohpromt = true"
          @mouseleave="show_mohpromt = false"
        >
          <v-icon :color="color.theme">mdi-file-multiple</v-icon>
          <v-badge
            v-if="$t('default') === 'th'"
            style="z-index:10000;bottom:-45px;left:-20px;"
            :value="show_mohpromt"
            :content="$t('balloon.Medicalcertificate')"
            transition="slide-y-transition"
          ></v-badge>
          <v-badge
            v-else
            style="z-index:10000;bottom:-45px;left:-30px;"
            :value="show_mohpromt"
            :content="$t('balloon.Medicalcertificate')"
            transition="slide-y-transition"
          ></v-badge>
        </v-btn> -->
      <!-- dark mode -->
      <!-- <v-btn
          fab
          small
          :style="darkModeMutiIcon"
          outlined
          class="ma-1 pa-0"
          @click="darkMode"
          @mouseover="show_darkmode = true"
          @mouseleave="show_darkmode = false"
        >
          <v-icon :color="color.theme">{{
            $vuetify.theme.dark
              ? "mdi-white-balance-sunny"
              : "mdi-moon-waxing-crescent"
          }}</v-icon>
          <v-badge
            v-if="$t('default') === 'th'"
            style="z-index: 10000; bottom: -45px; left: -35px"
            :value="show_darkmode"
            :content="
              $vuetify.theme.dark
                ? $t('balloon.lightmode')
                : $t('balloon.darkmode')
            "
            transition="slide-y-transition"
          ></v-badge>
          <v-badge
            v-else
            style="z-index: 10000; bottom: -45px; left: -45px"
            :value="show_darkmode"
            :content="
              $vuetify.theme.dark
                ? $t('balloon.lightmode')
                : $t('balloon.darkmode')
            "
            transition="slide-y-transition"
          ></v-badge>
        </v-btn> -->
      <!-- เมนู -->
      <!-- <v-menu bottom close-on-click offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              :style="darkModeMutiIcon"
              class="ma-1 pa-0"
              @mouseover="show_manual = true"
              @mouseleave="show_manual = false"
              v-on="on"
              fab
              small
              outlined
            >
              <v-badge
                v-if="$t('default') === 'th'"
                style="z-index: 10000; bottom: -45px; left: -50px"
                :value="show_manual"
                :content="$t('balloon.manual')"
                transition="slide-y-transition"
              ></v-badge>
              <v-badge
                v-else
                style="z-index: 10000; bottom: -45px; left: -60px"
                :value="show_manual"
                :content="$t('balloon.manual')"
                transition="slide-y-transition"
              ></v-badge>
              <v-icon :color="color.theme">mdi-book-outline</v-icon>
            </v-btn>
          </template>
          <v-list class="text-center" rounded>
            <v-list-item @click="clickManual(1)">
              <v-list-item-title style="line-height: 24px">
                {{ $t("manual.businessbox") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="clickManual(2)">
              <v-list-item-title style="line-height: 24px">
                {{ $t("manual.oneboxmanual") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="clickManual(3)">
              <v-list-item-title style="line-height: 24px">
                {{ $t("manual.etax") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="clickManual(4)">
              <v-list-item-title>
                {{ $t("manual.useonebox") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="clickManual(5)">
              <v-list-item-title style="line-height: 24px">
                {{ $t("manual.storage") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      <!-- อัปโหลด -->
      <!-- <v-menu
          transition="slide-y-transition"
          bottom
          close-on-click
          offset-y
          v-if="checkroutername !== 'document-management'"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="ma-1 pa-0"
              @mouseover="show_upload = true"
              @mouseleave="show_upload = false"
              fab
              small
              outlined
              :style="
                permission_denied_account === false ? darkModeMutiIcon : ''
              "
              v-on="on"
              :disabled="permission_denied_account"
            >
              <v-icon :color="color.theme">mdi-cloud-upload-outline</v-icon>
              <v-badge
                v-if="$t('default') === 'th'"
                style="z-index: 10000; bottom: -45px; left: -50px"
                :value="show_upload"
                :content="$t('balloon.upload_create')"
                transition="slide-y-transition"
              ></v-badge>
              <v-badge
                v-else
                style="z-index: 10000; bottom: -45px; left: -60px"
                :value="show_upload"
                :content="$t('balloon.upload_create')"
                transition="slide-y-transition"
              ></v-badge>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click.stop="openfile_">
              <v-list-item-avatar>
                <v-icon size="30" :color="darkModeIcon">mdi-file-upload</v-icon>
              </v-list-item-avatar>
              <v-list-item-title :style="darkModeText">{{
                $t("uploadfile.name")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="opencreatefolder()">
              <v-list-item-avatar>
                <v-icon size="30" :color="darkModeIcon"
                  >mdi-folder-upload</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-title :style="darkModeText">{{
                $t("mainRClick.uploadfolder")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click.stop="openfolder_">
              <v-list-item-avatar>
                <v-icon size="30" :color="darkModeIcon">mdi-folder-plus</v-icon>
              </v-list-item-avatar>
              <v-list-item-title :style="darkModeText">{{
                $t("uploadfolder.name")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      <!-- Purchase  -->
      <!-- <v-btn
          @mouseover="show_purchase = true"
          @mouseleave="show_purchase = false"
          class="ma-1 pa-0"
          fab
          small
          :style="darkModeMutiIcon"
          outlined
          @click="fn_checkAccountActivetypebiz(), (show_purchase = false)"
        >
          <v-icon :color="color.theme">mdi-cart-outline</v-icon>
          <v-badge
            v-if="$t('default') === 'th'"
            style="z-index: 10000; bottom: -45px; left: -60px"
            :value="show_purchase"
            :content="$t('balloon.purchase')"
            transition="slide-y-transition"
          ></v-badge>
          <v-badge
            v-else
            style="z-index: 10000; bottom: -45px; left: -65px"
            :value="show_purchase"
            :content="$t('balloon.purchase')"
            transition="slide-y-transition"
          ></v-badge>
        </v-btn> -->
      <!-- Close Menu -->
      <!-- <v-btn
          fab
          small
          color="error"
          outlined
          class="ma-1 pa-0"
          @click="show_menu = false"
          @mouseover="show_closemenu = true"
          @mouseleave="show_closemenu = false"
        >
          <v-icon>clear</v-icon>
          <v-badge
            v-if="$t('default') === 'th'"
            style="z-index:10000;bottom:-45px;left:-20px;"
            :value="show_closemenu"
            :content="$t('balloon.clear')"
            transition="slide-y-transition"
          ></v-badge>
          <v-badge
            v-else
            style="z-index:10000;bottom:-45px;left:-30px;"
            :value="show_closemenu"
            :content="$t('balloon.clear')"
            transition="slide-y-transition"
          ></v-badge>
        </v-btn> 
      </div> -->

      <v-divider inset vertical></v-divider>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn text x-large v-on="on">
            <v-list-item-avatar
              v-if="dataCitizenProfile.picture == null"
              size="90"
            >
              <!-- <v-badge
                color="#D6AD60"
                icon="mdi-crown"
                overlap
                v-if="role_level === 'True'"
              >
                <v-avatar size="45">
                  <img
                    :class="role_level === 'True' ? 'admin' : ''"
                    src="@/assets/user.png"
                    alt="John"
                  />
                </v-avatar>
              </v-badge>
              <v-avatar size="45" v-else>
                <img src="@/assets/user.png" alt="John" />
              </v-avatar> -->

              <v-badge
                avatar
                bordered
                overlap
                color="#D6AD60"
                v-if="
                  role_level === 'True' && dataAccountActive.type !== 'Citizen'
                "
              >
                <template v-slot:badge>
                  <v-avatar>
                    <v-icon>mdi-crown</v-icon>
                  </v-avatar>
                </template>

                <v-avatar size="50">
                  <img
                    :class="role_level === 'True' ? 'admin' : ''"
                    src="@/assets/user.png"
                    alt="John"
                  />
                </v-avatar>
              </v-badge>
              <v-avatar size="45" v-else>
                <img src="@/assets/user.png" alt="John" />
              </v-avatar>

              <!-- <img :src="'https://image.flaticon.com/icons/svg/149/149071.svg'" alt="John" /> -->
              <!-- <img
                  :class="role_level === 'True' ? 'admin' : ''"
                  src="@/assets/user.png"
                  alt="John"
                /> -->
            </v-list-item-avatar>
            <v-list-item-avatar size="90" v-else>
              <v-badge
                avatar
                bordered
                overlap
                color="#D6AD60"
                v-if="role_level === 'True'"
              >
                <template v-slot:badge>
                  <v-avatar>
                    <v-icon>mdi-crown</v-icon>
                  </v-avatar>
                </template>

                <v-avatar size="50">
                  <img
                    :class="role_level === 'True' ? 'admin' : ''"
                    :src="
                      'data:image/jpeg;base64,' + dataCitizenProfile.picture
                    "
                    alt="John"
                  />
                </v-avatar>
              </v-badge>
              <v-avatar size="45" v-else>
                <img
                  :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture"
                  alt="John"
                />
              </v-avatar>
            </v-list-item-avatar>

            <div class="text-left" v-if="imageHeight >= 500">
              <b>{{ fullname }}</b>
              <!-- <v-btn
                  v-if ="role_level === 'True' && dataAccountActive.type !== 'Citizen'"
                  @mouseover="show_admin = true"
                  @mouseleave="show_admin = false"
                  class="ml-3"
                  text 
                  x-small
                >
                <img src="@/assets/admin.png" alt="John" width="20px" />
                  <v-badge
                    v-if="$t('default') === 'th'"
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="show_admin"
                    :content="$t('balloon.admin')"
                    transition="slide-y-transition"
                  ></v-badge>
                  <v-badge
                    v-else
                    style="z-index:10000;bottom:-45px;left:-30px;"
                    :value="show_admin"
                    :content="$t('balloon.admin')"
                    transition="slide-y-transition"
                  ></v-badge>
                </v-btn> -->
              <!-- <v-icon
              v-if ="role_level === 'True'"
              class="aaaaa"
              >
                mdi-delete
              </v-icon> -->
              <br />
              <span style="font-size: 13px;">{{
                $store.getters.dataTypeusercurrent(
                  $store.state.account_active["type"],
                  $t("default")
                )
              }}</span>
            </div>
          </v-btn>
        </template>
        <v-card>
          <v-list nav>
            <v-list-item>
              <!-- <img :src="'https://image.flaticon.com/icons/svg/149/149071.svg'" alt="John" /> -->
              <!-- <v-list-item-avatar
                v-if="dataCitizenProfile.picture == null"
                :class="role_level === 'True' ? 'admin' : ''"
              >
                <img src="@/assets/user.png" alt="John" />
              </v-list-item-avatar>
              <v-list-item-avatar
                v-else
                :class="role_level === 'True' ? 'admin' : ''"
              >
                <img
                  :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture"
                  alt="John"
                />
              </v-list-item-avatar> -->
              <v-list-item-avatar
                v-if="dataCitizenProfile.picture == null"
                size="80"
              >
                <v-badge
                  avatar
                  bordered
                  overlap
                  color="#D6AD60"
                  v-if="
                    role_level === 'True' &&
                      dataAccountActive.type !== 'Citizen'
                  "
                >
                  <template v-slot:badge>
                    <v-avatar>
                      <v-icon>mdi-crown</v-icon>
                    </v-avatar>
                  </template>

                  <v-avatar size="45">
                    <img
                      :class="role_level === 'True' ? 'admin' : ''"
                      src="@/assets/user.png"
                      alt="John"
                    />
                  </v-avatar>
                </v-badge>
                <v-avatar size="45" v-else>
                  <img src="@/assets/user.png" alt="John" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-avatar size="80" v-else>
                <v-badge
                  avatar
                  bordered
                  overlap
                  color="#D6AD60"
                  v-if="role_level === 'True'"
                >
                  <template v-slot:badge>
                    <v-avatar>
                      <v-icon>mdi-crown</v-icon>
                    </v-avatar>
                  </template>

                  <v-avatar size="45">
                    <img
                      :class="role_level === 'True' ? 'admin' : ''"
                      :src="
                        'data:image/jpeg;base64,' + dataCitizenProfile.picture
                      "
                      alt="John"
                    />
                  </v-avatar>
                </v-badge>
                <v-avatar size="45" v-else>
                  <img
                    :src="
                      'data:image/jpeg;base64,' + dataCitizenProfile.picture
                    "
                    alt="John"
                  />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ fullname }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  $store.getters.dataTypeusercurrent(
                    $store.state.account_active["type"],
                    $t("default")
                  )
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-menu transition="slide-x-transition" left>
                  <template v-slot:activator="{ on }">
                    <v-btn fab text v-on="on" small>
                      <v-icon>mdi-palette</v-icon>
                    </v-btn>
                  </template>
                  <v-layout
                    :style="
                      $vuetify.theme.dark
                        ? 'background-color: black;'
                        : 'background-color: white;'
                    "
                    class="pa-1"
                  >
                    <v-flex>
                      <v-row no-gutters>
                        <v-subheader class="pa-0 pl-1">{{
                          $t("theme")
                        }}</v-subheader>
                      </v-row>
                      <v-row no-gutters>
                        <v-divider></v-divider>
                        <v-col
                          v-for="(item, n1) in color_1"
                          :key="n1"
                          class="pa-1"
                        >
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card>  -->
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data, item.data_code)"
                            class="elevation-4"
                            style="cursor: pointer"
                          >
                            <v-icon
                              :dark="color.darkTheme"
                              v-if="color.theme === item.name"
                              >done</v-icon
                            >
                          </v-avatar>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          v-for="(item, n2) in color_2"
                          :key="n2"
                          class="pa-1"
                        >
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data, item.data_code)"
                            class="elevation-4"
                            style="cursor: pointer"
                          >
                            <v-icon
                              :dark="color.darkTheme"
                              v-if="color.theme === item.name"
                              >done</v-icon
                            >
                          </v-avatar>
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          v-for="(item, n3) in color_3"
                          :key="n3"
                          class="pa-1"
                        >
                          <v-avatar
                            :color="item.name"
                            size="36"
                            @click="changecolor(item.data, item.data_code)"
                            class="elevation-4"
                            style="cursor: pointer"
                          >
                            <v-icon
                              :dark="color.darkTheme"
                              v-if="color.theme === item.name"
                              >done</v-icon
                            >
                          </v-avatar>
                          <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                        </v-col>
                      </v-row>
                      <!-- <v-col>
                    <v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i<5" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>4 && i<10" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>9 && i<15" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row><v-row>
                      <v-btn v-for="(item, i) in colors" :key="i" v-if="i>14 && i<20" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                    </v-row>
                      </v-col>-->
                      <v-row>
                        <v-subheader
                          class="pa-0 pl-4 pointer"
                          @click="openColorpicker = true"
                          >{{ $t("toolbar.morecolor") }}</v-subheader
                        >
                      </v-row>
                    </v-flex>
                  </v-layout>
                </v-menu>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <!-- เมนูบนขวา -->
          <v-list dense v-if="dataAccountActive.type === 'Citizen'">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon small>mdi-inbox-multiple</v-icon>
                  {{ $t("toolbar.storage") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pt-0">
              <v-list-item-icon>
                <v-chip>
                  <v-icon small>person</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle
                  class="text-center caption font-weight-light"
                >
                  <v-progress-linear
                    :color="percentagestoage_setcolor_"
                    rounded
                    :value="percentageStorage"
                    height="15"
                  >
                    <strong class="caption font-weight-light"
                      >{{ percentageStorage }} %</strong
                    >
                  </v-progress-linear>
                  {{ storage_usage }} {{ $t("adminrecheckimap.in") }} {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list dense v-else-if="dataAccountActive.type === 'Business'">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon small>mdi-inbox-multiple</v-icon>
                  {{ $t("toolbar.storage") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="pt-0">
              <v-list-item-icon>
                <v-chip>
                  <v-icon small>person</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle
                  class="text-center caption font-weight-light"
                >
                  <v-progress-linear
                    :color="percentagestoagecitizeninbiz_setcolor_"
                    rounded
                    :value="percentageStoragecitizeninbiz"
                    height="15"
                  >
                    <strong class="caption font-weight-light"
                      >{{ percentageStoragecitizeninbiz }} %</strong
                    >
                  </v-progress-linear>
                  {{ storage_usage }} {{ $t("adminrecheckimap.in") }} {{ storage_max_personal_biz }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-chip>
                  <v-icon small>business</v-icon>
                </v-chip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle
                  class="text-center caption font-weight-light"
                >
                  <v-progress-linear
                    :color="percentagestoagebusiness_setcolor_"
                    rounded
                    :value="percentageStoragebusiness"
                    height="15"
                  >
                    <strong class="caption font-weight-light"
                      >{{ percentageStoragebusiness }} %</strong
                    >
                  </v-progress-linear>
                  <!-- <br /> -->
                  {{ storage_usage_biz }} {{ $t("adminrecheckimap.in") }} {{ showDataStorage }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-list>
              <v-list-item
                v-for="(item, i) in listcctv()"
                :key="i"
                link
                @click="linktomenu(item, choosetypeuser)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-for="(item, i) in listitem()"
                :key="i"
                link
                @click="linktomenu(item, choosetypeuser)"
              >
                <v-list-item-content>
                  <v-list-item-title style="line-height: 28px;">{{
                    $t(item.text)
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list class="text-center">
              <v-list-item justify-center>
                <v-list-item-content>
                  <v-list-item-title>{{ version }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu transition="slide-y-transition" bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab text v-on="on" small>
            <flag :iso="defaultflag" :squared="false" />
          </v-btn>
        </template>
        <v-list nav dense>
          <v-list-item
            v-for="(item, i) in language"
            :key="i"
            @click="changelang(item.data)"
          >
            <v-list-item-action>
              <flag :iso="item.flag" :squared="false" />
            </v-list-item-action>
            <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
       <!-- เมนูประเมิน -->
       <v-menu bottom close-on-click offset-y >
        <template v-slot:activator="{ on }">
          <v-btn
            @mouseover="show_evaluate = true"
            @mouseleave="show_evaluate = false"
            fab
            text
            @click="fn_gotoeva()"
          >
          <v-icon :color="color.theme" style="margin-left:17px;">mdi-file-document-edit</v-icon>
          <!-- <v-badge content="2" color="error" class="bounce" id="alertnews" style="margin-left:-155px;">
          </v-badge> -->
          <v-avatar class="bounce2" style="margin-top:-45px;margin-left:-5px;" color="#FFC300" size="20px"   v-if="showicon == 'true' || showicon == true">
            <v-icon color="#f5f5f5" size="12px">
              mdi-alert
            </v-icon>
          </v-avatar>
          </v-btn>
          
            <v-badge
              v-if="$t('default') === 'th'"
              style="bottom:-45px;left:-20px;"
              :value="show_evaluate"
              :content="$t('balloon.evaluate')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="bottom:-45px;left:-20px;"
              :value="show_evaluate"
              :content="$t('balloon.evaluate')"
              transition="slide-y-transition"
            ></v-badge>
        </template>
      </v-menu>
      <!-- รวมเมนู ใหม่-->
      <v-menu bottom close-on-click offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            @mouseover="show_setting = true"
            @mouseleave="show_setting = false"
            v-on="on"
            fab
            text
          >
            <v-badge
              v-if="$t('default') === 'th'"
              style="bottom:-45px;left:-10px;"
              :value="show_setting"
              :content="$t('balloon.setting')"
              transition="slide-y-transition"
            ></v-badge>
            <v-badge
              v-else
              style="bottom:-45px;left:-10px;"
              :value="show_setting"
              :content="$t('balloon.setting')"
              transition="slide-y-transition"
            ></v-badge>
            <v-icon :color="color.theme">mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <!-- dark mode -->
          <v-list-item @click="darkMode" :style="heightListItem">
            <v-list-item-title :style="textThemeFontSmall">
              <v-icon size="20" :color="iconTheme" class="mt-n1">{{
                $vuetify.theme.dark
                  ? "mdi-white-balance-sunny"
                  : "mdi-moon-waxing-crescent mdi-rotate-135"
              }}</v-icon>
              <span class="pl-2">{{
                $vuetify.theme.dark
                  ? $t("balloon.lightmode")
                  : $t("balloon.darkmode")
              }}</span>
            </v-list-item-title>
          </v-list-item>
          <!-- คู่มือ -->
          <v-menu
            transition="slide-y-transition"
            left
            close-on-click
            offset-x
            content-class="roundUploadAndCreate"
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-book-open-page-variant</v-icon
                  >
                  <span class="pl-2">{{
                    $t("landingpagenavbar.oneboxmanual")
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, i) in listmanual" :key="i" @click="clickManual(item.link)" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon v-if="item.document_name_th.search('VDO') !== -1" class="pb-1" size="20" :color="iconTheme"
                    >mdi-arrow-right-drop-circle-outline</v-icon
                  >
                  <v-icon v-else class="pb-1" size="20" :color="iconTheme"
                    >mdi-book</v-icon
                  >
                  <span class="pl-2" v-if="$t('default') === 'th'">
                    {{ item.document_name_th }}
                  </span>
                  <span class="pl-2" v-else>
                    {{ item.document_name_eng }}
                  </span>
                </v-list-item-title>
              </v-list-item>
              <!-- <v-list-item @click="clickManual(2)" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-book</v-icon
                  >
                  <span class="pl-2">
                    {{ $t("manual.oneboxmanual") }}
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="clickManual(3)" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-book</v-icon
                  >
                  <span class="pl-2">
                    {{ $t("manual.etax") }}
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="clickManual(4)" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-arrow-right-drop-circle</v-icon
                  >
                  <span class="pl-2">
                    {{ $t("manual.useonebox") }}
                  </span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="clickManual(5)" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-arrow-right-drop-circle</v-icon
                  >
                  <span class="pl-2">
                    {{ $t("manual.storage") }}
                  </span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
          <!-- ใบรับรองแพทย์หมอพร้อม [new] -->
          <!-- <v-menu
            content-class="roundUploadAndCreate"
            transition="slide-y-transition"
            left
            close-on-click
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" :style="heightListItem">
                <v-list-item-title class="" :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-file-multiple</v-icon
                  >
                  <span class="pl-2">{{ $t("balloon.Medandetax") }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list dense>
              <v-list-item
                :style="heightListItem"
                @click.stop="opendialogmed = true"
                v-if="dataAccountActive.type !== 'Business'"
              >
                <v-list-item-title class="" :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-file-upload</v-icon
                  >
                  <span class="pl-2">{{ $t("med.medicalcertificate") }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :style="heightListItem"
                @click.stop="opendialogshopdee = true"
                v-if="dataAccountActive.type === 'Business'"
              >
                <v-list-item-title class="" :style="textThemeFontSmall">
                  <v-icon class="pb-1" size="20" :color="iconTheme"
                    >mdi-file-upload</v-icon
                  >
                  <span class="pl-2">ช้อปดีมีคืน</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <!-- <v-list-item
              v-if="
                role_level === 'True' || dataAccountActive.type === 'Citizen'
              "
              @click="fn_checkAccountActivetypebiz(), (show_purchase = false)"
              :style="heightListItem"
            >
              <v-list-item-title :style="textThemeFontSmall">
                <v-icon class="pb-1" size="20" :color="iconTheme"
                  >mdi-shopping</v-icon
                >
                <span class="pl-2">{{ $t("balloon.purchase") }}</span>
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item
              v-if="
                role_level === 'True' && dataAccountActive.type !== 'Citizen'
              "
              @click="fn_admin()"
              :style="heightListItem"
            >
              <v-list-item-title :style="textThemeFontSmall">
                <v-icon class="pb-1" size="20" :color="iconTheme"
                  >domain</v-icon
                >
                <span class="pl-2">{{
                  $t("balloon.business_management")
                }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
            v-if="
              dataAccountActive.type !== 'Citizen' && this.feature_mapdrive === true
            "
            @click="tolink_dialog_mapdrive()"
            :style="heightListItem"
          >
            <v-list-item-title :style="textThemeFontSmall">
              <v-icon class="pb-1" size="20" :color="iconTheme"
                >mdi-server-network</v-icon
              >
              <span class="pl-2">{{
                $t("toolbar.mapdrive")
              }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!-- หน้าจอมือถือด้านบน -->
    <v-app-bar
      v-else
      :scroll-threshold="1"
      app
      elevation="0"
      :color="$vuetify.theme.dark ? '#333333' : 'background'"
      style="border-bottom: 1px solid #F3F5F7; border-top: 1px solid #F3F5F7;"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        :color="color.theme"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-layout wrap>
        <v-layout row class="text-right mr-n5" v-if="quicksearch === true">
          <v-flex
            xs12
            v-if="
            $router.app['_route']['name'] === 'myfiles' ||
              $router.app['_route']['name'] === 'directory' ||
              $router.app['_route']['name'] === 'directorysharefile' || 
              $router.app['_route']['name'] === 'sharedwithme'  
              
          "
          >
          <!--  ||
              $router.app['_route']['name'] === 'sharedwithme' -->
            <v-menu
              v-model="advancesearch"
              offset-y
              transition="slide-y-transition"
              :close-on-click="false"
              :close-on-content-click="false"
              max-width="90%"
              left
            >
              <template v-slot:activator="{ on }">
                <v-layout row wrap class="text-right">
                  <v-flex xs12 class="pt-1">
                    <v-list-item-avatar v-on="on">
                      <v-icon class="pt-2" size="20" :color="iconTheme"
                        >search</v-icon
                      >
                    </v-list-item-avatar>
                  </v-flex>
                </v-layout>
              </template>
              <v-card outlined class="pa-4">
                <v-card-text class="pb-0">
                  <v-layout row wrap justify-center fill-height>
                    <v-text-field
                      v-model="search"
                      outlined
                      :label="$t('quicksearch')"
                      :color="color.theme"
                      dense
                      append-icon="search"
                      :error-messages="errorsearch"
                      :disabled="selectSearch == 'aspect_search'"
                      @keyup.enter="calladvancesearchdata"
                    ></v-text-field>
                  </v-layout>
                  <v-layout row wrap class="pb-4">
                    <v-divider></v-divider>
                  </v-layout>
                  <v-layout row wrap>
                    <v-icon :color="color.theme" class="mr-2"
                      >mdi-tune-vertical</v-icon
                    >
                    <h3 :style="'color:' + color.theme">
                      {{ $t("toolbar.searching.name") }}
                    </h3>
                  </v-layout>
                </v-card-text>
                <!-- <v-card-title>
                <v-subheader class="ma-0 pa-0" :color="color.theme">
                  <v-radio-group v-model="selectSearch">
                    <v-radio value="advance_search"></v-radio>
                  </v-radio-group>
                  {{ $t("toolbar.searching.name") }}
                </v-subheader>
                <v-spacer />
                <v-btn
                  icon
                  :color="color.theme"
                  @click="
                    (advancesearch = false),
                    $emit('loadfile'),
                    (selectSearch = 'advance_search')
                  "
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title> -->

                <!-- <v-card-text>
                <v-radio-group v-model="typeSearch" class="ma-0 pa-0">
                  <v-radio :label="$t('toolbar.searching.keywordsearch')" value="keywordsearch"></v-radio>
                  <v-radio
                    :label="$t('toolbar.searching.fulltextsearch')"
                    value="fulltextsearch"
                    :disabled="!ismydrive"
                  ></v-radio>
                </v-radio-group>
                <div v-if="typeSearch == 'fulltextsearch'">
                  <v-divider></v-divider>
                  <v-subheader class="ma-0 pa-0">{{$t('toolbar.searching.optional_fulltextearch')}}</v-subheader>
                  <v-radio-group v-model="type_Fulltextsearch" class="ma-0 pa-0">
                    <v-radio
                      :label="$t('toolbar.searching.optional_name_fulltextearch')"
                      value="file_name"
                    ></v-radio>
                    <v-radio
                      :label="$t('toolbar.searching.optional_content_fulltextearch')"
                      value="content_text"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-card-text>-->
                <v-card-text class="py-0 checkbox">
                  <v-layout row wrap justify-center fill-height class="pb-4">
                    <v-flex>
                    <!-- Checkbox Option Search -->
                    <!-- <span class="pa-0" style="font-size: 12px">{{
                      $t("toolbar.searching.searchby")
                    }}</span> -->
                    <v-row dense no-gutters>
                      <!-- ค้นหาภายใน -->
                      <v-col cols="8">
                        <v-checkbox
                          hide-details
                          v-model="specific_folder"
                          :label="$t('toolbar.searching.fileinfolder')"
                          :color="color.theme"
                          :disabled="selectSearch == 'aspect_search'"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row class="mb-5" dense no-gutters nowrap>
                      <!-- โฟลเดอร์ -->
                      <v-col cols="4">
                        <v-checkbox
                          hide-details
                          value="foldername"
                          v-model="optionSearch"
                          :label="$t('toolbar.searching.optional_folder_name')"
                          :color="color.theme"
                          @click="fn_selectFilterSearch(1)"
                        ></v-checkbox>
                      </v-col>
                      <!-- ชื่อไฟล์ -->
                      <v-col cols="3">
                        <v-checkbox
                          hide-details
                          value="filename"
                          v-model="optionSearch"
                          :label="
                            $t('toolbar.searching.optional_name_fulltextearch')
                          "
                          :color="color.theme"
                          @click="fn_selectFilterSearch(1)"
                        ></v-checkbox>
                      </v-col>
                      <!-- แฮชแท็ก -->
                      <v-col cols="5">
                        <v-layout>
                          <v-checkbox
                            hide-details
                            value="hashtag"
                            v-model="optionSearch"
                            :label="
                              $t(
                                'toolbar.searching.optional_hashtag_fulltextearch'
                              )
                            "
                            :color="color.theme"
                            @click="fn_selectFilterSearch(1)"
                          ></v-checkbox>
                          <v-btn
                            icon
                            color="#B7B7B7"
                            class="ma-0"
                            @click="fn_openhashtag"
                          >
                            <v-icon class="mt-7">mdi-help-circle</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row dense no-gutters>
                      <!-- เนื้อหาไฟล์ -->
                      <v-checkbox
                        hide-details
                        value="file_content"
                        v-model="optionSearch"
                        :label="
                          $t(
                            'toolbar.searching.optional_content_fulltextearch'
                          )
                        "
                        :color="color.theme"
                        @click="fn_selectFilterSearch(2)"
                      ></v-checkbox>
                    </v-row>
                    <!-- <v-radio-group
                      v-model="type_Fulltextsearch"
                      class="ma-0 pa-0"
                      row
                      :disabled="selectSearch == 'aspect_search'"
                    >
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_folder_name')"
                        value="folder"
                      ></v-radio>
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_name_fulltextearch')"
                        value="file_name"
                      ></v-radio>
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_content_fulltextearch')"
                        value="content_text"
                      ></v-radio>
                      <v-radio
                        :color="color.theme"
                        :label="$t('toolbar.searching.optional_hashtag_fulltextearch')"
                        value="hashtag"
                      ></v-radio>
                    </v-radio-group> -->
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout row wrap justify-center fill-height>
                  <v-flex lg10 xs10>
                    <v-text-field
                      v-model="search"
                      outlined
                      :label="$t('toolbar.searching.detail')"
                      :color="color.theme"
                      dense
                      prepend-icon="image_search"
                      :disabled="selectSearch == 'aspect_search'"
                      @keyup.enter="calladvancesearchdata"
                    ></v-text-field>
                  </v-flex>
                </v-layout> -->
                  <v-layout row wrap justify-center fill-height>
                    <v-flex xs12 class="mb-4">
                      <div>{{ $t("toolbar.searching.startenddate") }}</div>
                    </v-flex>
                    <v-flex sm5 xs12>
                      <v-dialog
                        persistent
                        v-model="menufromdate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fromdate"
                            :color="color.theme"
                            :label="$t('toolbar.searching.searchfromdate')"
                            :disabled="
                              selectSearch == 'aspect_search' ||
                                type_Fulltextsearch == 'hashtag'
                            "
                            append-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            clearable
                            @keyup.enter="calladvancesearchdata"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fromdate"
                          @input="menufromdate = false"
                          :color="color.theme"
                          @keyup.enter="calladvancesearchdata"
                          :max="date_now"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            dark
                            color="error"
                            @click="menufromdate = false"
                          >
                            ปิด
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex v-if="imageHeight == 400" sm1 d-flex justify-center
                      >-</v-flex
                    >
                    <v-flex sm5 xs12>
                      <v-dialog
                        persistent
                        v-model="menutodate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="todate"
                            :color="color.theme"
                            :disabled="
                              selectSearch == 'aspect_search' ||
                                type_Fulltextsearch == 'hashtag'
                            "
                            :label="$t('toolbar.searching.searchtodate')"
                            append-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            clearable
                            @keyup.enter="calladvancesearchdata"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="todate"
                          @input="menutodate = false"
                          :color="color.theme"
                          @keyup.enter="calladvancesearchdata"
                          :max="date_now"
                        >
                          <v-spacer></v-spacer>
                          <v-btn dark color="error" @click="menutodate = false">
                            ปิด
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <!-- <v-divider></v-divider> -->
                <!-- Aspect Search -->
                <!-- <v-card-title v-if="aspect_data[0].aspect == 'True'">
                <v-subheader class="ma-0 pa-0">
                  <v-radio-group v-model="selectSearch">
                    <v-radio value="aspect_search"></v-radio>
                  </v-radio-group>
                  {{ $t("toolbar.searching.aspect_name") }}
                </v-subheader>
                <v-spacer />
              </v-card-title> -->
                <!-- <v-card-text v-if="aspect_data[0].aspect == 'True'" >
                <div v-for="(item, index) in aspect_data[0].aspect_key" :key="index" >
                  <v-layout row wrap justify-center fill-height v-if="item.key_type == 'varchar'">
                    <v-flex lg10 class="pa-1">
                      <v-text-field
                        outlined
                        :label="item.key_name"
                        :color="color.theme"
                        dense
                        @input="(aspect_search) => updateAspectData(index, aspect_search)"
                        prepend-icon="image_search"
                        :disabled="selectSearch == 'advance_search'"
                        @keyup.enter="aspectbutton"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-center fill-height v-if="item.key_type == 'dropdown'"  >
                    <v-flex lg10>
                      <v-select
                        :items="item.data"
                        :label="item.key_name"
                        :value="item.data"
                        @input="(aspect_value) => updateAspectData(index, aspect_value)"
                        outlined
                        dense
                        prepend-icon="image_search"
                        :disabled="selectSearch == 'advance_search'"
                        @keyup.enter="aspectbutton"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                  ถ้า 2 ตัวบน key_type ไม่เปลี่ยน dateให้ใช้ v-else ไปเลย
                  <v-layout row wrap justify-center fill-height v-if="item.key_type == 'date'"  >
                    <v-flex lg5 class="pa-1">
                      <v-dialog
                        v-model="aspect_datefrom[item.dialog_date_from]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="aspect_valuedatefrom[item.dialog_date_from]"
                            :value="item.date_from"
                            :color="color.theme"
                            :label="item.key_name"
                            :disabled="selectSearch == 'advance_search'"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            clearable
                            @keyup.enter="aspectbutton"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          @input="aspect_datefrom[item.dialog_date_from] = false"
                          v-model="aspect_valuedatefrom[item.dialog_date_from]"
                          :color="color.theme"
                          @keyup.enter="aspectbutton"
                        >
                        </v-date-picker>
                      </v-dialog>
                    </v-flex>
                    <v-flex lg5 class="pa-1">
                      <v-dialog
                        v-model="aspect_dateto[item.dialog_date_to]"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">

                          <v-text-field
                            v-model="aspect_valuedateto[item.dialog_date_to]"
                            :color="color.theme"
                            :disabled="selectSearch == 'advance_search'"
                            :label="$t('toolbar.searching.searchtodate')"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            clearable
                           @keyup.enter="aspectbutton"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="aspect_valuedateto[item.dialog_date_to]"
                          @input="aspect_dateto[item.dialog_date_to] = false"
                          :color="color.theme"
                          @keyup.enter="aspectbutton"
                        ></v-date-picker>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text> -->

                <!-- ปุ่ม -->
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <!-- Advance Search -->
                  <v-btn
                    v-if="selectSearch == 'advance_search'"
                    @click="calladvancesearchdata"
                    :color="color.theme"
                    :dark="color.darkTheme"
                    :loading="processdelete"
                    block
                    >{{ $t("toolbar.searching.button") }}</v-btn
                  >
                  <!-- Aspect serach button -->
                  <v-btn
                    v-if="selectSearch == 'aspect_search'"
                    @click="aspectbutton"
                    :color="color.theme"
                    :dark="color.darkTheme"
                    >{{ $t("toolbar.searching.button") }}</v-btn
                  >
                </v-card-actions>
                <v-progress-linear
                  :active="processdelete"
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </v-card>
            </v-menu>
          </v-flex>
          <v-flex cols="12" sm="8" lg="8" v-else>
            <v-text-field
              v-if="imageHeight >= 500"
              v-model="search"
              :label="$t('quicksearch')"
              append-icon="search"
              dense
              single-line
              solo-inverted
              flat
              clearable
              hide-details
              @input="$emit('inputdata', search)"
              :color="color.theme"
              @click:clear="$emit('loadfile')"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout v-else>
          <v-spacer></v-spacer>
        </v-layout>

        <!-- smart upload search จอโทรศัพท์ -->
        <v-menu
            v-model="smartuploadsearch"
            offset-y
            transition="slide-y-transition"
            :close-on-click="true"
            :close-on-content-click="false"
            max-width="90%"
            right
          >
          <!-- <template v-slot:activator="{ on }">
            <v-flex xs1>
              <v-btn
                v-on="on"
                icon
                :color="color.theme"
                class="pl-2 pr-4 pt-5"
                :loading="processdelete"
                @mouseover="show_smartuploadsearch = true"
                @mouseleave="show_smartuploadsearch = false"
                @click="fn_gettamplate_smartupload()"
              >
                <v-icon>mdi-layers-search</v-icon>
              </v-btn>
            </v-flex>
          </template> -->
          <template v-slot:activator="{ on }">
                <!-- <v-layout xs2 class="text-right"> -->
                  <v-flex xs1 class="pt-1 text-center"
                   v-if=" $router.app['_route']['name'] === 'directory' && dataAccountActive.type === 'Business' && feature_inputmore === true">
                    <v-list-item-avatar v-on="on">
                      <v-icon class="pt-2" size="20" :color="iconTheme"
                        >mdi-layers-search</v-icon
                      >
                    </v-list-item-avatar>
                  </v-flex>
                <!-- </v-layout> -->
              </template>
          <v-card width="550" outlined>
              <v-card-title >
                <v-subheader class="ma-0 pa-0 text-h6 font-weight-black " style="font-size:25px">
                  <v-radio-group v-model="selectSearch_smartupload">
                    <v-radio value="smartupload_search"></v-radio>
                  </v-radio-group>
                  {{ $t("toolbar.searching.smartuploadsearch") }}
                </v-subheader>
                <v-spacer />
              </v-card-title>
              <v-card-text style="overflow-y: hidden">
                <v-layout row wrap justify-center fill-height class="pb-4">
                  <v-flex lg10 md10 sm10 xs10>
                    <!-- Checkbox Option Search -->
                    <span class="pa-0 text-h6 font-weight-black" style="font-size:20px">{{
                      $t("toolbar.searching.searchby")
                    }}</span>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex lg8 md8 sm8 xs11>
                    <v-select
                      outlined
                      dense
                      class="pa-1 pt-0 pb-0"
                      v-model="item_template"
                      :items="itemstemplate"
                      item-value="template_id"
                      item-text="template_name"
                      label="tamplate"
                      prepend-icon="mdi-layers"
                      :color="color.theme"
                      :item-color="color.theme"
                      @change="fn_get_detail_template(item_template)"
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-card-text style="max-height: 380px; overflow-y: auto">
                  <v-list 
                    dense
                    v-for="(item, i) in datatemplate"
                    :key="i"
                    class="mb-3"
                  >
                    <v-list-item class="mt-n1 mb-n6">
                      <v-list-item-content v-if="item.aspect_type === 'DROPDOWN'">
                        <v-select
                          v-model="item.value"
                          :items="item.aspect_fix_value"
                          outlined
                          dense
                          item-color="color.theme"
                          :color="color.theme"
                          :label="item.aspect_label"
                          prepend-icon="mdi-format-list-bulleted-type"
                          :item-value="item.aspect_fix_value[0]"
                        ></v-select>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="item.aspect_type === 'DATE'">
                        <v-dialog
                          persistent
                          v-model="item.menufromdate"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          width="290px"
                          clearable
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="item.fromdate"
                              :color="color.theme"
                              :label="item.aspect_label"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              dense
                              outlined
                              clearable
                              @keyup.enter="calladvancesearchdata"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.fromdate"
                            @input="item.menufromdate = false"
                            :color="color.theme"
                            @keyup.enter="calladvancesearchdata"
                            :max="todate === '' || todate === null ? date_now : todate"
                          >
                            <v-spacer></v-spacer>
                            <v-btn dark color="error" @click="item.menufromdate = false">
                              ปิด
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-list-item-content>
                      <v-list-item-content v-else-if="item.aspect_type === 'TEXT'">
                        <v-text-field
                          v-model="item.value_search"
                          outlined
                          dense
                          :label="item.aspect_label"
                          prepend-icon="mdi-file-find-outline"
                          clearable
                          :color="color.theme"
                        ></v-text-field>
          
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>

                <!-- <div class="form-group" v-for="(list_detail_tem_text, i) in list_detail_tem_text" :key="i">
                    <v-layout lg12>
                      <v-flex lg10>
                        <v-text-field
                          outlined
                          dense
                          :label="list_detail_tem_text.aspect_label"
                          prepend-icon="mdi-file-find-outline"
                          clearable
                          :color="color.theme"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                </div>
                <div class="form-group" v-for="(list_detail_tem_dropdown, i) in list_detail_tem_dropdown" :key="i">
                    <v-layout lg12>
                      <v-flex lg10>
                        <v-select
                          :items="list_detail_tem_dropdown.aspect_fix_value"
                          :label="list_detail_tem_dropdown.aspect_label"
                          :color="color.theme"
                          outlined
                          dense
                          clearable
                          prepend-icon="mdi-format-list-bulleted-type"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                </div>
                <div class="form-group" v-for="(list_detail_tem_date, i) in list_detail_tem_date" :key="i">
                    <v-layout lg12>
                      <v-flex lg10>
                    <v-dialog
                      persistent
                      v-model="menufromdate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      width="290px"
                      clearable
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromdate"
                          :color="color.theme"
                          :label="list_detail_tem_date.aspect_label"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          clearable
                          @keyup.enter="calladvancesearchdata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate"
                        @input="menufromdate = false"
                        :color="color.theme"
                        @keyup.enter="calladvancesearchdata"
                        :max="todate === '' || todate === null ? date_now : todate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn dark color="error" @click="menufromdate = false">
                          ปิด
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>
                    </v-layout>
                </div> -->
              </v-card-text>
              <!-- ปุ่ม -->
              <v-card-actions class="mr-5">
                <v-spacer></v-spacer>
                  <!-- smart upload search button -->
                  <v-btn
                    @click="callsmartuploadsearch"
                    :color="color.theme"
                    :dark="color.darkTheme"
                    >{{ $t("toolbar.searching.button") }}</v-btn
                  >
              </v-card-actions>
              <v-progress-linear
                :active="processdelete"
                indeterminate
                color="green"
              ></v-progress-linear>
          </v-card>
        </v-menu>

        <v-menu
          bottom
          transition="slide-y-transition"
          :close-on-click="true"
          :close-on-content-click="true"
          offset-y
          content-class="roundUploadAndCreate"
        >
          <template v-slot:activator="{ on }">
            <v-list-item-avatar
              v-on="on"
              v-if="
                $router.app['_route']['name'] === 'myfiles' ||
                  $router.app['_route']['name'] === 'directory'
              "
              class="pt-1"
            >
              <v-icon size="20" :color="iconTheme">more_vert</v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar v-on="on" v-else>
              <v-icon size="20" :color="iconTheme">more_vert</v-icon>
            </v-list-item-avatar>
          </template>
          <v-list dense>
            <!-- ใบรับรองแพทย์หมอพร้อม [new] -->
            <!-- <v-menu
              content-class="roundUploadAndCreate"
              transition="slide-y-transition"
              left
              close-on-click
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on" :style="heightListItem">
                  <v-list-item-title class="" :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-file-multiple</v-icon
                    >
                    <span class="pl-2">{{ $t("balloon.Medandetax") }}</span>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-list dense>
                <v-list-item
                  :style="heightListItem"
                  @click.stop="opendialogmed = true"
                  v-if="dataAccountActive.type !== 'Business'"
                >
                  <v-list-item-title class="" :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-file-upload</v-icon
                    >
                    <span class="pl-2">{{ $t("med.medicalcertificate") }}</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :style="heightListItem"
                  @click.stop="gotoinboxoutbox"
                  v-if="dataAccountActive.type === 'Business'"
                >
                  <v-list-item-title class="" :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-file-upload</v-icon
                    >
                    <span class="pl-2">ช้อปดีมีคืน</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
            <!-- จัด layout ให้ดันเมนูไปทางขวา -->
            <!-- <v-slide-item v-if="$router.app['_route']['name'] !== 'myfiles'">
            <v-layout
              v-if="$router.app['_route']['name'] !== 'directory'"
              class="ml-12 mr-12"
            >
            </v-layout>
            <v-layout
              v-else
              class="ml-12"
            >
            </v-layout>
          </v-slide-item>
          <v-slide-item v-else>
            <v-layout
              class="ml-12"
            >
            </v-layout>
          </v-slide-item> -->
            <!-- Mutimenu -->
            <!-- <div v-if="show_menu === false" class="ml-5 mt-3 mr-5">
            <v-btn
              :color="color.theme"
              rounded
              class="pa-3 white--text"
              @click="show_menu = true"
            >
              <v-icon>mdi-widgets-outline</v-icon>
            </v-btn>
          </div> -->
            <!-- <div v-else class="ml-5 ma-2 mr-5"> -->
            <!-- ใบรับรองแพทย์หมอพร้อม [new] -->
            <!-- <v-menu transition="slide-y-transition" bottom close-on-click offset-y>
              <template v-slot:activator="{ on }">
                <v-btn 
                  @mouseover="show_mohpromt = true"
                  @mouseleave="show_mohpromt = false"
                  class="ma-1 pa-0" 
                  fab 
                  v-on="on" 
                  small
                  :style="darkModeMutiIcon"
                  outlined
                >
                  <v-icon :color="color.theme">mdi-file-multiple</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.stop="opendialogshopdee = true">
                  <v-list-item-avatar>
                    <v-icon size="30" :color="darkModeIcon">mdi-file-upload</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-title :style="darkModeText">ช้อปดีมีคืน</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
            <!-- dark mode -->
            <!-- <v-btn
              fab
              small
              :style="darkModeMutiIcon"
              outlined
              class="ma-1 pa-0"
              @click="darkMode"
              @mouseover="show_darkmode = true"
              @mouseleave="show_darkmode = false"
            >
              <v-icon :color="color.theme">{{
                $vuetify.theme.dark
                  ? "mdi-white-balance-sunny"
                  : "mdi-moon-waxing-crescent"
              }}</v-icon>
            </v-btn> -->
            <!-- คู่มือ -->
            <!-- <v-menu bottom close-on-click offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  :style="darkModeMutiIcon"
                  class="ma-1 pa-0"
                  @mouseover="show_manual = true"
                  @mouseleave="show_manual = false"
                  v-on="on"
                  fab
                  small
                  outlined
                >
                  <v-icon :color="color.theme">mdi-book-outline</v-icon>
                </v-btn>
              </template>
              <v-list class="text-center" rounded>
                <v-list-item @click="clickManual(1)">
                  <v-list-item-title style="line-height: 24px">
                    {{ $t("manual.businessbox") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickManual(2)">
                  <v-list-item-title style="line-height: 24px">
                    {{ $t("manual.oneboxmanual") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickManual(3)">
                  <v-list-item-title style="line-height: 24px">
                    {{ $t("manual.etax") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickManual(4)">
                  <v-list-item-title>
                    {{ $t("manual.useonebox") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickManual(5)">
                  <v-list-item-title style="line-height: 24px">
                    {{ $t("manual.storage") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
            <!-- อัปโหลด -->
            <!-- <v-menu
              transition="slide-y-transition"
              bottom
              close-on-click
              offset-y
              v-if="checkroutername !== 'document-management'"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  class="ma-1 pa-0"
                  @mouseover="show_upload = true"
                  @mouseleave="show_upload = false"
                  fab
                  small
                  outlined
                  :style="
                    permission_denied_account === false ? darkModeMutiIcon : ''
                  "
                  v-on="on"
                  :disabled="permission_denied_account"
                >
                  <v-icon :color="color.theme">mdi-cloud-upload-outline</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.stop="openfile_">
                  <v-list-item-avatar>
                    <v-icon size="30" :color="darkModeIcon"
                      >mdi-file-upload</v-icon
                    >
                  </v-list-item-avatar>
                  <v-list-item-title :style="darkModeText">{{
                    $t("uploadfile.name")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click.stop="opencreatefolder()">
                  <v-list-item-avatar>
                    <v-icon size="30" :color="darkModeIcon"
                      >mdi-folder-upload</v-icon
                    >
                  </v-list-item-avatar>
                  <v-list-item-title :style="darkModeText">{{
                    $t("mainRClick.uploadfolder")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click.stop="openfolder_">
                  <v-list-item-avatar>
                    <v-icon size="30" :color="darkModeIcon"
                      >mdi-folder-plus</v-icon
                    >
                  </v-list-item-avatar>
                  <v-list-item-title :style="darkModeText">{{
                    $t("uploadfolder.name")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
            <!-- Purchase -->
            <!-- <v-btn
              @mouseover="show_purchase = true"
              @mouseleave="show_purchase = false"
              class="ma-1 pa-0"
              fab
              small
              :style="darkModeMutiIcon"
              outlined
              @click="fn_checkAccountActivetypebiz(), (show_purchase = false)"
            >
              <v-icon :color="color.theme">mdi-cart-outline</v-icon>
            </v-btn> -->
            <!-- Close Menu -->
            <!-- <v-btn
              fab
              small
              color="error"
              outlined
              class="ma-1 pa-0"
              @click="show_menu = false"
            >
              <v-icon>clear</v-icon>
            </v-btn>
          </div> -->
            <!-- dark mode -->
            <v-list-item @click="darkMode" :style="heightListItem">
              <v-list-item-title :style="textThemeFontSmall">
                <v-icon size="20" :color="iconTheme" class="mt-n1">{{
                  $vuetify.theme.dark
                    ? "mdi-white-balance-sunny"
                    : "mdi-moon-waxing-crescent mdi-rotate-135"
                }}</v-icon>
                <span class="pl-2">{{
                  $vuetify.theme.dark
                    ? $t("balloon.lightmode")
                    : $t("balloon.darkmode")
                }}</span>
              </v-list-item-title>
            </v-list-item>
            <!-- คู่มือ -->
            <v-menu
              transition="slide-y-transition"
              left
              close-on-click
              offset-x
              content-class="roundUploadAndCreate"
            >
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on" :style="heightListItem">
                  <v-list-item-title :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-book-open-page-variant</v-icon
                    >
                    <span class="pl-2">{{
                      $t("landingpagenavbar.oneboxmanual")
                    }}</span>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, i) in listmanual" :key="i" @click="clickManual(item.link)" :style="heightListItem">
                  <v-list-item-title :style="textThemeFontSmall">
                    <v-icon v-if="item.document_name_th.search('VDO') !== -1" class="pb-1" size="20" :color="iconTheme"
                      >mdi-arrow-right-drop-circle-outline</v-icon
                    >
                    <v-icon v-else class="pb-1" size="20" :color="iconTheme"
                      >mdi-book</v-icon
                    >
                    <span class="pl-2" v-if="$t('default') === 'th'">
                      {{ item.document_name_th }}
                    </span>
                    <span class="pl-2" v-else>
                      {{ item.document_name_eng }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
                <!-- <v-list-item @click="clickManual(2)" :style="heightListItem">
                  <v-list-item-title :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-book</v-icon
                    >
                    <span class="pl-2">
                      {{ $t("manual.oneboxmanual") }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickManual(3)" :style="heightListItem">
                  <v-list-item-title :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-book</v-icon
                    >
                    <span class="pl-2">
                      {{ $t("manual.etax") }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickManual(4)" :style="heightListItem">
                  <v-list-item-title :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-arrow-right-drop-circle</v-icon
                    >
                    <span class="pl-2">
                      {{ $t("manual.useonebox") }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="clickManual(5)" :style="heightListItem">
                  <v-list-item-title :style="textThemeFontSmall">
                    <v-icon class="pb-1" size="20" :color="iconTheme"
                      >mdi-arrow-right-drop-circle</v-icon
                    >
                    <span class="pl-2">
                      {{ $t("manual.storage") }}
                    </span>
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
            <!-- อัปโหลด -->
            <!-- <v-menu
            transition="slide-y-transition"
            left
            close-on-click
            offset-x
            v-if="checkroutername !== 'document-management'"
            content-class="roundUploadAndCreate"
          >
            <template v-slot:activator="{ on }">
              <v-list-item
                v-on="on"
                :disabled="permission_denied_account"
                :style="heightListItem"
              >
                <v-list-item-title :style="permission_denied_account ? '#9E9E9E' : textThemeFontSmall">
                  <v-icon
                    :disabled="permission_denied_account"
                    :color="iconTheme"
                    class="mb-1"
                    size="20"
                    >mdi-cloud-upload-outline</v-icon
                  >
                  <span class="pl-2">{{
                    $t("balloon.upload_create")
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list>
              <v-list-item @click.stop="openfile_" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon size="20" :color="iconTheme" class="mb-1"
                    >mdi-file-upload</v-icon
                  >
                  <span class="pl-2">{{
                    $t("uploadfile.name")
                  }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="opencreatefolder()" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon class="mb-1" size="20" :color="iconTheme"
                    >mdi-folder-upload</v-icon
                  >
                  <span class="pl-2">{{
                    $t("mainRClick.uploadfolder")
                  }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="openfolder_" :style="heightListItem">
                <v-list-item-title :style="textThemeFontSmall">
                  <v-icon class="mb-1" size="20" :color="iconTheme"
                    >mdi-folder-plus</v-icon
                  >
                  <span class="pl-2">{{
                    $t("uploadfolder.name")
                  }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
            <!-- <v-list-item
              v-if="
                role_level === 'True' || dataAccountActive.type === 'Citizen'
              "
              @click="fn_checkAccountActivetypebiz(), (show_purchase = false)"
              :style="heightListItem"
            >
              <v-list-item-title :style="textThemeFontSmall">
                <v-icon class="pb-1" size="20" :color="iconTheme"
                  >mdi-shopping</v-icon
                >
                <span class="pl-2">{{ $t("balloon.purchase") }}</span>
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item
              v-if="
                role_level === 'True' && dataAccountActive.type !== 'Citizen'
              "
              @click="fn_admin()"
              :style="heightListItem"
            >
              <v-list-item-title :style="textThemeFontSmall">
                <v-icon class="pb-1" size="20" :color="iconTheme"
                  >domain</v-icon
                >
                <span class="pl-2">{{
                  $t("balloon.business_management")
                }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-divider class="mx-n1 my-3" vertical inset></v-divider>
        <v-menu transition="slide-y-transition" bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-list-item-avatar
              v-on="on"
              v-if="dataCitizenProfile.picture == null"
              size="53"
            >
              <v-badge
                avatar
                bordered
                overlap
                color="#D6AD60"
                v-if="
                  role_level === 'True' && dataAccountActive.type !== 'Citizen'
                "
              >
                <template v-slot:badge>
                  <v-avatar>
                    <v-icon>mdi-crown</v-icon>
                  </v-avatar>
                </template>

                <v-avatar size="27">
                  <img
                    :class="role_level === 'True' ? 'admin' : ''"
                    src="@/assets/user.png"
                    alt="John"
                  />
                </v-avatar>
              </v-badge>
              <v-avatar size="27" v-else>
                <img src="@/assets/user.png" alt="John" />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-avatar size="53" v-on="on" v-else>
              <v-badge
                avatar
                bordered
                overlap
                color="#D6AD60"
                v-if="role_level === 'True'"
              >
                <template v-slot:badge>
                  <v-avatar>
                    <v-icon>mdi-crown</v-icon>
                  </v-avatar>
                </template>

                <v-avatar size="27">
                  <img
                    :class="role_level === 'True' ? 'admin' : ''"
                    :src="
                      'data:image/jpeg;base64,' + dataCitizenProfile.picture
                    "
                    alt="John"
                  />
                </v-avatar>
              </v-badge>
              <v-avatar size="27" v-else>
                <img
                  :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture"
                  alt="John"
                />
              </v-avatar>
            </v-list-item-avatar>
            <!-- <v-list-item-avatar
              v-on="on"
              v-if="
                $router.app['_route']['name'] === 'myfiles' ||
                  $router.app['_route']['name'] === 'directory'
              "
            >
              <v-icon size="20" :color="iconTheme">mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar v-on="on" v-else>
              <v-icon size="20" :color="iconTheme">mdi-account-circle</v-icon>
            </v-list-item-avatar> -->
          </template>
          <v-card>
            <v-list nav>
              <v-list-item-group mandatory :color="color.theme">
                <v-list-item>
                  <!-- <img :src="'https://image.flaticon.com/icons/svg/149/149071.svg'" alt="John" /> -->
                  <!-- <v-list-item-avatar
                    v-if="dataCitizenProfile.picture == null"
                    :class="role_level === 'True' ? 'admin' : ''"
                  >
                    <img src="@/assets/user.png" alt="John" />
                  </v-list-item-avatar>
                  <v-list-item-avatar
                    :class="role_level === 'True' ? 'admin' : ''"
                    v-else
                  >
                    <img
                      :src="
                        'data:image/jpeg;base64,' + dataCitizenProfile.picture
                      "
                      alt="John"
                    />
                  </v-list-item-avatar> -->
                  <v-list-item-avatar
                    v-if="dataCitizenProfile.picture == null"
                    size="80"
                  >
                    <v-badge
                      avatar
                      bordered
                      overlap
                      color="#D6AD60"
                      v-if="
                        role_level === 'True' &&
                          dataAccountActive.type !== 'Citizen'
                      "
                    >
                      <template v-slot:badge>
                        <v-avatar>
                          <v-icon>mdi-crown</v-icon>
                        </v-avatar>
                      </template>

                      <v-avatar size="50">
                        <img
                          :class="role_level === 'True' ? 'admin' : ''"
                          src="@/assets/user.png"
                          alt="John"
                        />
                      </v-avatar>
                    </v-badge>
                    <v-avatar size="45" v-else>
                      <img src="@/assets/user.png" alt="John" />
                    </v-avatar>

                    <!-- <img :src="'https://image.flaticon.com/icons/svg/149/149071.svg'" alt="John" /> -->
                    <!-- <img
                  :class="role_level === 'True' ? 'admin' : ''"
                  src="@/assets/user.png"
                  alt="John"
                /> -->
                  </v-list-item-avatar>
                  <v-list-item-avatar size="80" v-else>
                    <v-badge
                      avatar
                      bordered
                      overlap
                      color="#D6AD60"
                      v-if="role_level === 'True'"
                    >
                      <template v-slot:badge>
                        <v-avatar>
                          <v-icon>mdi-crown</v-icon>
                        </v-avatar>
                      </template>

                      <v-avatar size="50">
                        <img
                          :class="role_level === 'True' ? 'admin' : ''"
                          :src="
                            'data:image/jpeg;base64,' +
                              dataCitizenProfile.picture
                          "
                          alt="John"
                        />
                      </v-avatar>
                    </v-badge>
                    <v-avatar size="45" v-else>
                      <img
                        :src="
                          'data:image/jpeg;base64,' + dataCitizenProfile.picture
                        "
                        alt="John"
                      />
                    </v-avatar>
                    <!-- <img
                :src="'data:image/jpeg;base64,' + dataCitizenProfile.picture"
                alt="John"
              /> -->
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ fullname }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      $store.getters.dataTypeusercurrent(
                        $store.state.account_active["type"],
                        $t("default")
                      )
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar>
                    <v-menu transition="slide-x-transition" left>
                      <template v-slot:activator="{ on }">
                        <v-btn fab text v-on="on" small>
                          <v-icon>mdi-palette</v-icon>
                        </v-btn>
                      </template>
                      <v-layout
                        :style="
                          $vuetify.theme.dark
                            ? 'background-color: black;'
                            : 'background-color: white;'
                        "
                        class="pa-1"
                      >
                        <v-flex>
                          <v-row no-gutters>
                            <v-subheader class="pa-0 pl-1">{{
                              $t("theme")
                            }}</v-subheader>
                          </v-row>
                          <v-row no-gutters>
                            <v-divider></v-divider>
                            <v-col
                              v-for="(item, n1) in color_1"
                              :key="n1"
                              class="pa-1"
                            >
                              <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card>  -->
                              <v-avatar
                                :color="item.name"
                                size="36"
                                @click="changecolor(item.data, item.data_code)"
                                class="elevation-4"
                                style="cursor: pointer"
                              >
                                <v-icon
                                  :dark="color.darkTheme"
                                  v-if="color.theme === item.name"
                                  >done</v-icon
                                >
                              </v-avatar>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col
                              v-for="(item, n2) in color_2"
                              :key="n2"
                              class="pa-1"
                            >
                              <v-avatar
                                :color="item.name"
                                size="36"
                                @click="changecolor(item.data, item.data_code)"
                                class="elevation-4"
                                style="cursor: pointer"
                              >
                                <v-icon
                                  :dark="color.darkTheme"
                                  v-if="color.theme === item.name"
                                  >done</v-icon
                                >
                              </v-avatar>
                              <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col
                              v-for="(item, n3) in color_3"
                              :key="n3"
                              class="pa-1"
                            >
                              <v-avatar
                                :color="item.name"
                                size="36"
                                @click="changecolor(item.data, item.data_code)"
                                class="elevation-4"
                                style="cursor: pointer"
                              >
                                <v-icon
                                  :dark="color.darkTheme"
                                  v-if="color.theme === item.name"
                                  >done</v-icon
                                >
                              </v-avatar>
                              <!-- <v-card class="pa-4"  outlined  :color="item.name" @click="changecolor(item.data)"></v-card> -->
                            </v-col>
                          </v-row>
                          <!-- <v-col>
                          <v-row>
                            <v-btn v-for="(item, i) in colors" :key="i" v-if="i<5" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                          </v-row><v-row>
                            <v-btn v-for="(item, i) in colors" :key="i" v-if="i>4 && i<10" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                          </v-row><v-row>
                            <v-btn v-for="(item, i) in colors" :key="i" v-if="i>9 && i<15" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                          </v-row><v-row>
                            <v-btn v-for="(item, i) in colors" :key="i" v-if="i>14 && i<20" elevation="1" fab x-small :color="item.name" @click="changecolor(item.data)"></v-btn>
                          </v-row>
                        </v-col>-->
                          <v-row>
                            <v-subheader
                              class="pa-0 pl-4 pointer"
                              @click="openColorpicker = true"
                              >{{ $t("toolbar.morecolor") }}</v-subheader
                            >
                          </v-row>
                        </v-flex>
                      </v-layout>
                    </v-menu>
                  </v-list-item-avatar>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <!-- <v-divider></v-divider> -->
            <!-- เมนูบนขวา -->
            <v-list dense v-if="dataAccountActive.type === 'Citizen'">
              <v-list-item>
                <v-icon :color="color.theme" class="mr-2"
                  >mdi-inbox-multiple</v-icon
                >
                <h4 :style="'color:' + color.theme">
                  {{ $t("toolbar.storage") }}
                </h4>
              </v-list-item>
              <v-list-item class="pt-0">
                <v-list-item-icon>
                  <v-btn fab dark small depressed color="#1876D1">
                    <v-icon dark>mdi-cloud</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="caption font-weight-light">
                    <v-layout
                      >{{ $t("toolbar.mydrive") }}
                      <v-spacer></v-spacer>
                      <strong class="caption font-weight-light">
                        {{ percentageStorage }} %
                      </strong>
                    </v-layout>
                    <v-progress-linear
                      :color="percentagestoage_setcolor_"
                      rounded
                      :value="percentageStorage"
                      height="7"
                    >
                    </v-progress-linear>
                    {{ storage_usage }} {{ $t("adminrecheckimap.in") }} {{ showDataStorage }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list dense v-else-if="dataAccountActive.type === 'Business'">
              <v-list-item>
                <v-icon :color="color.theme" class="mr-2"
                  >mdi-inbox-multiple</v-icon
                >
                <h4 :style="'color:' + color.theme">
                  {{ $t("toolbar.storage") }}
                </h4>
              </v-list-item>
              <v-list-item class="pt-0">
                <v-list-item-icon>
                  <v-btn fab dark small depressed color="#1876D1">
                    <v-icon dark>mdi-cloud</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="caption font-weight-light">
                    <v-layout
                      >{{ $t("toolbar.mydrive") }}
                      <v-spacer></v-spacer>
                      <strong class="caption font-weight-light">
                        {{ percentageStoragecitizeninbiz }} %
                      </strong>
                    </v-layout>
                    <v-progress-linear
                      :color="percentagestoagecitizeninbiz_setcolor_"
                      rounded
                      :value="percentageStoragecitizeninbiz"
                      height="7"
                    >
                    </v-progress-linear>
                    {{ storage_usage }} {{ $t("adminrecheckimap.in") }} {{ storage_max_personal_biz }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-btn fab dark small depressed color="#71B5D8">
                    <v-icon dark>business</v-icon>
                  </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle class="caption font-weight-light">
                    <v-layout
                      >{{ $t("toolbar.mydrive_bu") }}
                      <v-spacer></v-spacer>
                      <strong class="caption font-weight-light">
                        {{ percentageStoragebusiness }} %
                      </strong>
                    </v-layout>
                    <v-progress-linear
                      :color="percentagestoagebusiness_setcolor_"
                      rounded
                      :value="percentageStoragebusiness"
                      height="7"
                    >
                    </v-progress-linear>
                    {{ storage_usage_biz }} {{ $t("adminrecheckimap.in") }} {{ showDataStorage }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider class="mx-3"></v-divider>
            <v-card-text class="pa-0">
              <v-list>
                <v-list-item
                  v-for="(item, i) in listcctv()"
                  :key="i"
                  link
                  @click="linktomenu(item, choosetypeuser)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-for="(item, i) in listitem()"
                  :key="i"
                  link
                  @click="linktomenu(item, choosetypeuser)"
                >
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title style="line-height: 28px">{{
                      $t(item.text)
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <!-- <v-divider></v-divider>
            <v-list class="text-center">
              <v-list-item justify-center>
                <v-list-item-content>
                  <v-list-item-title>{{ version }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list> -->
            </v-card-text>
          </v-card>
        </v-menu>
        <v-menu transition="slide-y-transition" bottom>
          <template v-slot:activator="{ on }">
            <v-list-item-avatar v-on="on" class="ml-n3">
              <flag
                style="height: 16px; min-height: 0px; width:16px; min-width: 0px;"
                :iso="defaultflag"
                :squared="false"
              />
            </v-list-item-avatar>
          </template>
          <v-list nav dense>
            <v-list-item
              v-for="(item, i) in language"
              :key="i"
              @click="changelang(item.data)"
            >
              <v-list-item-action>
                <flag :iso="item.flag" :squared="false" />
              </v-list-item-action>
              <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
            class="mt-5 mr-4"
            icon
            small
          
            @click="fn_gotoeva()"
          >
            <v-icon :color="color.theme">mdi-file-document-edit</v-icon>
            <v-avatar style="margin-top:-35px;margin-left:-10px;" color="#FFC300" size="15px"   v-if="showicon == 'true' || showicon == true">
            <v-icon color="#f5f5f5" size="10px">
              mdi-alert
            </v-icon>
          </v-avatar>
          </v-btn>

      </v-layout>
    </v-app-bar>

    <dialogprofile
      :show="openprofile"
      @closedialog="openprofile = false"
      @reload="fn_setlogindefault(),$emit('callstorage'), $emit('loadfile'), $emit('closeDrag'), $emit('openDrag')"
    ></dialogprofile>

    <uploadfiles
      :sendswitchapi="sendswitchapi"
      :show="openUploadfiles"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="openUploadfiles = false, $emit('closeDrag'), $emit('openDrag')"
      @openuploadfile="openUploadfiles = true"
      @closeDrag="$emit('closeDrag')"
      @checkclosedialog="checkdialogupfile"
      @closemobile=" (openUploadfiles = false), $emit('callstorage'), $emit('loadfile'), $emit('closeDrag'), $emit('openDrag')"
    ></uploadfiles>

    <createfolder
      :show="openUploadfolder"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="openUploadfolder = false, $emit('closeDrag'), $emit('openDrag')"
      @closeandreload="$emit('loadfile'), (openUploadfolder = false), $emit('closeDrag'), $emit('openDrag')"
      @openuploadfolder="openUploadfolder = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @checkclosedialog="checkdialogupfile"
      @folder_detail="open_folder_when_create_complete"
    ></createfolder>

    <uploadfolder
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      :enabled_inputfile="openUploadfolderinput"
      @closewithoutload="(openUploadfolderinput = false), $emit('openDrag')"
      @closeUpload="
        (openUploadfolderinput = false),
          checkdialogupfile(),
          $emit('openDrag'),
          closeDialog()
      "
    ></uploadfolder>

    <colorpicker
      :show="openColorpicker"
      :setColor="setcolor"
      @close="closeDialog(), (openColorpicker = false)"
      @openColorpicker="openColorpicker = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></colorpicker>

    <dialogcontactbuystorage
      :show="opendialogcontact"
      @closedialog="opendialogcontact = false"
    ></dialogcontactbuystorage>
    <dialogregisterbusiness
      :show="openregisterbusiness"
      @closedialog="openregisterbusiness = false"
    ></dialogregisterbusiness>
    <dialogmed
      :show="opendialogmed"
      @closedialog="opendialogmed = false"
    ></dialogmed>
    <dialogshopdee
      :show="opendialogshopdee"
      @closedialog="opendialogshopdee = false"
    ></dialogshopdee>
    <dialogmapdrive
      :show="opendialogmapdrive"
      @closedialog="opendialogmapdrive = false"
    ></dialogmapdrive>

    <dialogalertevaluation
      :show="opendialogalertevaluation"
      @closedialog="opendialogalertevaluation = false"
      @reload="fn_checkevaluate()"
    ></dialogalertevaluation>
    <createfiles
      :show="openCreatefiles"
      :document_text="document_text"
      :document_type="document_type"
      :file_type="file_type"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @close="openCreatefiles = false"
      @closeandreload="$emit('loadfile'), (openCreatefiles = false), $emit('closeDrag'), $emit('openDrag')"
      @openuploadfolder="openCreatefiles = true"
      @file_detail="open_file_when_create_complete"
    >
    </createfiles>

    <dialogsmartupload
      :show="opensmartupload"
      @close="opensmartupload = false, $emit('closeDrag'), $emit('openDrag')"
      :sendswitchapi="sendswitchapi"
      :parentfolder="parentfolder"
      :departmentid="departmentid"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @closemobile=" (opensmartupload = false), $emit('callstorage'), $emit('loadfile'), $emit('closeDrag'), $emit('openDrag')"
    />

    <shortcut
      :show="openshortcut"
      @closeshortcut="openshortcut = false, $emit('closeDrag'), $emit('openDrag')"
    ></shortcut>

    <hashtagsearch
      :showHashtag="openHashtag"
      @closedialog="(openHashtag = false)"
    >

    </hashtagsearch>

    <!-- COOKIE TIMEOUT -->
    <div v-if="isShowDialogExpired">
      <dialogexpired 
      :showExpired="isShowDialogExpired" 
      :status="status_dialog"
      @logoutbotonebox="closedialogOtpexpired"
      @closecontinue="closediloagOtpcontinue"
      >
      </dialogexpired>
    </div>

    <dialogshowtoken
      :isopen_token="opendialog_getToken"
      @closedialog="fn_close_token()"
      @closeDragMain="fn_close_drag()"
      @openDragMain="fn_open_drag()"
    />

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import gbfGenerate from "../../globalFunctions/generateAuthorize";
import formatDatetime from "../../globalFunctions/formatDatetime";
import format from "date-fns/format";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";
const hashtagsearch = () => import ("../optional/dialog-hashtag-search");
const createfolder = () => import("../upload/dialog-uploadfolder");
const uploadfiles = () => import("../upload/dialog-uploadfiles2");
const uploadfolder = () => import("../upload/input-uploadfolder");
const dialogprofile = () => import("../optional/dialog-profile");
const colorpicker = () => import("../optional/dialog-colorpicker");
const shortcut = () => import("../optional/dialog-shortcut");
const dialogcontactbuystorage = () =>
  import("../optional/dialog-contactbuystorage");
const dialogregisterbusiness = () =>
  import("../optional/dialog-registerbusiness.vue");
const dialogmed = () => import("../optional/dialog-med.vue");
const dialogshopdee = () => import("../optional/dialog-shopdee.vue");
const dialogexpired = () => import("../optional/dialog-boxbotexpired.vue")
const dialogalertevaluation = () => import("../optional/dialog-alert-evaluation.vue");
const dialogsmartupload = () =>import("../upload/dialog-smartupload.vue");
const createfiles = () => import("../upload/dialog-createfiles");
const dialogmapdrive = () => import("../optional/dialog-mapdrive.vue");
const dialogshowtoken = () => import("../optional/dialog-showToken.vue");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  props: [
    "parentfolder",
    "quicksearch",
    "setcolor",
    "permissionfolder",
    "aspectSearch",
    "sendswitchapi",
    "departmentid",
    "heardertextsearch"
  ],
  components: {
    createfiles,
    createfolder,
    uploadfolder,
    uploadfiles,
    dialogprofile,
    colorpicker,
    shortcut,
    dialogcontactbuystorage,
    dialogregisterbusiness,
    dialogmed,
    dialogshopdee,
    dialogexpired, // maitest,
    hashtagsearch,
    dialogalertevaluation,
    dialogsmartupload,
    dialogmapdrive,
    dialogshowtoken

  },
  data: () => ({
    dataloginbusiness:[],
    opendialog_getToken: false,
    isFocusLabel: false,
    opendialogmapdrive:false,
    disableCheckboxG1: false,
    disableCheckboxG2: false,
    openCreatefiles:false,
    file_type:"",
    document_type:"",
    document_text:"",
    itemsCreateFile: [
        {
          text: "Microsoft Word Document",
          icon: "mdi-file-word",
          document_type: "word",
          file_extension: "docx",
        },
        {
          text: "Microsoft Excel Worksheet",
          icon: "mdi-file-excel",
          document_type: "excel",
          file_extension: "xlsx",
        },
        {
          text: "Microsoft PowerPoint Presentation",
          icon: "mdi-file-powerpoint",
          document_type: "powerpoint",
          file_extension: "pptx",
        },
        {
          text: "Text Document",
          icon: "mdi-file-document",
          document_type: "text",
          file_extension: "txt",
        },
      ],

    tamplate_id:"",
    smartuplloadsearch:false,
    datasmartupload:[],
    dataheader:[],
    list_smartsearch:[],
    value_search:"",
    datatemplate:[],
    list_detail_tem_text:[],
    list_detail_tem_dropdown:[],
    list_detail_tem_date:[],
    item_template:"",
    selectSearch_smartupload:"smartupload_search",
    smartuploadsearch:false,
    show_smartuploadsearch:false,
    items1: ['PDF', 'XML'],
    items2: ['ตรวจสอบ', 'ยังไม่ได้ตรวจสอบ'],
    itemstemplate: [],
    statusevaluation:"",
    eva_expired:"",
    icon_eva:false,
    opendialogalertevaluation:false,
    showicon:"",
    show_evaluate:false,
    errorSearch: {status: false, msg: ""},
    openHashtag: false,
    isOpenInfor: false,
    listpayload:{},
    count_data_adv: 0,
    page: 1,
    size: 30,
    show_search:false,
    status_dialog: "",
    interval_check_expired: "", // testmai
    testtoken_auth: "", // testmai
    isShowDialogExpired: false, // testmai
    check_service_oneplatfrom: "",
    show_setting: false,
    system_type_run: "",
    opendialogshopdee: false,
    opendialogmed: false,
    openregisterbusiness: false,
    show_hover: true,
    show_mohpromt: false,
    show_darkmode: false,
    show_manual: false,
    show_upload: false,
    show_purchase: false,
    show_closemenu: false,
    show_business_management: false,
    path_folder: "",
    path_folder_split: "",
    specific_folder: false,
    opendialogcontact: false,
    navbarcss_click: {},
    openshortcut: false,
    checkdefault: false,
    version: "",
    processdelete: false,
    openUploadfolderinput: false,
    dialogupfile: false,
    aspect_datefrom: [],
    aspect_dateto: [],
    aspect_valuedatefrom: [],
    aspect_valuedateto: [],
    aspectDropdown: "",
    aspect_data: null,
    selectSearch: "advance_search",
    showDataStorage: "",
    disabledatesearch: false,
    permission_denied_account: true,
    checkbusiness: false,
    advancesearch: false,
    date_now: format(new Date(), "yyyy-MM-dd"),
    fromdate: "",
    menufromdate: false,
    todate: "",
    menutodate: false,
    optionSearch: ["foldername", "filename", "hashtag"],
    type_Fulltextsearch: "content_text",
    typeSearch: "keywordsearch",
    type: "hex",
    hex: "#FF00FF",
    openprofile: false,
    search: "",
    show_menu: false,
    cctv: [
      {
        active: true,
        icon: "mdi-cctv",
        text: "toolbar.cctv",
        link: "/cctv",
        disable: false,
      },
    ],
    forbusiness_inbox: [
      {
        active: true,
        icon: "mdi-inbox-arrow-down",
        text: "toolbar.allbusinessinbox",
        link: "/allbusinessinbox",
        disable: false,
      },
      // {
      //   active: true,
      //   icon: "mdi-inbox-arrow-up",
      //   text: "toolbar.allbusinessoutbox",
      //   link: "/allbusinessoutbox",
      //   disable: false,
      // },
    ],
    forbusiness_outbox: [
      // {
      //   active: true,
      //   icon: "mdi-inbox-arrow-down",
      //   text: "toolbar.allbusinessinbox",
      //   link: "/allbusinessinbox",
      //   disable: false,
      // },
      {
        active: true,
        icon: "mdi-inbox-arrow-up",
        text: "toolbar.allbusinessoutbox",
        link: "/allbusinessoutbox",
        disable: false,
      },
    ],
    library: [
      // {
      //   active: true,
      //   icon: "mdi-inbox-arrow-down",
      //   text: "toolbar.allbusinessinbox",
      //   link: "/allbusinessinbox",
      //   disable: false,
      // },
      // {
      //   active: true,
      //   icon: "mdi-book-open-page-variant",
      //   text: "toolbar.library",
      //   link: "/library",
      //   disable: true,
      // },
    ],
    counter: 0,
    defaultaccount: "ผู้ใช้งานทั่วไป",
    defaultflag: "",
    typeuser: [],
    choosetypeuser: {},
    language: [
      {
        flag: "us",
        name: "languages.english",
        data: "en",
      },
      {
        flag: "th",
        name: "languages.thai",
        data: "th",
      },
    ],
    dialog: false,
    drawer: null,
    tabs: 2,
    openUploadfiles: false,
    openUploadfolder: false,
    openColorpicker: false,
    items: [
      {
        active: true,
        icon: "mdi-cloud",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive_bu",
        link: "/directory/:id",
        disable: false,
      },
      {
        active: true,
        // icon: "mdi-cloud-outline",
        icon: "mdi-cloud",
        "icon-alt": "keyboard_arrow_down",
        text: "toolbar.mydrive",
        link: "/mydrive",
        disable: false,
      },
      {
        active: true,
        icon: "star",
        text: "toolbar.mystarfile",
        link: "/starred",
        disable: false,
      },
      {
        active: true,
        prependicon: "mdi-inbox-arrow-down",
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-inbox-arrow-down",
        text: "toolbar.myinbox.Parentname",
        model: false,
        children: [
          {
            icon: "mdi-account-tie",
            text: "toolbar.myinbox.Personalname",
            link: "/myinboxsystem",
            type: 1,
          },
          {
            // icon: "business",
            icon: "mdi-office-building",
            text: "toolbar.myinbox.Personalname",
            link: "/myinboxsystem",
            type: 2,
          },
        ],
      },
      {
        active: true,
        icon: "folder_shared",
        text: "toolbar.mysharefile",
        link: "/mydrive",
        disable: true,
      },
      {
        active: true,
        icon: "mdi-folder-clock",
        text: "toolbar.expiredfile",
        link: "/fileexpired",
        disable: false,
      },
      {
        active: true,
        icon: "delete_sweep",
        text: "toolbar.mytrash",
        link: "/trash",
        disable: false,
      },
    ],
    color_1: [
      {
        name: "#B71C1C",
        data: "#B71C1C",
        data_code: "hsla(0, 73%, 99%, 1)",
      },
      {
        name: "#EF5F33",
        data: "#EF5F33",
        data_code: "hsla(14, 85%, 99%, 1)",
      },
      {
        name: "#FFA000",
        data: "#FFA000",
        data_code: "hsla(38, 100%, 99%, 1)",
      },
      {
        name: "#228B22",
        data: "#228B22",
        data_code: "hsla(120, 61%, 99%, 1)",
      },
    ],
    color_2: [
      {
        name: "#7CB342",
        data: "#7CB342",
        data_code: "hsla(89, 46%, 99%, 1)",
      },
      {
        name: "#174966",
        data: "#174966",
        data_code: "hsla(202, 63%, 97%, 1)",
      },
      {
        name: "#1E90FF",
        data: "#1E90FF",
        data_code: "hsla(210, 100%, 99%, 1)",
      },
      {
        name: "#622D90",
        data: "#622D90",
        data_code: "hsla(272, 52%, 99%, 1)",
      },
    ],
    color_3: [
      {
        name: "#F17B9B",
        data: "#F17B9B",
        data_code: "hsla(344, 81%, 99%, 1)",
      },
      {
        name: "#6D4C41",
        data: "#6D4C41",
        data_code: "hsla(15, 25%, 99%, 1)",
      },
      {
        name: "#696969",
        data: "#696969",
        data_code: "hsla(0, 0%, 99%, 1)",
      },
      {
        name: "#1976D2",
        data: "#1976D2",
        data_code: "hsla(210, 79%, 99%, 1)",
      },
    ],
    listmanual: [],
    opensmartupload: false,
    // conditions: [{
    //   title: "",
    //   detail: "",
    //   operation: "",
    // }]
  }),
  created() {
    this.changelang(localStorage.getItem("lang"));
    console.log(localStorage.getItem("color"));
    this.changecolor(
      localStorage.getItem("color"),
      localStorage.getItem("color_code_temp")
    );
    this.dataTypeUser();

    //this.checkbusinessfeature(this.choosetypeuser);
  },
  filters: {
    subStr: function(val) {
      if (val.length > 15) return val.substring(0, 15) + "...";
      else return val;
    },
  },
  computed: {
    ...mapState([
      "color",
      "status_permission_outbox",
      "status_permission_inbox",
      "feature_status",
      "role_level",
      "storage_usage",
      "storage_usage_biz",
      "storage_max_personal_biz",
      "status_message",
      "triggle_timeout_otp",
      "feature_logo",
      "logo_default",
      "feature_inputmore",
      "feature_onlyoffice",
      "feature_mapdrive"
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters({
      dataBusinessProfile: "dataBusinessProfile",
      dataAccesstoken: "dataAccesstoken",
    }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataAccountCCTV",
      "dataStorageMaxpersonalinbiz",
      "dataLoginDefault",
      "dataCheckPackage",
    ]),
    checkroutername() {
      return this.$router.app["_route"]["name"] || "";
    },
    percentageStorage() {
      let self = this;
      let percentUsageStorage =
        (this.convertstorageToByte(this.storage_usage) /
          this.convertstorageToByte(this.dataStorageMax)) *
        100;

      self.showDataStorage = self.dataStorageMax;

      return percentUsageStorage === Infinity || percentUsageStorage > 100
        ? 100
        : Math.ceil(percentUsageStorage);
    },
    percentageStoragecitizeninbiz() {
      let self = this;
      let percentUsageStorage =
        (this.convertstorageToByte(this.storage_usage) /
          this.convertstorageToByte(this.dataStorageMaxpersonalinbiz)) *
        100;

      self.showDataStorage = self.dataStorageMaxpersonalinbiz;

      return percentUsageStorage === Infinity || percentUsageStorage > 100
        ? 100
        : Math.ceil(percentUsageStorage);
    },
    // setcolor storage citizen  ที่ แถบเมนูด้านล่างซ้าย
    percentagestoage_setcolor() {
      if (
        Math.ceil(this.percentageStorage) >= 0 &&
        Math.ceil(this.percentageStorage) < 70
      ) {
        return "green";
      } else if (
        Math.ceil(this.percentageStorage) >= 70 &&
        Math.ceil(this.percentageStorage) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStorage) >= 90) {
        return "red";
      }
    },
    // setcolor storage business  ที่ แถบเมนูบนขวา
    percentagestoage_setcolor_() {
      if (
        Math.ceil(this.percentageStorage) >= 0 &&
        Math.ceil(this.percentageStorage) < 70
      ) {
        return "green";
        // return this.color.theme;
      } else if (
        Math.ceil(this.percentageStorage) >= 70 &&
        Math.ceil(this.percentageStorage) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStorage) >= 90) {
        return "red";
      }
    },
    // setcolor storage citizen in biz ที่ แถบเมนูด้านบนขวา
    percentageStoragebusiness() {
      let self = this;
      let percentUsageStorage =
        (this.convertstorageToByte(this.storage_usage_biz) /
          this.convertstorageToByte(this.dataStorageMax)) *
        100;

      self.showDataStorage = self.dataStorageMax;

      return percentUsageStorage === Infinity || percentUsageStorage > 100
        ? 100
        : Math.ceil(percentUsageStorage);
    },
    // setcolor storage citizen  ที่ แถบเมนูด้านล่างซ้าย
    percentagestoagebusiness_setcolor() {
      if (
        Math.ceil(this.percentageStoragebusiness) >= 0 &&
        Math.ceil(this.percentageStoragebusiness) < 70
      ) {
        return "green";
      } else if (
        Math.ceil(this.percentageStoragebusiness) >= 70 &&
        Math.ceil(this.percentageStoragebusiness) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 90) {
        return "red";
      }
    },
    // setcolor storage citizen in biz  ที่ แถบเมนูด้านล่างซ้าย
    percentagestoagecitizeninbiz_setcolor() {
      if (
        Math.ceil(this.percentageStoragecitizeninbiz) >= 0 &&
        Math.ceil(this.percentageStoragecitizeninbiz) < 70
      ) {
        return "green";
      } else if (
        Math.ceil(this.percentageStoragecitizeninbiz) >= 70 &&
        Math.ceil(this.percentageStoragecitizeninbiz) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragecitizeninbiz) >= 90) {
        return "red";
      }
    },
    // setcolor storage biz  ที่ แถบเมนูด้านล่างซ้าย (ชั่วคราว)
    percentagestoagebiz_setcolor_right() {
      if (
        Math.ceil(this.percentageStoragebusiness) >= 0 &&
        Math.ceil(this.percentageStoragebusiness) < 70
      ) {
        return "green";
      } else if (
        Math.ceil(this.percentageStoragebusiness) >= 70 &&
        Math.ceil(this.percentageStoragebusiness) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 90) {
        return "red";
      }
    },
    // setcolor storage citizen in biz ที่ แถบเมนูด้านบนขวา
    percentagestoagecitizeninbiz_setcolor_() {
      if (
        Math.ceil(this.percentageStoragecitizeninbiz) >= 0 &&
        Math.ceil(this.percentageStoragecitizeninbiz) < 70
      ) {
        // return this.color.theme;
        return "green";
      } else if (
        Math.ceil(this.percentageStoragecitizeninbiz) >= 70 &&
        Math.ceil(this.percentageStoragecitizeninbiz) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragecitizeninbiz) >= 90) {
        return "red";
      }
    },
    // setcolor storage business  ที่ แถบเมนูบนขวา
    percentagestoagebusiness_setcolor_() {
      if (
        Math.ceil(this.percentageStoragebusiness) >= 0 &&
        Math.ceil(this.percentageStoragebusiness) < 70
      ) {
        // return this.color.theme;
        return "green";
      } else if (
        Math.ceil(this.percentageStoragebusiness) >= 70 &&
        Math.ceil(this.percentageStoragebusiness) < 90
      ) {
        return "#ffcc00";
      } else if (Math.ceil(this.percentageStoragebusiness) >= 90) {
        return "red";
      }
    },

    _oldpercentageStorage() {
      let self = this;
      let _dataStorageMax = [];
      // console.log("tttt", this.dataStorageMax);

      // if(){}
      let datastorage_max = this.dataStorageMax.split(" ");
      // console.log("datastorage_max", datastorage_max);

      if (datastorage_max[1] === "KB") {
        if (datastorage_max[0] / 1048576 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1048576));
          _dataStorageMax.push("GB");
          self.showDataStorage =
            String(parseFloat(datastorage_max[0] / 1048576).toFixed(2)) +
            " " +
            "GB";
        } else {
          _dataStorageMax.push(String(datastorage_max[0]));
          _dataStorageMax.push("GB");
          self.showDataStorage =
            String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "KB";
        }
      } else if (datastorage_max[1] === "MB") {
        if (datastorage_max[0] / 1024 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1024));
          _dataStorageMax.push("GB");
          self.showDataStorage =
            String(parseFloat(datastorage_max[0] / 1024).toFixed(2)) +
            " " +
            "GB";
        } else {
          _dataStorageMax = this.dataStorageMax.split(" ");
          self.showDataStorage = String(datastorage_max[0]) + " " + "MB";
        }
      } else if (datastorage_max[1] === "GB") {
        _dataStorageMax = this.dataStorageMax.split(" ");
        self.showDataStorage =
          String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "GB";
      }
      console.log("Ddd", _dataStorageMax);

      // console.log("citizen---",this.storage_usage);
      // console.log("business---",this.storage_usage_biz);

      let _dataStorageUsage = this.storage_usage.split(" ");

      if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "MB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage);
        // console.log(max_storage);
        let total = (current_storage / max_storage) * 100;
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total) || 100;
        }
      } else if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "KB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total) || 100;
        }
      } else {
        let max_storage = parseFloat(_dataStorageMax[0]);
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        if (max_storage === 0 && current_storage === 0) {
          return 0;
        }
        let total = (current_storage / max_storage) * 100;
        // console.log("t------",total);
        if (total === Infinity) {
          return 100;
        } else {
          return Math.ceil(total);
        }
      }
    },

    _oldpercentageStoragebusiness() {
      let self = this;
      let _dataStorageMax = [];
      console.log("tttt", this.dataStorageMax);
      let datastorage_max = this.dataStorageMax.split(" ");
      console.log("datastorage_max", datastorage_max);

      if (datastorage_max[1] === "KB") {
        if (datastorage_max[0] / 1048576 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1048576));
          _dataStorageMax.push("GB");
          self.showDataStorage =
            String(parseFloat(datastorage_max[0] / 1048576).toFixed(2)) +
            " " +
            "GB";
        } else {
          _dataStorageMax.push(String(datastorage_max[0]));
          _dataStorageMax.push("GB");
          self.showDataStorage =
            String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "KB";
        }
      } else if (datastorage_max[1] === "MB") {
        if (datastorage_max[0] / 1024 >= 1) {
          _dataStorageMax.push(String(datastorage_max[0] / 1024));
          _dataStorageMax.push("GB");
          self.showDataStorage =
            String(parseFloat(datastorage_max[0] / 1024).toFixed(2)) +
            " " +
            "GB";
        } else {
          _dataStorageMax = this.dataStorageMax.split(" ");
          self.showDataStorage = String(datastorage_max[0]) + " " + "MB";
        }
      } else if (datastorage_max[1] === "GB") {
        _dataStorageMax = this.dataStorageMax.split(" ");
        self.showDataStorage =
          String(parseFloat(datastorage_max[0]).toFixed(2)) + " " + "GB";
      }

      // console.log("Ddd", _dataStorageMax);
      // console.log("bbbbb", this.storage_usage_biz);
      let _dataStorageUsage = this.storage_usage_biz.split(" ");

      if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "MB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        return Math.ceil(total) || 100;
      } else if (_dataStorageMax[1] === "GB" && _dataStorageUsage[1] === "KB") {
        let max_storage = parseFloat(_dataStorageMax[0]) * 1000000;
        let current_storage = parseFloat(_dataStorageUsage[0]);
        let total = (current_storage / max_storage) * 100;
        // console.log(total);
        return Math.ceil(total) || 100;
      } else {
        let max_storage = parseFloat(_dataStorageMax[0]);
        let current_storage = parseFloat(_dataStorageUsage[0]);
        // console.log(current_storage)
        // console.log(max_storage)
        if (max_storage === 0 && current_storage === 0) {
          return 0;
        }
        let total = (current_storage / max_storage) * 100;
        // console.log("totalb----",total);
        if (total === Infinity) {
          return 100;
        } else {
          // console.log("t------",total);
          return Math.ceil(total);
        }
      }
    },

    fullname() {
      console.log(this.$store.getters.dataCitizenProfile);
      if (this.$t("default") === "th") {
        return (
          this.$store.getters.dataCitizenProfile["first_name_th"] +
            " " +
            this.$store.getters.dataCitizenProfile["last_name_th"] || ""
        );
      } else {
        if (
          this.$store.getters.dataCitizenProfile.hasOwnProperty(
            "first_name_eng"
          ) === true
        ) {
          return (
            this.$store.getters.dataCitizenProfile["first_name_eng"] +
              " " +
              this.$store.getters.dataCitizenProfile["last_name_eng"] || ""
          );
        } else {
          return (
            this.$store.getters.dataCitizenProfile["first_name_th"] +
              " " +
              this.$store.getters.dataCitizenProfile["last_name_th"] || ""
          );
        }
      }
    },

    imageHeight() {
      let self = this;
      let resolution = 0;
      switch (self.$vuetify.breakpoint.name) {
        case "xs":
          resolution = 220;
          break;
        case "sm":
          resolution = 400;
          break;
        case "md":
          resolution = 500;
          break;
        case "lg":
          resolution = 600;
          break;
        case "xl":
          resolution = 800;
          break;
      }
      return resolution;
    },

    ismydrive() {
      return this.$router.app["_route"]["name"] == "myfiles";
    },
    // sortColor() {
    //   let self = this;
    //   for (let i = 0; i < self.colors.length; i++) {
    //     return self.colors[i];
    //   }
    // },
    colorset: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      },
    },

    showColor() {
      if (typeof this.colorset === "string") return this.colorset;

      return JSON.stringify(
        Object.keys(this.colorset).reduce((colorset, key) => {
          colorset[key] = Number(this.colorset[key].toFixed(2));
          return colorset;
        }, {}),
        null,
        2
      );
    },
    disableByPackage() {
      // return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      return false;
    },
    darkModeIcon() {
      return this.$vuetify.theme.dark ? "white" : "blue-grey darken-4";
    },
    darkModeText() {
      return this.$vuetify.theme.dark ? "color: white; font-size: 14px" : "color: #263238; font-size: 14px"; 
    },
    // darkModeMutiIcon() {
    //   return this.$vuetify.theme.dark
    //     ? "border-color:" + this.color.theme + ";"
    //     : "border-color:" + this.color.theme + ";";
    // },
    // ตัวหนา
    listOnBoldFontNormal() {
      return "font-size: 16px; font-weight: 700;";
    },
    // ตัวบาง
    listNoBoldFontNormal() {
      return "font-size: 16px; font-weight: 400; color: #636363;";
    },
    // ตัวหนา
    listOnBoldFontSmall() {
      return "font-size: 14px; font-weight: 700;";
    },
    // ตัวบาง
    listNoBoldFontSmall() {
      return "font-size: 14px; font-weight: 400; color: #636363;";
    },
    // ความสูงของ list-item ของเมนูรายการที่ต้องกด
    heightListItem() {
      return "height: 40px; min-height: 0px;";
    },
    // สีของอักษรสำหรับ mobile
    textThemeFontNormal() {
      return this.$vuetify.theme.dark
        ? "color: white; font-size: 16px; line-height: 24px;"
        : "color:" + this.color.theme + "; font-size: 16px; line-height: 24px;";
    },
    // สีของอักษรสำหรับ mobile
    textThemeFontSmall() {
      return this.$vuetify.theme.dark
        ? "color: white; font-size: 14px; line-height: 24px;"
        : "color:" + this.color.theme + "; font-size: 14px; line-height: 24px;";
    },
    // สีของไอคอนสำหรับ mobile
    iconTheme() {
      return this.$vuetify.theme.dark ? "white" : this.color.theme;
    },
    // ความสูงของเมนูข้างซ้าย
    heightListMenuLeft() {
      return "height: 54px; min-height: 0px;";
    },
    errorsearch () {
      const errors = []
      if(this.errorSearch.status){
        errors.push(
          this.errorSearch.msg
        );
      }
      
      return errors;
    }
  },
  methods: {
    fn_close_drag () {
      console.log("ทำไมมมมไม่ได้้้")
      // this.$emit("removedragselectevent")  บน Prod ยังไม่มี dragevent ตัวใหม่
     },
    fn_open_drag () {
      console.log("Open Drag Event")
      // this.$emit("createdragselectevent") บน Prod ยังไม่มี dragevent ตัวใหม่
    },
    fn_close_token () {
      this.opendialog_getToken = false
    },
    fn_selectFilterSearch (value) {
      if(this.optionSearch.length === 0){
        this.optionSearch = []
      } else {
        if(value === 1) {
          this.optionSearch = this.optionSearch.filter((item) => item !== "file_content")
        } else {
          this.optionSearch = this.optionSearch.filter((item) => item === "file_content")
        }
      }
    },
    tolink_dialog_mapdrive(){
      this.opendialogmapdrive = true;
    },
    open_folder_when_create_complete(data){
      // console.log("open_folder_when_create_complete",data);
      this.$emit("open_folder",data)
    },
    open_file_when_create_complete(data){
      // console.log("open file when create complete",data);
      this.$emit("open_file_office",data)
    },
    create_files(text, document_type, type){
      this.openCreatefiles = true;
      this.document_text = text;
      this.document_type = document_type;
      this.file_type = type;
      this.$emit("callparentfolder");
    },
    async callsmartuploadsearch(){
      this.smartuplloadsearch = true;
      this.$emit("callsmartuploadsearch",this.datatemplate,this.tamplate_id)
      console.log("datatemplate toolbar",this.datatemplate);
      this.$emit("smartuplloadsearch",this.smartuplloadsearch)
      let list_smartsearch = []
      for(let i=0; i < this.datatemplate.length; i++){
        if(this.datatemplate[i].aspect_type === "TEXT"){
          console.log("value_search",this.datatemplate[i].value_search);
         if(this.datatemplate[i].value_search === null || this.datatemplate[i].value_search === undefined || this.datatemplate[i].value_search === ""){
          }else{
            list_smartsearch.push({
              "aspect_id":this.datatemplate[i].aspect_id,
              "aspect_key":this.datatemplate[i].aspect_name,
              "aspect_label":this.datatemplate[i].aspect_label,
              "aspect_value":this.datatemplate[i].value_search
            })
          }
        }else if(this.datatemplate[i].aspect_type === "DROPDOWN"){
          if(this.datatemplate[i].value === null || this.datatemplate[i].value === undefined || this.datatemplate[i].value === ""){
          }else{
            list_smartsearch.push({
              "aspect_id":this.datatemplate[i].aspect_id,
              "aspect_key":this.datatemplate[i].aspect_name,
              "aspect_label":this.datatemplate[i].aspect_label,
              "aspect_value":this.datatemplate[i].value
            })
          }
        }else if(this.datatemplate[i].aspect_type === "DATE"){
          if(this.datatemplate[i].fromdate === null || this.datatemplate[i].fromdate === undefined || this.datatemplate[i].fromdate === ""){
          }else{
            let dt_date = this.datatemplate[i].fromdate.split("-")
            list_smartsearch.push({
              "aspect_id":this.datatemplate[i].aspect_id,
              "aspect_key":this.datatemplate[i].aspect_name,
              "aspect_label":this.datatemplate[i].aspect_label,
              "aspect_value":dt_date[0] + dt_date[1] + dt_date[2]
            })
          }
        }
      }
    
      if(list_smartsearch.length === 0){
        this.smartuploadsearch = true;
      }else{
        this.smartuploadsearch = false;
      }
      
      // console.log("callsmartuploadsearch",this.datatemplate);
      // this.list_smartsearch = [];
      // for(let i=0; i < this.datatemplate.length; i++){
      //   if(this.datatemplate[i].aspect_type === "TEXT"){
      //     console.log("value_search",this.datatemplate[i].value_search);
      //    if(this.datatemplate[i].value_search === null || this.datatemplate[i].value_search === undefined || this.datatemplate[i].value_search === ""){
      //     }else{
      //       this.list_smartsearch.push({
      //         "aspect_id":this.datatemplate[i].aspect_id,
      //         "aspect_key":this.datatemplate[i].aspect_name,
      //         "aspect_label":this.datatemplate[i].aspect_label,
      //         "aspect_value":this.datatemplate[i].value_search
      //       })
      //     }
      //   }else if(this.datatemplate[i].aspect_type === "DROPDOWN"){
      //     if(this.datatemplate[i].value === null || this.datatemplate[i].value === undefined || this.datatemplate[i].value === ""){
      //     }else{
      //       this.list_smartsearch.push({
      //         "aspect_id":this.datatemplate[i].aspect_id,
      //         "aspect_key":this.datatemplate[i].aspect_name,
      //         "aspect_label":this.datatemplate[i].aspect_label,
      //         "aspect_value":this.datatemplate[i].value
      //       })
      //     }
      //   }else if(this.datatemplate[i].aspect_type === "DATE"){
      //     if(this.datatemplate[i].fromdate === null || this.datatemplate[i].fromdate === undefined || this.datatemplate[i].fromdate === ""){
      //     }else{
      //       let dt_date = this.datatemplate[i].fromdate.split("-")
      //       this.list_smartsearch.push({
      //         "aspect_id":this.datatemplate[i].aspect_id,
      //         "aspect_key":this.datatemplate[i].aspect_name,
      //         "aspect_label":this.datatemplate[i].aspect_label,
      //         "aspect_value":dt_date[0] + dt_date[1] + dt_date[2]
      //       })
      //     }
      //   }
      // }
      // console.log("list_smartsearch",this.list_smartsearch);
      // if(this.list_smartsearch.length === 0){

      // }else{
      //   let payload = {
      //     "business_id": this.dataAccountActive.business_info.business_id,
      //     "account_id": this.dataAccountId,
      //     "key_body": this.list_smartsearch
      //   }
      //   console.log("payload",payload);
      //   let auth = await gbfGenerate.generateToken();
      //   this.axios
      //   .post(
      //     process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/smartbox/v1/smart_search",
      //     payload,{
      //         headers: { Authorization: auth.code },
      //       }
      //   )
      //   .then((response) => {
      //     if (response.data.status == "OK") {
      //       console.log("search smaert UP",response.data);
      //       this.datasmartupload = response.data.data
      //       this.dataheader = response.data.key_header
      //       this.$emit("datasmartupload", this.datasmartupload);
      //       this.$emit("dataheader", this.dataheader)
      //     } else {
      //       console.log("response error",response.data);
      //     }
      //   })
      //   .catch((error) => {
      //     console.log("error gettamplate search", error);
      //   });

      // }
    },
    async fn_gettamplate_smartupload(){
      console.log("เข้าฟังก์ชัน fn_gettamplate_smartupload");
      let auth = await gbfGenerate.generateToken();
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_id: this.dataAccountId
      }
      console.log("payload121212",payload);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/smart_box/get_template",
          payload, {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.itemstemplate = response.data.data;
            this.item_template = response.data.data[0];
            this.fn_get_detail_template(response.data.data[0].template_id);
          } else {
            console.log("response error",response.data);
          }
        })
        .catch((error) => {
          console.log("error gettamplate search", error);
        });
    },
    async fn_get_detail_template(id){
      this.list_detail_tem_text = [];
      this.list_detail_tem_dropdown = [];
      this.list_detail_tem_date = [];
      let auth = await gbfGenerate.generateToken();
      let payload = {
        "template_id": id
      }
      console.log("fn_get_detail_template",id);
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/v1/smart_box/get_template_details",
          payload, {
            headers: { Authorization: auth.code },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            console.log("response fn_get_detail_template",response.data.data.aspect_data);
            this.tamplate_id = id;
            this.datatemplate = response.data.data.aspect_data;
            // for(let i = 0; i < response.data.data.aspect_data.length; i++){
            //   if(response.data.data.aspect_data[i].aspect_type === "TEXT"){
            //     this.list_detail_tem_text.push(response.data.data.aspect_data[i])
            //   }else if(response.data.data.aspect_data[i].aspect_type === "DROPDOWN"){
            //     this.list_detail_tem_dropdown.push(response.data.data.aspect_data[i])
            //   }else if(response.data.data.aspect_data[i].aspect_type === "DATE"){
            //     this.list_detail_tem_date.push(response.data.data.aspect_data[i])
            //   }
            // }
            // console.log(" this.list_detail_tem_text",this.list_detail_tem_text);
            // console.log(" this.list_detail_tem_dropdown",this.list_detail_tem_dropdown);
            // console.log(" this.list_detail_tem_date",this.list_detail_tem_date);
          } else {
            console.log("response error",response.data);
          }
        })
        .catch((error) => {
          console.log("error gettamplate search", error);
        });
    },
    fn_gotoeva(){
      this.opendialogalertevaluation = true;
      this.showicon = false;
    },
    fn_checkevaluate(){
      let one_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      one_id = window.atob(one_id.toString(CryptoJS.enc.Utf8))

      let payload = {
        one_id: one_id
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
          "/api/get_user_evaluate",
          payload,
          // {
          //   headers: { Authorization: auth.code },
          // }
        )
        .then((response) => {
          console.log("response eva",response);
          if (response.data.status === "OK") {
            this.statusevaluation = response.data.data.status_evaluate
            this.eva_expired = response.data.data.evaluate_expired
             if(this.statusevaluation === "N"){
              if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                this.showicon = false
              }
             }else if(this.statusevaluation === "W"){
              if(this.eva_expired === "N"){
                this.opendialogalertevaluation = false;
                this.showicon = true
              }else if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                this.showicon = false
              }
             }else if(this.statusevaluation === "Y"){
              if(this.eva_expired === "N"){
                this.opendialogalertevaluation = false;
                this.showicon = false
              }else if(this.eva_expired === "Y"){
                this.opendialogalertevaluation = true;
                this.showicon = false
              }
             }
             this.show_evaluate = false;
             console.log(" this.showicon",this.showicon);
             VueCookies.set("iconeva",this.showicon)
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    fn_openhashtag (e) {
      e.preventDefault()
      console.log("HELLO There ", e)
      this.openHashtag = true
    },
    changeFromdatedocument() {
      var datenow = format(new Date(), "yyyy-MM-dd");
      if(this.fromdate > this.todate ){
        this.fromdate = this.todate
      }else{
        this.fromdate = this.todate
      }
      this.$refs.menufromdate.save(this.fromdate);     

    },
    fn_setlogindefault() {
      console.log("this.dataBusinessProfile", this.dataAccountActive.business_info.first_name_th);
      // this.dataBusinessProfile
      this.choosetypeuser = {
        type: "Businass",
        data: this.dataAccountActive.business_info,
        nameth: this.dataAccountActive.business_info.first_name_th,
        nameen: this.dataAccountActive.business_info.first_name_eng,
        taxid: this.dataAccountActive.business_info.taxid,
        biz_id: this.dataAccountActive.business_info.biz_id,
        business_id: this.dataAccountActive.business_info.business_id,
      };
      // this.choosetypeuser = this.$t('default') === 'en' ? this.dataAccountActive.business_info.first_name_eng : this.dataAccountActive.business_info.first_name_th
    },
    diff_minutes (dt2, dt1) { // ใข้งานอยู่
      var diff =(dt2 - dt1) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff))
    },

    encryptdata (raw_data) { // ใข้งานอยู่
      let enc = CryptoJS.AES.encrypt(window.btoa(raw_data), 
      CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY),{ mode: CryptoJS.mode.ECB, }).toString()
      return enc
    },

    decryptdata (data) { // ใข้งานอยู่
      try{
      var data_decrypt = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      data_decrypt = window.atob(data_decrypt.toString(CryptoJS.enc.Utf8)) 
      return data_decrypt
      } catch(err) {
        return "error"
      }
    },

    checkusedTime_otp () { // ใข้งานอยู่ (เพิ่มมาใช้ในการ watch directory/id)
      if(this.decryptdata(VueCookies.get("username")) === process.env.VUE_APP_ACCOUNT_BOT){
        var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
        var timeUsed = this.diff_minutes(new Date().getTime(), new Date(check_logindatetime).getTime())
        if(timeUsed <= 10 && VueCookies.get("continue_otp") === "false"){
          this.$store.dispatch("triggle_otp_botonebox_manage", {status: "WARNING", show: true})
        } else if (timeUsed <= 0) { 
          this.$store.dispatch("triggle_otp_botonebox_manage", {status: "TIMEOUT", show: true})
        }
        this.triggle_otp_timeout()
      } else {
        return
      }
    },

    async triggle_otp_timeout () { // ใข้งานอยู่
      if(this.triggle_timeout_otp === true){
        this.isShowDialogExpired = true
        this.status_dialog = this.status_message
      }
    },


    async starttriggle_auth_token () { // ปิดการใช้งาน
      if (VueCookies.get("username") !== null && VueCookies.get("username") !== "" && 
          VueCookies.get("username") !== undefined) {
        let username_show = this.decryptdata(VueCookies.get("username"))
        if(username_show === process.env.VUE_APP_ACCOUNT_BOT){
          if(VueCookies.isKey("checklogin") === false){
          this.interval_check_expired = setInterval(() => {
          this.triggle_expired_token_botonbox_temporary ()
          }, 6000)
        } else {
          this.interval_check_expired = setInterval(() => {
          this.triggle_expired_token_botonbox()
          }, 6000)
        }
      }
    }

    },


    triggle_expired_token_botonbox_temporary (status) { // ปิดการใช้งาน
      var check_isExist_login_logindatetime = VueCookies.isKey("login_datetime")
      var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
      if(check_isExist_login_logindatetime === true  && check_logindatetime !== "error" && VueCookies.isKey("data_id")){
        var timeUsed = this.diff_minutes(new Date().getTime(), new Date(check_logindatetime).getTime())
        if(timeUsed === 10 && VueCookies.get("continue_otp") === "false"){
            this.isShowDialogExpired = true
            this.status_dialog = "WARNING"
            clearInterval(this.interval_check_expired)
            
        } else if (timeUsed  <=  0) {
            this.isShowDialogExpired = true
            this.status_dialog = "TIMEOUT"
            clearInterval(this.interval_check_expired)
        }
      } else {
        clearInterval(this.interval_check_expired)
        console.log("changed some value")
        this.$router.push({ path: "/logout" });
      }
    },

    triggle_expired_token_botonbox (status) { // ปิดการใช้งาน
      // ยังไงมันก็ต้องวิธีการเช็คค่าต่างของ botbox ถ้าหากถูกเปลี่ยนค่าหรือถูกลบ จะต้องถูก logout ทันที
      var check_isExist_login_logindatetime = VueCookies.isKey("login_datetime")
      var check_logindatetime = this.decryptdata(VueCookies.get("login_datetime"))
      if( check_isExist_login_logindatetime === true &&  isNaN(check_logindatetime) === true &&
          check_logindatetime !== "error" && VueCookies.isKey("data_id")){
        if( VueCookies.isKey("token_auth") === true  ) {
          var dateLogin = this.decryptdata(VueCookies.get("login_datetime"))
          var timeUsed = this.diff_minutes(new Date().getTime(), new Date(dateLogin).getTime())
          if(timeUsed === 10 && VueCookies.get("continue_otp") === "false"){
            this.isShowDialogExpired = true
            this.status_dialog = "WARNING"
            clearInterval(this.interval_check_expired)
          // return
          }
          
        }  else {
          this.isShowDialogExpired = true
          this.status_dialog = "TIMEOUT"
          clearInterval(this.interval_check_expired)
        }
      } else {
        //มรการเปลี่ยนแปลงหรือลบบางค่าของ otp
        clearInterval(this.interval_check_expired)
        console.log("changed some value")
        this.$router.push({ path: "/logout" });
      }
    },
    
    triggle_feature_logo () {
      try{
      if(this.dataAccountActive["type"] === "Business"){
        if(this.feature_logo){
          if(this.logo_default === "" || this.logo_default === 'default'){ // แสดงว่ายังไม่มีการเปลี่ยน logo หรือ ตั้งค่า logo เป็น default
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      } else {
        return false
      }
      } catch (err) {
        return false
      }
      
    },

    async logout_bot_onebox () { // ใช้งานอยู่
      let payload = {
        data_id:  VueCookies.get("data_id")
      }
      return await this.axios
      .post(process.env.VUE_APP_SERVICE_AUTHORIZE_CITIZEN + "/api/v1/logout/otp", payload)
      .then((response) => {
        return new Promise((resolve, reject) => {
          if (response.data.status === "OK") {
            setTimeout(() => {
              resolve({ status: "OK", message: "Verified" });
            }, 500);
          } else {
            setTimeout(() => {
              resolve({ status: "INVALID OTP", message: "Invalid OTP provided" });
            }, 500);
          }
        });
      })
      .catch((err) => {})
    },

    async closedialogOtpexpired () {
      await this.logout_bot_onebox ()
      .then((res) => {
        if(res.status === 'OK'){
          this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false}) // clear vuex
          this.$router.push({ path: "/logout" });
        }
      })
    },
    closediloagOtpcontinue () {
      this.$store.dispatch("triggle_otp_botonebox_manage", {status: "", show: false})
      this.isShowDialogExpired = false
      //this.starttriggle_auth_token()
    },

    fn_gotoetax() {
      console.log("this.system_type_run",this.system_type_run);
      if(this.system_type_run === 'PRODUCTION'){
        window.open(
          "https://etax.one.th/portal/login_sso?taxid=" +
            this.dataAccountActive.taxid +
            "&accesstoken=" +
            this.dataAccesstoken
        );
      }else{
        window.open(
          "https://devinet-etax.one.th/portal/login_sso?taxid=" +
            this.dataAccountActive.taxid +
            "&accesstoken=" +
            this.dataAccesstoken
        );
      }
    },
    clickManual(url) {
      window.open(url)
      // if (item === 1) {
      //   window.open(
      //     "https://onebox.siameastsolutions.com/public?id=9dd5dc8ef27ec253b80b86ceeec01565"
      //   );
      // } else if (item === 2) {
      //   window.open(
      //     "https://onebox.siameastsolutions.com/public?id=e9230decd699c822bdf809f12dd3bf3f"
      //   );
      // } else if (item === 3) {
      //   window.open(
      //     "https://onebox.siameastsolutions.com/public?id=94bd222c3c4448b25fe48fa72e04c3a4"
      //   );
      // } else if (item === 4) {
      //   window.open(
      //     "https://onebox.siameastsolutions.com/public?id=16bc24e8c021f817415aaf1ec74de382"
      //   );
      // } else if (item === 5) {
      //   window.open(
      //     "https://onebox.siameastsolutions.com/public?id=f53838c9e82dda307a37c1f919d6f4d9"
      //   );
      // }
    },
    async fnGetManual() {
      let auth = await gbfGenerate.generateToken();
      this.axios
        .get(
          process.env.VUE_APP_SERVICE_ADMIN +
            "/api/admin/get_user_manual?type=basic",
          { headers: { Authorization: auth.code } }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            this.listmanual = res.data.result;
            this.$emit('report_page', this.listmanual)
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("manual.manualfail"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("manual.manualfail"),
          });
        });
    },

    fn_checkAccountActivetypebiz() {
      if (this.dataAccountActive["type"] === "Business") {
        this.$router.push({ path: "/packagenew" });
      } else {
        this.$router.push({ path: "/packagecurrent" });
        // this.opendialogcontact = true
      }
    },
    fn_checkreportSystem () {
      this.$router.push({ path: "/report" });
    },
    fn_admin() {
      this.$router.push({ path: "/onebox-system-admin-index" });
      // this.$router.push({ path: "/system-admin-index" });
    },
    fn_shortcut() {
      this.openshortcut = true;
    },
    test() {
      console.log("กดenter");
    },
    checkdialogupfile() {
      this.dialogupfile = true;
    },
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1024
            : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1048576
            : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1073741824
            : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0] * 1099511627776
            : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte =
          typeof splitStorage[0] === "number"
            ? splitStorage[0]
            : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },

    reloadtomydrive() {
      if (this.dataAccountActive["type"] === "Business") {
        let data_buffer = sessionStorage.getItem("biz_active");
        let data_biz = JSON.parse(data_buffer);
        console.log(data_biz);

        this.$store.dispatch("switchaccount_business", data_biz).then((msg) => {
          //this.$emit("loadfile");
          //this.$emit("callstorage");
          if (msg.status === "Business Access Granted") {
            // if()
            //this.$router.push({ name: "myfiles" });
            this.$router.push({
              path:
                "/directory/" +
                this.dataAccountActive.business_info["my_folder_id"],
            });
          } else {
            this.$router.push("logout");
          }
        });
      } else {
        this.$router.push({ name: "myfiles" });
      }
    },

    viewLog(val) {
      console.log("View log =>", val);
    },
    // addSearch() {
    //   this.conditions.push({
    //     title: "",
    //     detail: "",
    //     operation: "",
    //   })
    //   console.log("conditions", this.conditions)
    // },
    async aspectbutton() {
      let aspect_keys = [];
      let aspect_values = {};
      let index_dt = 0;
      for (
        let index = 0;
        index < this.aspect_data[0].aspect_key.length;
        index++
      ) {
        aspect_keys.push({
          key_name: this.aspect_data[0].aspect_key[index].key_name,
          key_type: this.aspect_data[0].aspect_key[index].key_type,
        });
        let objname = this.aspect_data[0].aspect_key[index].key_name;
        // อาจจะต้องดักแค่อันที่ไม่ใช่ date
        // ตัดตัวใช้ if ทิ้ง
        // ให้ไปใช้ตัว else เลย
        if (
          this.aspect_data[0].aspect_key[index].key_type == "varchar" ||
          this.aspect_data[0].aspect_key[index].key_type == "dropdown"
        ) {
          Object.assign(aspect_values, {
            [objname]: this.aspect_data[0].aspect_key[index].select_data,
          });
        } else if (this.aspect_data[0].aspect_key[index].key_type == "date") {
          // แต่ละ บริษัท ใช้ keytype ไม่เหมือนกันต้องดักเพิ่ม
          // date docdate day days
          aspect_keys[index].key_type = "docdate";
          let aspect_search_date = {
            date_from: this.aspect_valuedatefrom[index_dt],
            date_to: this.aspect_valuedateto[index_dt],
          };
          Object.assign(aspect_values, { [objname]: aspect_search_date });
          index_dt += 1;
        } else {
          Object.assign(aspect_values, {
            [objname]: this.aspect_data[0].aspect_key[index].select_data,
          });
        }
      }
      console.log("aspect_values key and value", aspect_values);

      let payload = {
        account_id: this.dataAccountId,
        aspect_keys: aspect_keys,
        aspect_value: aspect_values,
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_UPLOAD_API + "/api/aspect_search",
          payload,
          { headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            // console.log(response);
            this.$emit("advancesearch", response);
          } else {
            // console.log("response.data != OK", response);
            this.$emit("advancesearch", response);
            console.log(response.data);
          }
          // ปิด v-menu
          this.advancesearch = false;
        })
        .catch((error) => {
          console.log("error aspect search", error);
        });
    },

    setAspectData() {
      this.aspect_data = this.aspectSearch || [
        { aspect: "False", aspect_key: [] },
      ];
      // console.log('aspectdata',this.aspect_data)
      let index_dt = 0;
      for (
        let index = 0;
        index < this.aspect_data[0].aspect_key.length;
        index++
      ) {
        // ถ้า key_type varchar กับ dropdrow ไม่เปลี่ยน
        // ให้ if date เพราะ date ไมรู้จะส่งมาแบบไหน varchar กับ dropdrow ให้เป็น else ปกติ
        if (this.aspect_data[0].aspect_key[index].key_type == "varchar") {
          this.aspect_data[0].aspect_key[index].select_data = "";
        } else if (this.aspect_data[0].aspect_key[index].key_type == "date") {
          //สำหรับระบุตำแหน่ง dialog date
          this.aspect_data[0].aspect_key[index].dialog_date_from = index_dt;
          this.aspect_data[0].aspect_key[index].dialog_date_to = index_dt;
          this.aspect_datefrom.push(false);
          this.aspect_dateto.push(false);
          this.aspect_valuedatefrom.push("");
          this.aspect_valuedateto.push("");
          index_dt += 1;
        } else if (
          this.aspect_data[0].aspect_key[index].key_type == "dropdown"
        ) {
          this.aspect_data[0].aspect_key[index].select_data = "";
        }
      }
      // console.log('Add key aspect data',this.aspect_data)
    },

    updateAspectData(index, value) {
      this.aspect_data[0].aspect_key[index].select_data = value;
    },

    changestorage_sidebar(_choosetypeuser) {
      console.log(_choosetypeuser);
      console.log("this.checkdefault", this.checkdefault);
      this.checkdefault = true;
      // let beforechoose = this.choosetypeuser;
      if (this.imageHeight <= 500 && _choosetypeuser !== undefined) {
        this.drawer = !this.drawer;
      } else if (this.imageHeight <= 500 && _choosetypeuser === undefined) {
        return _choosetypeuser;
      }
      if (_choosetypeuser.type === "Citizen") {
        this.checkbusiness = false;
        this.$store.dispatch("switchaccount_citizen").then((msg) => {
          console.log(msg);
          this.$emit("loadfile");
          this.$emit("callstorage");
          sessionStorage.removeItem("biz_active");
          this.$router.push({ name: "myfiles" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          this.checkbusiness = true;
          let payload;
          if (this.dataAccesstoken === "") {
            payload = {
              taxid: _choosetypeuser.data.id_card_num,
              user_id: this.dataUsername,
              biz_id: _choosetypeuser.data.id,
              branch_no: _choosetypeuser.data.branch_no,
            };
          } else {
            payload = {
              taxid: _choosetypeuser.data.id_card_num,
              accesstoken: this.dataAccesstoken,
              biz_id: _choosetypeuser.data.id,
              branch_no: _choosetypeuser.data.branch_no,
            };
          }

          this.$store
            .dispatch("switchaccount_business", payload)
            .then((msg) => {
              if (msg["status"] === "Business Access Denied") {
                this.checkbusiness = false;
                console.log("msg", msg);
                if (msg["msg"] === "ER401: Authorization is wrong") {
                  this.$router.push("logout");
                } else {
                  if (this.dataAccountActive["type"] === "Business") {
                    let type_business_index =
                      this.dataAccountActive["business_info"]["id"] === ""
                        ? this.typeuser.findIndex(
                            (x) =>
                              x.taxid === this.dataAccountActive["taxid"] &&
                              x.branch_no ===
                                this.dataAccountActive["business_info"][
                                  "branch_no"
                                ]
                          )
                        : this.typeuser.findIndex(
                            (x) =>
                              x.biz_id ===
                                this.dataAccountActive["business_info"]["id"] &&
                              x.branch_no ===
                                this.dataAccountActive["business_info"][
                                  "branch_no"
                                ]
                          );
                    console.log(type_business_index);
                    this.choosetypeuser = this.typeuser[type_business_index];
                    this.checkbusiness = false;
                    console.log("feature", this.feature_status);
                  } else {
                    this.choosetypeuser = {
                      type: "Citizen",
                      data: [],
                      nameth: "ผู้ใช้งานทั่วไป",
                      nameen: "Personal",
                      taxid: "",
                      biz_id: "",
                      business_id: "",
                    };
                  }
                  Toast.fire({ icon: "error", title: msg["msg"] });
                  // console.log("vvv",this.dataAccountActive.business_info["my_folder_id"]);
                  this.$emit("loadfile");
                  //this.$emit("loadfile_reload");
                  this.$emit("callstorage");
                  this.$router.push({ name: "myfiles" });
                  // this.$store.dispatch("switchaccount_citizen").then(msg => {
                  //   this.$emit("loadfile");
                  //   this.$emit("callstorage");
                  //   this.$router.push({ name: "myfiles" });
                  // });
                }
              } else {
                console.log("feature", this.feature_status);
                this.checkbusiness = true;
                this.choosetypeuser = _choosetypeuser;
                // console.log(msg);
                // this.$emit("loadfile");
                this.$emit("callstorage");
                console.log(
                  "vvv",
                  this.dataAccountActive.business_info["my_folder_id"]
                );
                if (this.$store.state.feature_inputmore === true) {
                  this.fn_gettamplate_smartupload()
                }

                this.$router.push({
                  path:
                    "/directory/" +
                    this.dataAccountActive.business_info["my_folder_id"],
                });

                this.$emit(
                  "loadfile",
                  this.dataAccountActive.business_info["my_folder_id"]
                );

                this.$emit("loadfile_reload");
                //this.$router.push({ name: "myfiles" });

                // localStorage.setItem('my_folder_id', CryptoJS.AES.encrypt(window.btoa(this.dataAccountActive.business_info["my_folder_id"]), CryptoJS.enc.Utf8.parse("8MV5r6121K8airUo9eI6TQjhXcM6pvFB"), { mode: CryptoJS.mode.ECB, }).toString());
                // console.log("my_folder_id set");
                this.fn_addemail_alertstorage();
              }
            });
        } else {
          this.$store
            .dispatch("switchaccount_businessonemail", _choosetypeuser)
            .then((msg) => {
              console.log(msg);
              this.$emit("loadfile");
              this.$emit("callstorage");
              this.$router.push({ name: "myfiles" });
            });
        }
      }
    },
    async fn_addemail_alertstorage() {
      console.log("fn_addemail_alertstorage");
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
      };
      console.log("payload fn_addemail_alertstorage", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API +
            "/api/check_email_alert_storage",
          payload,
          {
            headers: { Authorization: auth.code },
          }
        )
        .then((res) => {
          if (res.data.status == "OK") {
            console.log("เข้านี่จ้าา", res.data);
            if (res.data.alert === "Y") {
              VueCookies.set("check_alert_email", "Y");
            } else {
              if (res.data.admin_business_status === "True") {
                VueCookies.set("check_alert_email", "N");
              } else {
                VueCookies.set("check_alert_email", "Y");
              }
            }
          } else {
            Toast.fire({
              icon: "error",
              title: res.data.errorMessage,
            });
          }
        })
        .catch((err) => {
          console.log("==========", err);
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเชื่อมต่อ OneBox ได้ กรุณาติดต่อผู้ดูแลระบบ ",
          });
          this.loading = false;
        });
    },
    openfile() {
      console.log("rot", this.$router.currentRoute.path);
      if (this.$router.currentRoute.path === "/sharedwithme") {
        Toast.fire({
          icon: "error",
          title: this.$t("uploadfile.donotshare"),
        });
      } else {
        this.dialogupfile = false;
        (this.openUploadfiles = true), this.$emit("callparentfolder");
      }
    },

    openfolder() {
      if (this.$router.currentRoute.path === "/sharedwithme") {
        Toast.fire({
          icon: "error",
          title: this.$t("uploadfolder.donotshare"),
        });
      } else {
        this.dialogupfile = false;
        (this.openUploadfolder = true), this.$emit("callparentfolder");
      }
    },
    opencreatefolder() {
      if (this.$router.currentRoute.path === "/sharedwithme") {
        Toast.fire({
          icon: "error",
          title: this.$t("uploadfolder.donotshare"),
        });
      } else {
        this.dialogupfile = false;
        this.$emit("closeDrag");
        (this.openUploadfolderinput = true), this.$emit("callparentfolder");
      }
    },
    gotoinboxoutbox() {
      // let URL_inbox = "http://localhost:8080/myinboxetax";

      // window.open(URL_inbox, "_blank");
      // this.$router.replace("/myinboxetax");
      this.$router.push({ path: "/myinboxetax", query: { type: 2 } });
    },
    openfile_() {
      this.openUploadfiles = true;
      this.dialogupfile = false;
      this.$emit("callparentfolder");
    },
    openfolder_() {
      this.openUploadfolder = true;
      this.dialogupfile = false;
      this.$emit("callparentfolder");
    },

    openpro() {
      this.openprofile = true;
    },

    searchdata() {
      // if (this.typeSearch == "fulltextsearch") {
      //   this.$emit("fulltextsearch", {
      //     keyword: this.search,
      //     type: this.type_Fulltextsearch
      //   });
      // } else {
      //   this.$emit("inputdata", this.search);
      // }
    },
    async calladvancesearchdata() {
      this.errorSearch.status = false
      this.errorSearch.msg = ""
      // detect search | ,
      if(this.optionSearch.length === 0){
        this.optionSearch.push("foldername", "filename", "hashtag");
      }

      if(this.optionSearch.includes("hashtag")){
          if(this.search.includes(',') && this.search.includes('|')){ // ดักกรณี มีการใส่ , | พร้อมกัน
          console.log("deteced true ",this.optionSearch)
          this.errorSearch.status = true
          this.errorSearch.msg = "ในการค้นหาด้วยแฮชแท็ก โปรดใช้อักขระพิเศษอย่างใดอย่างหนึ่งจาก , หรือ |"
          if(!this.advancesearch){
            this.advancesearch = true
          }
          return
        }
        if(this.search){
          let splitAllItem = this.search.trim().split(/[,|]/)
          if(splitAllItem.find((item) => item === "") !== undefined){ // ถ้า undefined แสดงว่าเจอช่องว่างขอคำ 
            this.errorSearch.status = true
            this.errorSearch.msg = "ควรใช้แฮชแท็กตั้งแต่2แฮชแท็กขึ้นไป"
            if(!this.advancesearch){
            this.advancesearch = true
          }
            return
          }
        }
      }
      
      this.processdelete = true;
      this.path_folder_split = this.$router.currentRoute.path.split("/");
      this.path_folder = "";
      if (this.path_folder_split[1] === "directory") {
        this.path_folder = this.path_folder_split[2];
      } else if (this.path_folder_split[1] === "directorysharefile") {
        this.path_folder = this.path_folder_split[2];
      } else {
        if (this.dataAccountActive["type"] === "Business") {
          this.path_folder = this.dataAccountActive["business_info"][
            "my_folder_id"
          ];
        } else {
          this.path_folder = this.dataAccountActive["directory_id"];
        }
      }
      if (this.path_folder_split[1] === "directorysharefile" || this.path_folder_split[1] === "sharedwithme") {
        this.calladvancesearchdata_old();
        // this.listpayload = {
        //   account_id: this.dataAccountId,
        //   cre_dtm: formatDatetime.formatDateYYYYmmDD(this.fromdate, "from"),
        //   to_dtm: formatDatetime.formatDateYYYYmmDD(this.todate, "to"),
        //   type_search: this.optionSearch,
        //   name: this.search,
        //   cctv: this.dataAccountCCTV["status"],
        //   folder_id: this.path_folder,
        //   dept_id: this.departmentid,
  
        // };
      }else{
        this.listpayload = {
          account_id: this.dataAccountId,
          cre_dtm: formatDatetime.formatDateYYYYmmDD(this.fromdate, "from"),
          to_dtm: formatDatetime.formatDateYYYYmmDD(this.todate, "to"),
          type_search: this.optionSearch,
          name: this.search,
          cctv: this.dataAccountCCTV["status"],
          folder_id: this.specific_folder == true ? this.path_folder : "",
          dept_id: this.departmentid,
 
        };
     
      console.log("this.listpayload",this.listpayload);
      this.$emit("countoffset");
      this.$emit("search_advanced_new",this.listpayload);
      this.processdelete = false;
      this.advancesearch = false;
                      // $emit('loadfile'),
                      // (selectSearch = 'advance_search')
      }

    },
    async calladvancesearchdata_old() {
      let payload = {};
      let acc_id = "";

      if (this.dataAccountActive["type"] === "Business") {
        acc_id = this.dataAccountActive["business_info"]["business_id"];
      } else {
        acc_id = this.dataAccountId;
      }
      // console.log("this.optionSearch",this.optionSearch);
      console.log("rot", this.$router.currentRoute.path.split("/"));
      this.path_folder_split = this.$router.currentRoute.path.split("/");
      this.path_folder = "";
      if (this.path_folder_split[1] === "directory") {
        this.path_folder = this.path_folder_split[2];
      } else if (this.path_folder_split[1] === "directorysharefile") {
        this.path_folder = this.path_folder_split[2];
      } else {
        if (this.dataAccountActive["type"] === "Business") {
          this.path_folder = this.dataAccountActive["business_info"][
            "my_folder_id"
          ];
        } else {
          this.path_folder = this.dataAccountActive["directory_id"];
        }
      }

      if (this.path_folder_split[1] === "directorysharefile" || this.path_folder_split[1] === "sharedwithme") {
        payload = {
          account_id: this.dataAccountId,
          cre_dtm: formatDatetime.formatDateYYYYmmDD(this.fromdate, "from"),
          to_dtm: formatDatetime.formatDateYYYYmmDD(this.todate, "to"),
          type_search: this.optionSearch,
          name: this.search,
          cctv: this.dataAccountCCTV["status"],
          // folder_id: this.path_folder,
          folder_id: this.specific_folder === true && this.path_folder_split[1] === "sharedwithme" ? "root" : this.path_folder_split[1] === "sharedwithme" ? "" : this.path_folder,
          dept_id: this.departmentid,
        };
        console.log("fake payload", payload);
        this.processdelete = true;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              `/api/shared/advanced_search`,
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            console.log("advance search", response.data);
            if (response.data.status === "OK") {
              this.count_data_adv = response.data.data.total;
              console.log("this.count_data_adv",this.count_data_adv);
              this.$emit("count_data_ad", this.count_data_adv);
              this.$emit("advancesearch", response, this.search);
            } else {
              console.log("ERR");
              this.$emit("advancesearch", response, this.search);
            }
            this.advancesearch = false;
            this.processdelete = false;
          });
      } else {
        payload = {
          account_id: this.dataAccountId,
          cre_dtm: formatDatetime.formatDateYYYYmmDD(this.fromdate, "from"),
          to_dtm: formatDatetime.formatDateYYYYmmDD(this.todate, "to"),
          type_search: this.optionSearch,
          name: this.search,
          cctv: this.dataAccountCCTV["status"],
          folder_id: this.specific_folder == true ? this.path_folder : "",
          dept_id: this.departmentid,
          limit: cal_limit,
          offset: cal_offset,
        };
        console.log("fake payload", payload);
        this.processdelete = true;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_API +
              `/api/v2/advanced_search`,
            payload,
            {
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            console.log("advance search", response.data);
            if (response.data.status === "OK") {
              this.count_data_adv = response.data.total;
              console.log("this.count_data_adv",this.count_data_adv);
              this.$emit("count_data_ad", this.count_data_adv);
              this.$emit("advancesearch", response);
            } else {
              console.log("ERR");
              this.$emit("advancesearch", response);
            }
            this.advancesearch = false;
            this.processdelete = false;
          });
      }

    },

    // ตั้ง icon file/folder
    seticon(parameter) {
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word-outline", "primary"];
      } else if (parameter === "zip") {
        dataicon = ["mdi-zip-box-outline", "blue-grey"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    sortedArray(_rawdata) {
      function compare(a, b) {
        if (localStorage.getItem("lang") === "en") {
          // decimal code ของ A กับ a ไม่เท่ากัน
          if (a.nameen.toUpperCase() < b.nameen.toUpperCase()) {
            return -1;
          } else {
            return 1;
          }
        } else if (localStorage.getItem("lang") === "th") {
          if (a.nameth < b.nameth) {
            return -1;
          } else {
            return 1;
          }
        }
      }
      console.log("compare", _rawdata.sort(compare));
      return _rawdata.sort(compare);
    },
    dataTypeUser() {
      console.log("create dataTypeUser", localStorage.getItem("lang"));
      this.typeuser = [];
      // console.log(this.$store.state.service);
      // console.log("VueCookies",VueCookies.get("oneplatfrom"));
      let check_service_oneplatfrom_ = VueCookies.get("oneplatfrom");
      let check_citizen_oneplat_ = VueCookies.get("check_citizen_oneplat");
      console.log("check_service_oneplatfrom",this.check_service_oneplatfrom);
      if(check_service_oneplatfrom_ !== "true"){
      if (
        this.$store.state.service !== "OneMail" &&
        this.$store.state.service !== "OneMail_OneID"
      ) {
        let _citizen = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal",
          taxid: "",
          biz_id: "",
          business_id: "",
        };

        // this.typeuser.push(_citizen);
      } else {
        // if (this.dataBusinessProfile.length === 0) {
        let _citizen = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal",
          taxid: "",
          branch_no: "",
          biz_id: "",
          business_id: "",
        };
        // this.typeuser.push(_citizen);
        // }
      }
      }
    
    if(check_citizen_oneplat_ === 'true'){
        let _citizen = {
            type: "Citizen",
            data: [],
            nameth: "ผู้ใช้งานทั่วไป",
            nameen: "Personal",
            taxid: "",
            branch_no: "",
            biz_id: "",
            business_id: "",
          };
          // this.typeuser.push(_citizen);
      }
      console.log("typeuser", this.typeuser);
      let rawtypeuser = [];
      this.dataloginbusiness = [];
      this.dataloginbusiness.push({
        business_id: "f04d153beeae498c351a4acd83c9989a",
        tax_id: "0107559000061",
        first_name_eng: "SiamEast Solutions Public Company Limited",
        first_name_th: "สยามอีสต์ โซลูชั่น จำกัด (มหาชน)",
        branch_no: "00001"
      });
      console.log("this.dataloginbusiness",this.dataloginbusiness);
      let i = 0;
      for (i = 0; i < this.dataBusinessProfile.length; i++) {
        // let dataIndex = this.dataloginbusiness.findIndex(x => x.tax_id === this.dataBusinessProfile[i]["id_card_num"]);
        let dataIndex = this.dataloginbusiness.findIndex(x => x.tax_id === this.dataBusinessProfile[i].id_card_num);
        console.log("dataIndex2222",dataIndex);
        if(dataIndex != -1){
        if (this.$store.state.service !== "OneMail") {
          rawtypeuser.push({
            type: "Business",
            data: this.dataBusinessProfile[i],
            nameth: this.dataBusinessProfile[i]["first_name_th"],
            nameen: this.dataBusinessProfile[i]["first_name_eng"],
            taxid: this.dataBusinessProfile[i]["id_card_num"],
            biz_id: this.dataBusinessProfile[i]["id"],
            business_id: this.dataBusinessProfile[i]["business_id"],
            branch_no: this.dataBusinessProfile[i]["branch_no"],
          });
        } else {
          rawtypeuser.push({
            type: "Business",
            data: this.dataBusinessProfile[i],
            nameth: this.dataBusinessProfile[i]["first_name_th"],
            nameen: this.dataBusinessProfile[i]["first_name_eng"],
            taxid: this.dataBusinessProfile[i]["taxid"],
            biz_id: this.dataBusinessProfile[i]["id"],
            business_id: this.dataBusinessProfile[i]["business_id"],
            branch_no: this.dataBusinessProfile[i]["branch_no"],
          });
        }
       }
      }
      rawtypeuser = this.sortedArray(rawtypeuser);
      // console.log('rawtypeuser sort',rawtypeuser)
      this.typeuser = this.typeuser.concat(rawtypeuser);
      console.log("this.typeuser", this.typeuser);
      // console.log('after sort',this.typeuser)
      console.log(this.choosetypeuser);
      //  if(this.dataLoginDefault["account_category"] === "Business" && this.checkdefault === false){
      //    this.checkbusiness = true;
      //       let payload = {
      //         taxid: this.dataLoginDefault.id_card_num,
      //         accesstoken: this.dataAccesstoken,
      //         biz_id:this.dataLoginDefault.id

      //       };
      //    this.$store.dispatch("switchaccount_business", payload).then((msg) => {
      //         if (msg["status"] === "Business Access Denied") {
      //           this.checkbusiness = false;
      //           console.log("msg", msg);
      //           if (msg["msg"] === "ER401: Authorization is wrong") {
      //             this.$router.push("logout");
      //           } else {
      //             if (this.dataAccountActive["type"] === "Business") {
      //               let type_business_index = this.typeuser.findIndex((x) => x.id === this.dataAccountActive["business_info"]["id"]);
      //               this.choosetypeuser = this.typeuser[type_business_index];
      //               this.checkbusiness = false;
      //               console.log("feature", this.feature_status);
      //             } else {
      //               this.choosetypeuser = {
      //                 type: "Citizen",
      //                 data: [],
      //                 nameth: "ผู้ใช้งานทั่วไป",
      //                 nameen: "Personal",
      //               };
      //             }
      //             Toast.fire({ icon: "error", title: msg["msg"] });
      //             this.$emit("loadfile");
      //             this.$emit("callstorage");
      //             console.log("เข้าอันนี้");
      //             //this.$router.push({ link: "/directory/" + this.dataLoginDefault["my_folder_id"] });
      //             this.$router.push({ name: "myfiles" });
      //             // this.$store.dispatch("switchaccount_citizen").then(msg => {
      //             //   this.$emit("loadfile");
      //             //   this.$emit("callstorage");
      //             //   this.$router.push({ name: "myfiles" });
      //             // });
      //           }
      //         } else {
      //           console.log("feature", this.feature_status);
      //           this.checkbusiness = true;
      //           let objIndex = this.typeuser.findIndex((obj) => obj.biz_id === this.dataLoginDefault.id);
      //           this.choosetypeuser = this.typeuser[objIndex]
      //           // console.log(msg);
      //           this.$emit("loadfile");
      //           this.$emit("callstorage");
      //           console.log("/directory/");
      //           this.$router.push({ path: "/directory/" + this.dataLoginDefault["my_folder_id"] });
      //           //this.$router.push({ name: "myfiles" });
      //         }
      //       });
      //  }
      if (this.dataAccountActive["type"] === "Business") {
        let type_business_index = -1;
        if (this.$store.state.service !== "OneMail") {
          type_business_index =
            this.dataAccountActive["business_info"]["id"] === ""
              ? this.typeuser.findIndex(
                  (x) =>
                    x.taxid === this.dataAccountActive["taxid"] &&
                    x.branch_no ===
                      this.dataAccountActive["business_info"]["branch_no"]
                )
              : this.typeuser.findIndex(
                  (x) =>
                    x.biz_id ===
                      this.dataAccountActive["business_info"]["id"] &&
                    x.branch_no ===
                      this.dataAccountActive["business_info"]["branch_no"]
                );
        } else {
          type_business_index = this.typeuser.findIndex(
            (x) =>
              x.taxid === this.dataAccountActive["taxid"] &&
              x.branch_no ===
                this.dataAccountActive["business_info"]["branch_no"]
          );
        }
        this.choosetypeuser = this.typeuser[type_business_index];
        this.checkbusiness = true;
      } else {
        this.choosetypeuser = {
          type: "Citizen",
          data: [],
          nameth: "ผู้ใช้งานทั่วไป",
          nameen: "Personal",
          biz_id: "",
          taxid: "",
          business_id: "",
        };
      }
    },

    changestorage(typeuser, business) {
      if (typeuser === "Citizen") {
        this.checkbusiness = false;
        this.$store.dispatch("switchaccount_citizen").then((msg) => {
          console.log(msg);
          this.$emit("loadfile");
          this.$emit("callstorage");
          this.$router.push({ name: "myfiles" });
        });
      } else {
        if (this.$store.state.service !== "OneMail") {
          this.checkbusiness = true;
          let payload = {
            taxid: business.id_card_num,
            accesstoken: this.dataAccesstoken,
          };
          this.$store
            .dispatch("switchaccount_business", payload)
            .then((msg) => {
              // console.log(msg);
              if (msg["status"] === "Business Access Denied") {
                if (msg["msg"] === "ER401: Authorization is wrong") {
                  this.$router.push("logout");
                } else {
                  Toast.fire({ icon: "error", title: msg["msg"] });
                }
              } else {
                // console.log(msg);
                this.$emit("loadfile");
                this.$emit("callstorage");
                this.$router.push({ name: "myfiles" });
              }
            });
        } else {
          this.$store
            .dispatch("switchaccount_businessonemail", typeuser)
            .then((msg) => {
              console.log(msg);
              this.$emit("loadfile");

              this.$emit("callstorage");
              this.$router.push({ name: "myfiles" });
            });
        }
      }
    },

    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
      this.dataTypeUser();
    },

    changecolor(parameter, parameter_code) {
      console.log("color", parameter, parameter_code);
      // console.log(this.colors_.hsla["h"],this.colors_.hsla["s"],this.colors_.hsla["l"],this.colors_.hsla["a"]);
      //let color_code = "hsla("+String(this.colors_.hsla["h"])+","+String(this.colors_.hsla["s"]*100)+"%,"+String(99.99)+"%,"+String(this.colors_.hsla["a"]*100)+"%)"
      if (parameter === undefined || parameter === null) {
        console.log("if");
        localStorage.setItem("color", "#174966");
        localStorage.setItem("color_code", "hsla(202, 63%, 97%, 1)");
        localStorage.setItem("color_code_temp", "hsla(202, 63%, 97%, 1)");
      } else {
        localStorage.setItem("color_code_temp", parameter_code);
        if (this.imageHeight >= 500) {
          localStorage.setItem(
            "color_code",
            this.$vuetify.theme.dark ? "#333333" : parameter_code
          );
        } else {
          // Background Mobile
          localStorage.setItem(
            "color_code",
            this.$vuetify.theme.dark ? "#636363" : parameter_code
          );
        }
        localStorage.setItem("color", parameter);
        //this.$store.dispatch("check_color",localStorage.getItem("color")).then(res => {});
      }
      this.$store
        .dispatch("check_color", localStorage.getItem("color"))
        .then((res) => {});
    },

    gotoAdmin(choosetypeuser) {
      // console.log("ddddfdfdddf", choosetypeuser);
      let payload = {
        account_id: this.dataAccountId,
      };
      this.$store
        .dispatch("authorize_login_admin_business", payload)
        .then((msg) => {
          // console.log(msg);
          if (msg["status"] === "Login Admin Business Access Denied") {
            // if (msg["msg"] === "ER401: Authorization is wrong") {
            //   this.$router.push("logout");
            // } else {
            //   Toast.fire({ icon: "error", title: msg["msg"] });
            // }
            console.log("Login Admin Business Access Denied");
            Toast.fire({ icon: "error", title: msg["msg"] });
          } else {
            console.log("login success");
            //this.$emit("loadfile");
            //this.$emit("callstorage");
            // this.$router.push({ path: "/system-admin-index" });
            this.$router.push({ path: "/onebox-system-admin-index" });
          }
        });
    },
    // gotoAdminonebox() {
    //   this.$router.push({ path: "/adminonebox" });
    // },
    async tolink_inputmore() {
      this.opensmartupload = true;
      // let payload = {
      //   accesstoken: this.dataAccesstoken
      // }
      // let shared_token = ""
      // await this.axios
      // .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/getsharetoken_from_accesstoken", payload)
      // .then((response) => {
      //   return new Promise((resolve, reject) => {
      //     console.log("response",response);          
      //     if (response.data.status === "OK") {
      //       shared_token = response.data.shared_token;
      //       window.location.href = process.env.VUE_APP_DISIN + shared_token;
      //       // window.location.href = "https://demo-inputmore.inet.co.th/landing?shared_token=" + shared_token;
      //     }
      //   });
      // })
      // .catch((err) => {})
    },
    tolink(parameter, text) {
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      if (text === "toolbar.myinbox.Personalname") {
        this.$router.push({ path: parameter, query: { type: "1" } });
      } else if (text === "toolbar.myinbox.Businessname") {
        this.$router.push({ path: parameter, query: { type: "2" } });
      } else if (text === "toolbar.myoutbox.Businessname") {
        this.$router.push({ path: parameter, query: { type: "2" } });
      } else if (text === "toolbar.myoutbox.Personalname") {
        this.$router.push({ path: parameter, query: { type: "1" } });
      } else {
        this.$router.push(parameter);
      }
    },
    tolink_parent(parameter, text) {
      if (this.imageHeight <= 500) {
        this.drawer = !this.drawer;
      }
      this.navbarcss_click = {
        background: "rgba(246, 247, 250, 1)",
      };
      console.log("tolink_parent");
      if (text === "toolbar.mydrive_bu") {
        this.$router.push(
          "/directory/" +
            this.dataAccountActive["business_info"]["my_folder_id"]
        );
        this.$emit("loadfile");
      }
      this.$router.push(parameter);
    },

    linktomenu(parameter, choosetypeuser) {
      console.log(parameter);
      if (parameter.key === "profile") {
        this.openpro();
      } else if (parameter.key === "personal-log") {
        // this.$router.push({ path: "/system-personal-log" });
        this.$router.push({ path: "/onebox-system-personal-log" });
      } else if (parameter.key === "admin") {
        this.gotoAdmin(choosetypeuser);
        // } else if (parameter.key === "consent") {
        //   this.$router.push({ path: "/consent" });
      } else if (parameter.key === "cctv") {
        window.open("https://cctv.thssoft.com/member/login/");
      } else if (parameter.key === "package") {
        this.$router.push({ path: "/packagenew" });
      } else if (parameter.key === "package_citizen") {
        this.$router.push({ path: "/packagecurrent" });
      } else if (parameter.key === "listpay") {
        this.$router.push({ path: "/packagelistpay" });
      } else if (parameter.key === "registerbusiness") {
        this.openregisterbusiness = true;
      } else if (parameter.key === "token") {
        this.opendialog_getToken = true
      } else {
        let username_show = this.decryptdata(VueCookies.get("username"))
        if(username_show === process.env.VUE_APP_ACCOUNT_BOT){
          this.closedialogOtpexpired()
        } else {
          // this.$router.push({ path: "/logout" });
          this.$store
          .dispatch("change_default_logo", "")
          .then((msg) => {
            if(msg.status === 'OK'){
              this.$router.push({ path: "/logout" });
            }
          })
          .catch(()=>{ // เผื่อๆ
            this.$router.push({ path: "/logout" });
          })
        }
      }
    },

    closeDialog() {
      this.openUploadfiles = false;
      this.openUploadfolder = false;
      this.openColorpicker = false;
      console.log("this.dialogupfile", this.dialogupfile);
      if (this.dialogupfile === true) {
        this.$emit("loadfile");
      }
    },
    listinputmore() {
      let _items = [];
      if (this.dataAccountActive["type"] === "Business") {
        if (this.feature_inputmore) {
          _items.push({
            active: true,
            icon: "mdi-upload",
            text: "toolbar.specialupload",
            link: "",
            disable: false,
            key: "inputmore",
            color: this.color.theme || "primary",
          })
        }
      }
      return _items;
    },
    listmenu() {
      //  console.log("admin",this.role_level);
      // console.log("feature_status",this.feature_status);
      let _items = [];
      // console.log("type",this.dataAccountActive["type"]);
      // console.log("permissioninout",this.status_permission);

      if (this.dataAccountActive["type"] === "Business") {
        if (
          this.status_permission_inbox === "DE" &&
          this.status_permission_outbox === "AL"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //   // dashboard
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardinbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     // {
            //     //   // icon: "business",
            //     // icon: "mdi-office-building",
            //     //   text: "toolbar.myinbox.Businessname", ///////
            //     //   link: "/myinbox-system",
            //     //   type: 2,
            //     // },
            //     // {
            //     //   icon: "delete_sweep",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardoutbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "delete_sweep",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "DE" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardinbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     // {
            //     //   // icon: "business",
            //     // icon: "mdi-office-building",
            //     //   text: "toolbar.myinbox.Businessname", ///////
            //     //   link: "/myinbox-system",
            //     //   type: 2,
            //     // },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myoutbox.Trashoutbox",
            //       link: "/trashoutbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "DE" &&
          this.status_permission_outbox === "DE"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     // {
            //     //   // icon: "business",
            //     // icon: "mdi-office-building",
            //     //   text: "toolbar.myinbox.Businessname", ///////
            //     //   link: "/myinbox-system",
            //     //   type: 2,
            //     // },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     // {
            //     //   // icon: "business",
            //     // icon: "mdi-office-building",
            //     //   text: "toolbar.myoutbox.Businessname", //////
            //     //   link: "/myoutbox-system",
            //     //   type: 2,
            //     // },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" &&
          this.status_permission_outbox === "DE"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardinbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardoutbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     // {
            //     //   // icon: "business",
            //     // icon: "mdi-office-building",
            //     //   text: "toolbar.myoutbox.Businessname", //////
            //     //   link: "/myoutbox-system",
            //     //   type: 2,
            //     // },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === "DE"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardinbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myinbox.Trashinbox",
            //       link: "/trashinbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardoutbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     // {
            //     //   // icon: "business",
            //     // icon: "mdi-office-building",
            //     //   text: "toolbar.myoutbox.Businessname", //////
            //     //   link: "/myoutbox-system",
            //     //   type: 2,
            //     // },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardinbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // inbox123
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myinbox.Trashinbox",
            //       link: "/trashinbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "date_range",
            //     //   text: "ไฟล์อินบ๊อกซ์หมดอายุ",
            //     //   link: "/fileexpiredinbox",
            //     //   type: 4,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardoutbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // outbox123
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myoutbox.Trashoutbox",
            //       link: "/trashoutbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "date_range",
            //     //   text: "ไฟลเอาท์บ๊อกซ์หมดอายุ",
            //     //   link: "/fileexpiredoutbox",
            //     //   type: 4,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" &&
          this.status_permission_outbox === "AL"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardinbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   color: this.color.theme || "primary",
            //     //   type: 1,
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "dashboard_inboxoutbox",
            //     //   link: "/dashboardoutbox",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     //   disable: true,
            //     // },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === "AL"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myinbox.Trashinbox",
            //       link: "/trashinbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myoutbox.Trashoutbox",
            //       link: "/trashoutbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === "DL"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myinbox.Trashinbox",
            //       link: "/trashinbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     }
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "DL" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // outbox123
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myoutbox.Trashoutbox",
            //       link: "/trashoutbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if ( 
          this.status_permission_inbox === "DL" &&
          this.status_permission_outbox === "AL" || 
          this.status_permission_inbox === 'AL' && 
          this.status_permission_outbox === 'DL' || 
          this.status_permission_inbox === 'DL' && 
          this.status_permission_outbox === 'DL'
        ){  
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "DL" &&
          this.status_permission_outbox === "DE"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   }
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "DE" &&
          this.status_permission_outbox === "DL"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   }
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AL" || this.status_permission_inbox === "DL" &&
          this.status_permission_outbox === ""
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "AD" &&
          this.status_permission_outbox === ""
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myinbox.Trashinbox",
            //       link: "/trashinbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "" &&
          this.status_permission_outbox === "AL" || this.status_permission_inbox === "DL"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   color: this.color.theme || "primary",
            //   model: false,
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else if (
          this.status_permission_inbox === "" &&
          this.status_permission_outbox === "AD"
        ) {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     {
            //       icon: "mdi-trash-can",
            //       text: "toolbar.myoutbox.Trashoutbox",
            //       link: "/trashoutbox",
            //       type: 3,
            //       color: this.color.theme || "primary",
            //     },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        } else {
          _items = [
            {
              active: true,
              icon: "location_city",
              "icon-alt": "keyboard_arrow_down",
              text: "toolbar.mydrive_bu",
              link:
                "/directory/" +
                this.dataAccountActive["business_info"]["my_folder_id"],
              disable: false,
              key: this.feature_status.my_drive_bu,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              // icon: "mdi-cloud-outline",
              icon: "mdi-cloud",
              text: "toolbar.mydrive",
              link: "/mydrive",
              disable: false,
              key: this.feature_status.my_drive,
              color: this.color.theme || "primary",
            },

            {
              active: true,
              icon: "mdi-star",
              text: "toolbar.mystarfile",
              link: "/starred",
              disable: this.disableByPackage,
              key: this.feature_status.file_s,
              color: this.color.theme || "primary",
            },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-down",
            //   icon: "mdi-inbox-arrow-down",
            //   "icon-alt": "mdi-inbox-arrow-down",
            //   text: "toolbar.myinbox.Parentname",
            //   key: this.feature_status.inbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardinbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myinbox.Personalname",
            //     //   link: "/myinbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myinbox.Businessname", ///////
            //       link: "/myinbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myinbox.Trashinbox",
            //     //   link: "/trashinbox",
            //     //   type: 3
            //     // }
            //   ],
            // },
            // {
            //   active: true,
            //   prependicon: "mdi-inbox-arrow-up",
            //   icon: "mdi-inbox-arrow-up",
            //   "icon-alt": "mdi-inbox-arrow-up",
            //   text: "toolbar.myoutbox.Parentname",
            //   key: this.feature_status.outbox,
            //   model: false,
            //   color: this.color.theme || "primary",
            //   children: [
            //   // {
            //   //     icon: "mdi-account-tie",
            //   //     text: "dashboard_inboxoutbox",
            //   //     link: "/dashboardoutbox",
            //   //     type: 1,
            //   //     color: this.color.theme || "primary",
            //   //     disable: true,
            //   //   },
            //     // {
            //     //   icon: "mdi-account-tie",
            //     //   text: "toolbar.myoutbox.Personalname",
            //     //   link: "/myoutbox-system",
            //     //   type: 1,
            //     //   color: this.color.theme || "primary",
            //     // },
            //     {
            //       // icon: "business",
            //       icon: "mdi-office-building",
            //       text: "toolbar.myoutbox.Businessname", //////
            //       link: "/myoutbox-system",
            //       type: 2,
            //       color: this.color.theme || "primary",
            //     },
            //     // {
            //     //   icon: "mdi-trash-can",
            //     //   text: "toolbar.myoutbox.Trashoutbox",
            //     //   link: "/trashoutbox",
            //     //   type: 3,
            //     // },
            //   ],
            // },
            // {
            //   active: true,
            //   // icon: "mdi-folder-account-outline",
            //   icon: "mdi-folder-account",
            //   text: "toolbar.mysharefile",
            //   link: "/sharedwithme",
            //   disable: false,
            //   key: this.feature_status.shared,
            //   color: this.color.theme || "primary",
            // },
            {
              active: true,
              icon: "mdi-folder-clock",
              text: "toolbar.expiredfile",
              link: "/fileexpired",
              disable: this.disableByPackage,
              color: this.color.theme || "primary",
            },
            {
              active: true,
              icon: "mdi-trash-can",
              text: "toolbar.mytrash",
              link: "/trash",
              disable: false,
              key: this.feature_status.trash,
              color: this.color.theme || "primary",
            },
          ];
        }
      } else {
        ////////////////////////////////////////////
        _items = [
          {
            active: true,
            // icon: "mdi-cloud-outline",
            icon: "mdi-cloud",
            text: "toolbar.mydrive",
            link: "/mydrive",
            disable: false,
            key: this.feature_status.my_drive,
            color: this.color.theme || "primary",
          },
          //     {
          //   active: true,
          //   icon: "mdi-cloud",
          //   "icon-alt": "keyboard_arrow_down",
          //   text: "toolbar.mydrive_bu",
          //   link: "/directory/" + this.dataLoginDefault["my_folder_id"],
          //   disable: false,
          //   key: this.feature_status.my_drive_bu,
          // },
          {
            active: true,
            icon: "mdi-star",
            text: "toolbar.mystarfile",
            link: "/starred",
            disable: this.disableByPackage,
            key: this.feature_status.file_s,
            color: this.color.theme || "primary",
          },
          // {
          //   active: true,
          //   prependicon: "mdi-inbox-arrow-down",
          //   icon: "mdi-inbox-arrow-down",
          //   "icon-alt": "mdi-inbox-arrow-down",
          //   text: "toolbar.myinbox.Parentname",
          //   key: this.feature_status.inbox,
          //   model: false,
          //   color: this.color.theme || "primary",
          //   children: [
          //     {
          //       icon: "mdi-account-tie",
          //       text: "toolbar.myinbox.Personalname",
          //       link: "/myinbox-system",
          //       // link: "/myinbox-system-citizen",
          //       type: 1,
          //       color: this.color.theme || "primary",
          //     },
          //     {
          //         icon: "mdi-trash-can",
          //         text: "toolbar.myinbox.Trashinbox",
          //         link: "/trashinbox",
          //         type: 3,
          //         color: this.color.theme || "primary",
          //       },
          //   ],
          // },
          // {
          //   active: true,
          //   prependicon: "mdi-inbox-arrow-up",
          //   icon: "mdi-inbox-arrow-up",
          //   "icon-alt": "mdi-inbox-arrow-up",
          //   text: "toolbar.myoutbox.Parentname",
          //   key: this.feature_status.outbox,
          //   model: false,
          //   color: this.color.theme || "primary",
          //   children: [
          //     {
          //       icon: "mdi-account-tie",
          //       text: "toolbar.myoutbox.Personalname",
          //       link: "/myoutbox-system",
          //       type: 1,
          //       color: this.color.theme || "primary",
          //     },
          //     {
          //         icon: "mdi-trash-can",
          //         text: "toolbar.myoutbox.Trashoutbox",
          //         link: "/trashoutbox",
          //         type: 3,
          //         color: this.color.theme || "primary",
          //       },
          //   ],
          // },
          // {
          //   active: true,
          //   // icon: "mdi-folder-account-outline",
          //   icon: "mdi-folder-account",
          //   text: "toolbar.mysharefile",
          //   link: "/sharedwithme",
          //   key: this.feature_status.shared,
          //   disable: false,
          //   color: this.color.theme || "primary",
          // },
          {
            active: true,
            icon: "mdi-folder-clock",
            text: "toolbar.expiredfile",
            link: "/fileexpired",
            disable: this.disableByPackage,
            color: this.color.theme || "primary",
          },
          {
            active: true,
            icon: "mdi-trash-can",
            text: "toolbar.mytrash",
            link: "/trash",
            key: this.feature_status.trash,
            disable: false,
            color: this.color.theme || "primary",
          },
        ];
      }
      let filterFeature = [];
      if (this.feature_status !== undefined) {
        filterFeature = _items.filter(this.checkfeature);
        // console.log("new1",filterFeature);
      }
      return filterFeature;
    },

    listitem() {
      let _items = [];
      console.log("admin1", this.role_level);
      if (this.dataAccountActive["type"] === "Business") {
        if (this.$store.state.service === "OneMail") {
          if (this.role_level === "True") {
            _items = [
              {
                active: true,
                text: "admintoolbar.admin",
                icon: "apartment",
                link: "/onebox-system-admin-dashboard",
                // link: "/system-admin-dashboard",
                disable: false,
                key: "admin",
              },
              // {
              //   active: true,
              //   icon: "mdi-bag-checked",
              //   text: "consentheader",
              //   link: "",
              //   disable: false,
              //   key: "consent",
              // },
              {
                active: true,
                icon: "mdi-account-circle",
                text: "Personal",
                link: "",
                disable: false,
                key: "profile",
              },
              {
                active: true,
                icon: "mdi-key",
                text: "Onebox Token",
                link: "",
                disable: false,
                key: "token",
              }
              // ใช้
              // {
              //   active: true,
              //   icon: "mdi-shopping",
              //   text: "admintoolbar.buystorage",
              //   link: "",
              //   disable: false,
              //   key: "package",
              // },
              // {
              //   active: true,
              //   icon: "shopping_cart",
              //   text: "admintoolbar.paymentlist",
              //   link: "",
              //   disable: false,
              //   key: "listpay",
              // },
              // {
              //   active: true,
              //   icon: "mdi-logout",
              //   text: "logout",
              //   link: "",
              //   disable: false,
              //   key: "logout",
              // },
            ];
          } else {
            _items = [
              // {
              //   active: true,
              //   icon: "mdi-bag-checked",
              //   text: "consentheader",
              //   link: "",
              //   disable: false,
              //   key: "consent",
              // },
              {
                active: true,
                icon: "mdi-account-circle",
                text: "Personal",
                link: "",
                disable: false,
                key: "profile",
              },
              {
                active: true,
                icon: "mdi-key",
                text: "Onebox Token",
                link: "",
                disable: false,
                key: "token",
              }
              // ไม่ใช่ Admin
              // {
              // active: true,
              // icon: "mdi-shopping",
              // text: "admintoolbar.buystorage",
              // link: "",
              // disable: false,
              // key: "package",
              // },
              // {
              // active: true,
              // icon: "shopping_cart",
              // text: "admintoolbar.paymentlist",
              // link: "",
              // disable: false,
              // key: "listpay",
              // },
              // {
              //   active: true,
              //   icon: "mdi-logout",
              //   text: "logout",
              //   link: "",
              //   disable: false,
              //   key: "logout",
              // },
            ];
          }
        } else {
          if (this.role_level === "True") {
            _items = [
              // {
              //   active: true,
              //   icon: "mdi-city",
              //   text: "admintoolbar.register",
              //   link: "",
              //   disable: false,
              //   key: "registerbusiness",
              // },
              {
                active: true,
                text: "admintoolbar.admin",
                icon: "apartment",
                // link: "/system-admin-dashboard",
                link: "/onebox-system-admin-dashboard",
                disable: false,
                key: "admin",
              },
              // {
              //   active: true,
              //   icon: "mdi-bag-checked",
              //   text: "consentheader",
              //   link: "",
              //   disable: false,
              //   key: "consent",
              // },
              {
                active: true,
                icon: "mdi-account-circle",
                text: "Personal",
                link: "",
                disable: false,
                key: "profile",
              },
              {
                active: true,
                icon: "mdi-key",
                text: "Onebox Token",
                link: "",
                disable: false,
                key: "token",
              },
              // ใช้
              // {
              //   active: true,
              //   icon: "mdi-shopping",
              //   text: "admintoolbar.buystorage",
              //   link: "",
              //   disable: false,
              //   key: "package",
              // },
              // {
              //   active: true,
              //   icon: "shopping_cart",
              //   text: "admintoolbar.paymentlist",
              //   link: "",
              //   disable: false,
              //   key: "listpay",
              // },
              {
                active: true,
                icon: "mdi-logout",
                text: "logout",
                link: "",
                disable: false,
                key: "logout",
              },
            ];
          } else {
            _items = [
              // {
              //   active: true,
              //   icon: "mdi-city",
              //   text: "admintoolbar.register",
              //   link: "",
              //   disable: false,
              //   key: "registerbusiness",
              // },
              // {
              //   active: true,
              //   icon: "mdi-bag-checked",
              //   text: "consentheader",
              //   link: "",
              //   disable: false,
              //   key: "consent",
              // },
              {
                active: true,
                icon: "mdi-account-circle",
                text: "Personal",
                link: "",
                disable: false,
                key: "profile",
              },
              {
                active: true,
                icon: "mdi-key",
                text: "Onebox Token",
                link: "",
                disable: false,
                key: "token",
              },
              // ไม่ใช่ admin
              // {
              // active: true,
              // icon: "mdi-shopping",
              // text: "admintoolbar.buystorage",
              // link: "",
              // disable: false,
              // key: "package",
              // },
              // {
              // active: true,
              // icon: "shopping_cart",
              // text: "admintoolbar.paymentlist",
              // link: "",
              // disable: false,
              // key: "listpay",
              // },
              {
                active: true,
                icon: "mdi-logout",
                text: "logout",
                link: "",
                disable: false,
                key: "logout",
              },
            ];
          }
        }
      } else {
        _items = [
          // {
          //     active: true,
          //     icon: "mdi-bag-checked",
          //     text: "consentheader",
          //     link: "",
          //     disable: false,
          //     key: "consent",
          //   },
          {
            active: true,
            icon: "mdi-city",
            text: "admintoolbar.register",
            link: "",
            disable: false,
            key: "registerbusiness",
          },
          {
            active: true,
            icon: "mdi-account-circle",
            text: "Personal",
            link: "",
            disable: false,
            key: "profile",
          },
          {
            active: true,
            icon: "mdi-key",
            text: "Onebox Token",
            link: "",
            disable: false,
            key: "token",
          },
          {
            active: true,
            icon: "mdi-file-table-box-multiple",
            text: "admintoolbar.log",
            link: "",
            disable: false,
            key: "personal-log",
          },
          // ใช้
          // {
          //   active: true,
          //   icon: "mdi-shopping",
          //   text: "admintoolbar.buystorage",
          //   link: "",
          //   disable: false,
          //   key: "package_citizen",
          // },
          // {
          //   active: true,
          //   icon: "shopping_cart",
          //   text: "admintoolbar.paymentlist",
          //   link: "",
          //   disable: false,
          //   key: "listpay",
          // },
          {
            active: true,
            icon: "mdi-logout",
            text: "logout",
            link: "",
            disable: false,
            key: "logout",
          },
        ];
      }
      return _items;
    },
    listcctv() {
      let _items = [];
      console.log("cctv", this.dataAccountCCTV["status"]);
      if (this.dataAccountCCTV["status"] === true) {
        _items = [
          {
            active: true,
            text: "toolbar.cctv",
            icon: "mdi-cctv",
            link: "",
            disable: false,
            key: "cctv",
          },
        ];
      } else {
        _items = [];
      }
      return _items;
    },
    checkfeature(_item) {
      // console.log("1",_item.key);
      return _item.key !== "N";
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("darkmode", this.$vuetify.theme.dark.toString());
      this.changecolor(
        localStorage.getItem("color"),
        localStorage.getItem("color_code_temp")
      );
    },
    fn_loadfile(){
      if(this.heardertextsearch === true){
        this.$emit('loadfile');
      }
    }
  },
  watch: {
    aspectSearch(val) {
        console.log("Watch aspect_data", val);
        this.setAspectData();
    },
    type_Fulltextsearch: function() {
      // if (val == "folder") {
      //   this.disabledatesearch = true;
      //   this.$emit("loadfile");
      // } else {
      this.disabledatesearch = false;
      // }
    },
    typeSearch: function(val) {
      if (val == "keywordsearch") {
        this.$emit("loadfile");
      }
    },
    permissionfolder: function(val) {
      console.log("val", val);
      console.log(
        "this.permission_denied_account",
        this.permission_denied_account
      );
      if (val["upload"] == "False" || val === "01" || val === "02") {
        this.permission_denied_account = true;
      } else {
        this.permission_denied_account = false;
      }
    },
    checkroutername: function() {
      this.advancesearch = false;
    },
    '$route.params.id': { // เอาไว้ใช้งานเฉพาะ (directory/id)
    handler: function(newVal, oldVal) {
      if(newVal !== undefined && oldVal !== undefined){
        if(newVal !== oldVal){
          this.checkusedTime_otp()
        } else {
          return
        }
      } else {
        return
      }
      
    },
    deep: true,
    immediate: true
    },
    optionSearch: function (val) {
      if (val.includes("foldername") || val.includes("filename") || val.includes("hashtag")) {
        this.disableCheckboxG2 = true;
      } else if (val.includes("file_content")) {
        this.disableCheckboxG1 = true;
      } else {
        this.disableCheckboxG1 = false;
        this.disableCheckboxG2 = false;
      }
      
      if(!val.includes("hashtag")){
        if(this.errorSearch.status){
          this.errorSearch.status = false
          this.errorSearch.msg = ""
        }
      }
    },
    search: function(newVal, oldVal) {
      if(this.errorSearch.status){
        if(oldVal.length !== newVal){
          this.errorSearch.status  = false
        }
      }
    },
  },
  mounted() {
    if (this.$store.state.feature_inputmore === true) {
      this.fn_gettamplate_smartupload()
    }
    this.showicon = VueCookies.get("iconeva")
    this.fnGetManual();
    this.check_service_oneplatfrom = VueCookies.get("oneplatfrom");
    this.system_type_run = process.env.VUE_APP_ENVIRONMENT;
    this.version = process.env.VUE_APP_SERVICE_VERSION;
    this.setAspectData();
    const darkmode = localStorage.getItem("darkmode");
    //this.starttriggle_auth_token ()
    if(this.decryptdata(VueCookies.get("username")) === process.env.VUE_APP_ACCOUNT_BOT ){
      this.triggle_otp_timeout ()
    }
    if (darkmode) {
      if (darkmode === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    } else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = false;
      localStorage.setItem("darkmode", this.$vuetify.theme.dark.toString());
    }
    this.changecolor(
      localStorage.getItem("color"),
      localStorage.getItem("color_code_temp")
    );
  },
  beforeDestroy () {
    clearInterval(this.interval_check_expired)
  }
};
</script>
<style>
/* .badge {
  margin-top: -10px;
  margin-bottom: 5px;
} */
.hashtag-more span {
  font-size: 14px;
}
.v-input__append-inner {
  cursor: pointer;
}
.admin {
  border-style: solid;
  border-color: #d6ad60;
  border-width: 3px;
}
.pointer {
  cursor: pointer;
}
/* .v-application .primary--text {
    color:black !important;
    caret-color: black !important;
} */
#toolbarcss {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
}
#navbarcss {
  background: rgba(246, 247, 250, 1);
}
.checkbox .v-label {
  font-size: 14px;
}
.roundUploadAndCreate {
  border-radius: 10px;
  border: 1px solid #f3f5f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.v-input.autocompleteClass input {
  font-size: 14px;
  line-height: 22px;
  /* color: red; */
}
.bounce {
  text-align: center;
  margin: 0 auto;
  /* margin-top:100px; */
  height: 20px;
  width: 150px;
  padding: 20px;
  -webkit-animation: bounce 0.7s infinite;
}
@-webkit-keyframes bounce {
  0% {
    transform: scale(1, 1) translate(0px, 0px);
  }

  30% {
    transform: scale(1, 0.8) translate(0px, 10px);
  }

  75% {
    transform: scale(1, 1.1) translate(0px, 10px);
  }

  100% {
    transform: scale(1, 1) translate(0px, 0px);
  }
}
div#alertnews {
  position: fixed;
  right: -30px;
  bottom: 60px;
}
.bounce2 {
  text-align: center;
  margin: 0 auto;
  /* margin-top:100px; */
  height: 20px;
  width: 150px;
  padding: 5px;
  -webkit-animation: bounce 0.7s infinite;
}
@-webkit-keyframes bounce2 {
  0% {
    transform: scale(1, 1) translate(0px, 0px);
  }

  30% {
    transform: scale(1, 0.8) translate(0px, 10px);
  }

  75% {
    transform: scale(1, 1.1) translate(0px, 10px);
  }

  100% {
    transform: scale(1, 1) translate(0px, 0px);
  }
}
</style>
